
.mod-place-bid .modal-dialog.strip-pop{
    width: 600px!important;
}
.strip-pop .modal-content{
    padding: 50px 0;
}
input.plce-text::placeholder{
    color: #000;
    font-size: 18px;
}

.pt-0{
    padding-top: 0px !important;
}

.modal-header h3 ::before {
    position: absolute;
    background: #00CED5;
    width: 7px;
    height: 20px;
    left: -15px;
    top: 4px;
    border-radius: 20px;
}