.savedsearchform{
    display: flex;
    flex-direction: column;
}
.savedSearchFormNurron{
    /* width: 50%; */
}
.titleinput{
    display: flex;
    margin: 1em 0em 0em 0em;
}
.SavedsearchButton{
    margin: 0em 0em 0em 1em;
    border-radius: 10px;
    border: 1px solid #00ced5;
    color: #00ced5;
}
.SavedSearchButtonOne{
    margin: 0 0 0 8em;
    background: #fff;
    padding: 10px 14px;
    font-size: 15px;
    outline: 0;
}
.errclass{
    border : 1px solid red;
}
.savedclose{
    border: 0;
    color: red;
    opacity: 0.9;
}
.savedclose:hover{
    background:white
}
.gandd{
   padding: 0em 1em 0em 0em 
}
.card{
    border: 1px solid lightgrey;
    box-shadow: 0px 2px 10px -1px lightgrey;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
    }




    @media (max-width:986px){
        .SavedSearchButtonOne{
            margin: auto;
        }
    }