.d-inline-block{
    display: inline-block !important;
}
.ml-4{
    margin-left: 20px;
}
 .widthauto {
    width: auto !important;
    box-shadow: 0px 3px 10px 0px #0000001f;
}
.commission-question{
    display: flex;
    padding: 1em;
}
.justify-between{
    justify-content: space-between;
}
.width-100{
    width:100%;
}
.margin-finne input[type="datetime-local"]{
    width:13em;
}

.margin-finne .MuiInput-underline:before,.margin-finne .MuiInput-underline:after,
.date-mark .MuiInput-underline:before,.date-mark .MuiInput-underline:after
{display: none;}
.margin-finne .MuiFormControl-root{width:100%;}
.date-mark .MuiFormControl-root{width:100%;}
.margin-finne P,.date-mark p{display: none;}
.date-mark .MuiInputBase-input{   
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    height: 48px;
    font-family: 'Karla', sans-serif !important;}

.margin-finne .MuiInputBase-input,.date-mark .MuiInputBase-input{
    font-size: 1.5rem!important;
    color: #9E9E9E;
}
.date-mark .MuiInputBase-input{
    padding:0em 1em
}
.margin-finne .MuiInput-root{
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    height: 48px;
    font-size: 16px;
    font-family: 'Karla', sans-serif !important;
    padding: 10px 20px;
    width: 100%;
    text-transform: uppercase;
}
.commission-question-div{    display: flex;
    align-items: baseline;}

.commission-question-r{
    display: flex;
flex-direction: column;
}
#singlealis .single-alis{
    position: relative;
}

@media only screen and  (max-width:600px){
    .po-st{
        flex-flow: column;
    }
    .po-st .pad--both-100{
        padding: 30px 30px;
    }
    #singlealis .single-alis img{
        width: 100%;
        min-width: 100%;
    }
    .cl-bot{
        clear: both;
    }
}
