.scnd-slider img {
    object-fit: cover;
    min-height: 70px;
    height: 70px;
    width: 130px;
}
.scnd-slider {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    position: relative;
}
.slider-a img {
    width: 100%;
    height: 350px;
    min-height: 350px;
    object-fit: cover;
}
.scnd-slider:after{
    content: '';
    position: absolute;
    right: 0;
    background: no-repeat #00ced5 url(/images/icons/right-arrow.svg) center center;
    padding: 10px;
    z-index: 1;
    top: 45px;
    border-radius: 50px;

    cursor: pointer;
}
.scnd-slider:before{
    content: '';
    position: absolute;
    left:0;
    border-radius: 50px;
    background:no-repeat #00ced5 url(/images/icons/left-arrow.svg) center center;
    padding: 10px;
    z-index: 1;
    top: 45px;
    cursor: pointer;
}

.d_block{
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
}

.bid_error{
    display: block;
    color: red;
    text-align: center;
}

.bidstatus{
    padding-top: 25px;
    text-align: center;
    width: 80%;
}
.view-elip span:nth-child(2){
cursor: pointer;
}
.view-elip span:nth-child(1){
    width: 70%;
    word-break: break-word;
}

.backBtn{
    content: '';
    position: absolute;
    left: 0;
    border-radius: 50px;
    background: no-repeat #00ced5 url(/images/icons/left-arrow.svg) center center;
    padding: 10px;
    z-index: 5;
    top: 45px;
    cursor: pointer;
}

.nextBtn{
    content: '';
    position: absolute;
    right: 0;
    background: no-repeat #00ced5 url(/images/icons/right-arrow.svg) center center;
    padding: 10px;
    z-index: 5;
    top: 45px;
    border-radius: 50px;
    cursor: pointer;
}
.font-size-1_rem{
    font-size: 1.7rem;
}
.hovpopup{
    position: relative;
}
.justifycontent-between{
    justify-content: space-between;
}
.hovpopup .hvpop:hover + .hovermsg{
    display: block;
}

span.hovermsg::first-letter{
    text-transform: uppercase;
}

span.hovermsg {
    border: 1px solid #eee;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    padding: 12px;
    position: absolute;
    z-index: 1031;
    opacity: 1;
    background: #fff;
    right: auto;
    top: 2;
    left: 0;
    width: 25em;
    display: none;
    text-align: center;
    word-wrap: inherit;
    color: #7d7875;
}

#bidModal .modal-body form {
    display: block;
}

@keyframes FadeAnimation {
    0% {
      opacity: 1;
      visibility: visible;
    }
  
    100% {
      opacity: 0;
      visibility: hidden;
    }
}