.toggle.respond,
[id^=drop] {
	display: none!important;
}

@media only screen and (max-width: 768px) {
    [id^=drop]:checked + .dash-ul{
        display: block!important;
        position: relative;
        width: 75%;
        z-index: 999;
    }

    .toggle.respond + a,
    .dash-ul {
        display: none!important;
    }

   

    /* Stylinf the toggle lable */
    .toggle.respond {
        display: block!important;
        background-color: #00ced5;
        padding:14px 20px;	
        color:#FFF;
        font-size:17px;
        text-decoration:none;
        border:none;
        width: 75%;
        margin: auto;
    }

    .toggle.respond:hover {
        background-color: #00ced5;
    }

    
    .dash-ul .ul-list{
        width: 100%;
        text-align: left;
        padding: 15px;
    }
    .dash-ul .ul-list li{
        background: transparent;
    }
    .dash-ul .ul-list li.active{
        background: #003b3d;
        color: #FFF;
    }
    .filter-body .nav-dash .ul-list li.active:before, .filter-body .nav-dash .ul-list li:hover:before{
        display: none;
    }
    .filter-body .nav-dash .ul-list li.active a, .filter-body .nav-dash .ul-list li:hover a{
        color: #FFF!important;
        
    }
    .dash-ul{
        width: 75%;
        text-align: center;
        grid-column-gap: 23px;
        grid-row-gap: 10px;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        background: #00ced5;
        margin: auto;
    }

}

.dashboard_profile::before{
    background: none !important;
}