th{
    text-align : center
}
td::first-letter{
    text-transform: uppercase;
}
 .text-noproduct-label {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}
.paddingem-top-1{
    padding-top:1em !important;
}

