/* Pricing */
.pricing h3{
    text-align: center;
    margin: 5% auto;
}
.pricing h4{
    line-height: 30px;
    letter-spacing: 0.6px
}
.pricing{
    text-align: justify;
    margin: 4% auto;
}
.video-card{
    box-shadow: 9px 10px 15px -7px #b6b6b6;
    width: 350px;
    max-width: 350px;

}
.video-card figure{
    position: relative;
}
.video-body {
    padding: 35px;
}
.video-body h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}
.video-body p {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
    letter-spacing: 0.8px;
    line-height: 30px;
}
.video-card figure:after{
    position: absolute;
    content: '';
    background: #000000;
    top:0;
    bottom: 0;
    left: 0;
    right:0;
    opacity: 0.5;
    background-image: url('/images/info-play.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
}
.video-card  img{
    object-fit: cover;
    width: 100%;
    height: 220px;
}
.videos-info{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15% ;
}
/* Pricing End */
.top-clan{
    padding-bottom: 30px;
}

