@import url(https://fonts.googleapis.com/css?family=Karla:400,700|Lato:300,400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Karla:400,700|Lato:300,400,700,900&display=swap);
.top-head {
  font-weight: 400;
  background: #112e51;
  color: #fff;
  padding: 11px 0;
  font-size: 13px
}

.top-head span {
  font-weight: 300;
  margin-left: 6px
}

.top-head a { 
  color: #d6d7d9;
}
.top-head a:hover {
  color: #fff;
}

.top-head i {
  margin-right: 8px;
  font-size: 18px;
}
.topnav, .mainnav, .uni-search {
  display: flex;
  justify-content: flex-end; 
  flex: 1 1;
}

.top-logo {
  padding: 30px 0
}

.top-logo img {
  float: left
}

.top-logo span {
  background: #5b616b;
  color: #fff;
  padding: 8px 28px;
  font-size: 16px;
  margin-left: 15px;
  margin-top: 6px;
  letter-spacing: 5px;
  float: left;
  text-transform: uppercase;
  border-radius: 4px 30px 30px 4px
}
.mainnav a {
  color: #212121;
}
.search-head {
  background: #f1f1f1;
    padding-top: 10px;
    padding-bottom: 10px;
}

.search-head select{
width: auto;
height: 3.4rem;
padding: 0.5rem 0.7em;
padding-right: 40px;
}
.profiledrop{
  position: absolute;
  width: 140px;
  left: 0;    top: 65px;
  border-top: 3px solid #000;
  -webkit-transform: translate(48px, 2px);
          transform: translate(48px, 2px);
  -webkit-transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.profiledrop ul{
  padding: 0;
  list-style-type: none;
}
.profiledrop ul li a{
  color: #000000;
  text-decoration: none;
}
.profiledrop ul li:hover a{
  color: #00ced5;
}
.profiledrop ul li{
  background: #f0f7ffcc;
  padding: 10px 5px;
  margin: 1px auto;
}
.pad-rt-5{
  padding:0  7px
}
/* animate */
.dropdown-menu1
{
	border:none;
	display: block;
    -webkit-transform: scale(1)!important;
            transform: scale(1)!important;
	height: 0px;
	overflow: hidden;
	padding: 0px;
	top: 180px;
	-webkit-transition: all .3s;
	transition: all .3s;
}
.dropdown-menu1
{
	display: block;
	top: 100%;
	height: inherit;
}

/* snack bar */
/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  background-color: #d4edda;
  padding: 15px;
  color: #155724;
  position: fixed;
  z-index: 1;
  padding-right: 185px;
  right: 0;
  top: 6em;
  float: none;
  margin: 0 2px;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.snackbarClose{
    right: 20px;
    float: right;
    position: absolute;
    top: 15px;
    font-size: 20px;
}

.logo-design{
  text-shadow: 2px 2px #4965878f;
}
.header-name-display{
  padding-left: 10px;
  padding-top: 8px;
  outline: 0;
}
/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
/* 26/7 */
.snow-header a.navbar-brand {
  color: #fff !important;
  font-size: 26px;
}

#bs-example-navbar-collapse-1 ul li.res-call a{
  cursor: pointer;
}
#bs-example-navbar-collapse-1 ul li a {
  display: inline-block;
  position: relative;
}

#bs-example-navbar-collapse-1 ul li {
  color: #fff;
}

.nav-profile div:nth-child(2) {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.noti-dot{
  position: absolute;
  background: #ff7b2e;
  /* width: 20px; */
  /* height: 20px; */
  border-radius: 100px;
  font-size: 12px;
  text-align: center;
  display: flex;
  top: 10px;
  right: 9px;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 3px 4px;
}
.navbar-brand p{
  font-size: 16px;
  float: right;
}
.nav>li.scnd-dash{
  display: none;
}
.header-right li.active:after, .header-right li.res-call:hover:after{
  display: none;
}
.header-right li.res-call a:hover{
  cursor: auto;
  cursor: initial;
  /* cursor: context-menu; */
}
.logo-design img{
  width: 145px;
  display: inline;
  object-fit: contain;
}





























@media (max-width: 991px) {
  .res991{
    width: 100%;
  }
}
@media (max-width: 790px) {
  .snow-header a.navbar-brand {
    color: #fff !important;
    font-size: 15px;
  }
  .navbar-default .navbar-nav.header-right>li>a{
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .snow-header a.navbar-brand {
    font-size: 20px;
  }
  .profiledrop{
    display: none;
  }
  .nav>li.scnd-dash{
    display: block;
  }
  .navbar-default .navbar-nav.header-right > li.divider{
    margin-top: 0!important;
  }
  .profile.head{
    float: left;
    margin: 0!important;
  }
  .navbar-default .navbar-nav.header-right > li.divider{
    height: 1px!important;
  }
  nav .navWide {
    display: block;
  }
  nav .navNarrow {
    display: none;
  }
  .profiledrop{
    margin: auto;
    position: relative;
    top:0;
    left: 0;
    right: 0;
    -webkit-transform: translate(13px, 2px);
            transform: translate(13px, 2px);
  }
  .nav-profile{
    justify-content: center;
  }
}
@media (max-width: 425px) {
  .logo-design img{
    width: 100px;
  }
}

.uni-footer-contact-links {
  display: flex;
  justify-content: flex-end; 
  flex: 1 1;
}
.footer-contact a {
  color: #212121;
  text-decoration: none;
  padding-left: 40px;
  padding-top: 15px;
}
.uni-footer .container {
  max-width: 1040px;
}
.foot-link.social ul li {
  padding-right: 20px;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  /* padding: 6px 12px; */
}
.foot-link ul {
  padding: 10px 10px;
  justify-content: flex-end;}

.foot-link ul li a{
  color : #C4C4C4;
  text-decoration: none;
}

.foot-link ul li a:hover{
  color : #ffffff;
}
.navbar-brand span {
  font-size: 16px;
  float: right;
}
.News-letter p {
  color: #fff;
  font-weight: 400;
  font-size: 44px;
  margin: 40px 0px 15px 0 !important;
}
.News-letter span{
  color: #fff ;
  font-weight: 400;
  font-size: 20px;
}
.foot-link .fa-mobile-alt:before,.foot-link .fa-address-book:before{font-size: 16px;}
.foot-link ul.pr-30{
  padding-right:30px ;    width: 230px;
}
/* .foot-link.social{    width: 260px;} */
.foot-link.social ul li i{    margin-right: 20px;}

@media only screen and (max-width: 768px){
  .footer-above .flex.justify-around {
    justify-content: space-around!important;
    flex-direction: column;
    align-items: center;
    padding: 0px 0;
  }
  .foot-link ul {
    padding: 10px 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .foot-link .main-li{display: none;}
}
@media only screen and (max-width: 600px){
  .foot-link ul.pr-30 {
    padding-right: 10px!important;
  }
  .main-fle{
    flex-flow: column;
    align-items: stretch;
  }
  .foot-link.ft-after{
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 500px){
  .soci{
    justify-content: center!important;
  }
  .footer-above .flex.justify-around {
    flex-direction: column!important;
  }
}
body {
    font-family: 'Karla', sans-serif !important;
    font-family: 'Lato', sans-serif !important;
    overflow-x: hidden !important;
}

.karla {
    font-family: 'Karla', sans-serif !important;
}

.lato {
    font-family: 'Lato', sans-serif !important;
}

.bannercarosal .thumbs-wrapper.axis-vertical {
    display: none;
}

.btn.focus,
.btn:focus,
.btn:hover {
    outline: 0;
}

.primary {
    background: #00CED5 !important;
}

.secondary {
    background: #7AECB0 !important;
}

.gray {
    background: #242A31 !important;
}

.text-light {
    color: #00CED5 !important;
}

.text-dark {
    color: #242A31 !important;
}

.text-white {
    color: #ffffff !important;
}

.text-green {
    color: #0EC882 !important;
}

.text-blue {
    color: #2660A8 !important;
}

.text-error {
    color: #D85858 !important;
}

.text-black {
    color: #000000 !important;
}

.text-input {
    color: #9E9E9E !important;
}

.box-shadow {
    box-shadow: 0px 0px 10px 2px #00000014;
}

.text-gray {
    color: #848888 !important;
}

.pointer{
    cursor: pointer;
}

.font-12 {
    font-size: 12px !important;
}

.m-auto {
    margin: auto;
}

.font-15 {
    font-size: 15px !important;
}

.font-20 {
    font-size: 20px !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.pad-10 {
    padding: 10px !important;
}

.pad-0 {
    padding: 0 !important;
}

.mar-0 {
    margin: 0 !important;
}

.width-100 {
    width: 100px !important;
}

.width-15 {
    width: 15% !important;
}

.width-200 {
    width: 200px !important;
}

.width-300 {
    width: 300px !important;
}

.width-400 {
    width: 400px !important;
}

.width-500 {
    width: 500px !important;
}

.custom-container {
    width: 1306px;
}

.flex-wrap {
    flex-wrap: wrap;
}

.line-height-30 {
    line-height: 30px;
}

.main-mar-pro {
    margin: 20px 0px;
}

.btn-primary {
    background: #00CED5;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    padding: 9px 33px;
    border-color: #00ced5;
    text-transform: uppercase;
}

.btn-white {
    background: white !important;
    color: #00CED5 !important;
    font-size: 14px !important;
    padding: 8px 16px !important;
    margin: 8px !important;
}

.btn-primary:hover {
    background: #00CED5;
    color: #fff;
    border-color: #00ced5;
    box-shadow: 0px 0px 0px 3px #00ced53b;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

.btn-secondary {
    background: #faad39;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 9px;
    padding: 10px 50px;
    border-color: #faad39;
    text-transform: capitalize;;
}

.btn-blury {
    background: rgb(255, 255, 255);
    color: #063B7B;
    font-size: 16px;
    font-weight: 400;
    border-radius: 9px;
    padding: 10px 30px;
    border-color: #063B7B;
    text-transform: capitalize;
}

.btn-secondary:hover {
    color: #fff;
    box-shadow: 0px 5px 10px 0px #00ced547;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

.select-two {
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    height: 40px;
}

.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.justify-around {
    justify-content: space-around;
}

.justify-between {
    justify-content: space-between;
}

.pad-20 {
    padding: 20px !important;
}

.pad-15 {
    padding: 15px !important;
}

.pad-30 {
    padding: 30px !important;
}

.align-end {
    align-items: flex-end !important;
}

.pad-55 {
    padding: 55px !important;
}

.pad-btm-7em {
    padding-bottom: 7em !important;
}

.pad-btm-20 {
    padding-bottom: 20px !important;
}

.pad-top-20 {
    padding-top: 20px !important;
}

.pad-lr-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.pad-lr-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.pad-tb-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.pad-top-30 {
    padding-top: 30px;
}

.mar-top-30 {
    padding-top: 30px;
}

.mar-top-20 {
    padding-top: 20px;
}

.pad-btm-30 {
    padding-bottom: 30px;
}

.border {
    border: 1px solid #01152D;
}

.border-none {
    border: none;
}

.mar-btn-30 {
    margin-bottom: 30px !important;
}


/* css */
.client-review{
    font-size: 42px;
}
.snow-pad .snow-header a.navbar-brand {
    font-family: 'Karla', sans-serif;
    font-size: 32px;
    font-weight: 400;
    color: #fff;
}

.navbar-default .navbar-nav.header-right > li > a {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
}

.navbar-default .navbar-nav.header-right > li.divider {
    background: white;
    height: 13px;
    padding: 0 0.6px;
    margin-top: 19px;
}

.Home-form {
    /* background-image: url("/images/home-f-bg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 600px;
    display: flex;
    align-items: center; */
    position: relative;
}
.show-fotter .foot-bg {
    background-image: url("/images/foot-img-1.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.show-fotter .foot-bg:after {
    content: '';
    position: absolute;
    background: #E1E2E4;
    bottom: 0;

}

.foot-link ul {
    padding: 0;
    list-style-type: none;
    color: #fff;
    margin-bottom: 0;
}

.foot-link ul li.main-li {
    font-weight: 600;
    padding-bottom: 10px;
    margin-bottom: 0;
    color: #ffffff;

}

.News-letter h4 {
    font-size: 22px;
    font-weight: 500;
}

.News-letter p {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
}

.footer-above .navbar-form input {
    border: 1px solid #E1E2E4;
    border-radius: 4px;
    padding: 20px 18px;
}

.footer-above .navbar-form input.form-control:focus {
    border-color: #00ced5;
}

.foot-link address {
    margin-bottom: 0;
}

.foot-link li {
    padding: 3px 0;
    color: #919191;
    cursor: pointer;
}

.foot-link li:hover {
    color: #c4c4c4;
    cursor: auto;
    cursor: initial;
}

/* .foot-link .social ul li {
    padding-right: 20px;
    font-size: 20px;
    color: #cccccc;
} */

.elty {
    box-shadow: 0px 3px 30px 0px #6161611f;
    width: 480px;
    margin: auto;
    border-bottom: 5px solid #00ced5;
    padding-bottom: 20px !important;
    position: relative;
}

.elty label {
    color: #242A31;
    font-size: 15px;
    font-family: 'Karla', sans-serif !important;
    font-weight: 400;
}

.elty input.form-control:focus {
    border-color: #00ced5;
}

.elty input {
    height: 44px;
    font-family: 'Karla', sans-serif !important;
}

.link-redir p {
    font-family: 'Karla', sans-serif !important;
}

input.check-height {
    height: 15px;
}

.alpha-form {
    padding-top: 100px;
}

.top-hd {
    position: absolute;
    top: -24px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 4px;
    width: 65%;
    color: #fff;
    border-radius: 4px;
    background: #00ced5;
    margin: auto;
}

.top-hd h4 {
    font-size: 20px;
    font-family: 'Lato', sans-serif !important;
    position: relative;
}

/* .top-hd h4:before {
    content: '';
    position: absolute;
    left: 130px;
    bottom: -4px;
    width: 25px;
    height: 2px;
    background: #fff;
} */

.center-part {
    margin: 69px auto;
    margin-bottom: 0;
}

.checkbox .mar-btm-30 {
    margin-bottom: 30px;
}

/* Custom checkbox */
.show-check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    float: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.show-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.show-check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    border-color: #CECECE;
    width: 18px;
}

/* On mouse-over, add a grey background color */
.show-check:hover input ~ .checkmark {
    background-color: #E5E1E1;
}

/* When the checkbox is checked, add a blue background */
.show-check input:checked ~ .checkmark {
    background-color: #E5E1E1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.show-check .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.show-check input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.show-check .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #00ced5;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* end */
.foot-link.ft-after,
.foot-link.ft-before {
    position: relative;
}

.foot-link.ft-before:before {
    content: '';
    position: absolute;
    height: 65px;
    left: -35px;
    top: 40px;
    width: 2px;
    background: #919191;
}

.foot-link.ft-after:before {
    content: '';
    position: absolute;
    height: 65px;
    right: -35px;
    top: 40px;
    width: 2px;
    background: #919191;
}

.elty select {
    height: 44px;
    font-family: 'Karla', sans-serif !important;
}

.elty select.form-control:focus {
    border-color: #00ced5;
}

.top-hd.reg-hd h4:before {
    left: 117px;
    bottom: -4px;
    width: 31px;
}

.forgot-form {
    padding: 100px 0;
    margin: auto;
}

.top-hd.for-hd h4:before {
    left: 79px;
    width: 30px;
}

/* search Page */
.center-part .sch-banner {
    background-image: url("/public/images/sch-banner.png");
    height: 60vh;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;
}

.center-part .sch-banner .sch-content {
    background-color: #000000;
    opacity: 0.2;
    filter: alpha(opacity=60);
    height: 60vh;
}

.find-card h3 {
    font-size: 26px;
    font-weight: 600;
    color: #07192E;
    margin: 35px auto;
    margin-top: 0;
}

.find-card h3 span {
    position: relative;
}

.find-card .inner-card {
    padding: 30px 100px;
    background: white;
    border-radius: 17px;
    margin: 0 75px;
    box-shadow: 0px 3px 16px 0px #0000001a;
}

.find-card {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 70%;
}

.find-card h3 span:before {
    content: '';
    position: absolute;
    background: #00CED5;
    width: 7px;
    height: 20px;
    left: -15px;
    top: 6px;
    border-radius: 20px;
}

/* Filter */
.filter-body .accordion {
    max-width: 30em;
}

.filter-body .toggle {
    display: none;
}

.filter-body .option {
    position: relative;
}

.filter-body .title,
.content {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.filter-body .title.active {
    color: #00CED5;
}

.filter-body .title {
    padding: 1em;
    display: block;
    color: #17365D;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
}

.filter-body .title:after,
.filter-body .title:before {
    content: '';
    position: absolute;
    right: 1.25em;
    top: 1.25em;
    width: 3px;
    height: 0.75em;
    background-color: #17365D;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.filter-body .title:after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.filter-body .title.active:after {
    background: #00CED5
}

.filter-body .content {
    max-height: 0;
    overflow: hidden;
    background-color: #fff;
}

.filter-body .content p {
    margin: 0;
    padding: 0.5em 1em 1em;
    font-size: 0.9em;
    line-height: 1.5;
}

/* .toggle:checked + .title, .toggle:checked + .title + .content {
    box-shadow: 3px 3px 6px #ddd, -3px 3px 6px #ddd;
  } */
.filter-body .toggle:checked + .title + .content {
    max-height: 500px;
}

/* content ranger */
.filter-body .toggle:checked + .title + .content.ranger {
    overflow: unset;
}

/* content ranger */
.filter-body .toggle:checked + .title + .content.ranger .MuiSlider-thumb .MuiSlider-valueLabel{
    width: 32px;
    text-align: center;
}

/* content ranger */
.filter-body .toggle:checked + .title + .content.ranger .MuiSlider-thumb .MuiSlider-valueLabel span,
.filter-body .toggle:checked + .title + .content.ranger .MuiSlider-thumb .MuiSlider-valueLabel span span{
    width: 100%;
}

.filter-body .toggle:checked + .title:before {
    -webkit-transform: rotate(90deg) !important;
    transform: rotate(90deg) !important;

}

.filter-body h3 span {
    position: relative;
    padding: 6px 28px;
}

.filter-body h3 span:before {
    content: '';
    position: absolute;
    background: #00CED5;
    width: 7px;
    height: 20px;
    left: 14px;
    top: 10px;
    border-radius: 20px;
}

section.filter-body {
    margin: auto;
    margin-top: 16em;
    margin-bottom: 5em;
}

/* End Filter */
.content .show-check span.checkmark {
    width: 18px;
    height: 18px;
    border: 1px solid #7adadd;
    background: #ffffff;
}

.content .show-check {
    font-size: 18px;
    color: #2660A8;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}

.content ul {
    list-style-type: none;
}

.content .show-check {
    float: unset;
}

.contact {
    margin: 35px 37px;
}

.content .show-check .checkmark:after {
    left: 6px;
    top: 2px;
}

.sort {
    padding: 10px 0px;
}

.list-pro {
    box-shadow: 0px 0px 10px 2px #00000014;
}

.profile {
    width: 100px;
    height: 100px;
    margin: 32px auto;
    border-radius: 100px;
    position: relative;
    border: 3px solid #496587;
}

.profile span {
    display: block;
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    color: #496587;
}

.profile span:nth-child(2) {
    font-size: 14px;
}

.profile .div {
    margin: 17px auto;
}

.profile:before {
    content: '';
    background-image: url(/public/images/icons/star.svg);
    position: absolute;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: left;
    left: -40px;
    top: -25px;

}

.contact .btn-secondary {
    padding: 8px 19px;
}

.contact:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 75%;
    background: #e3e3e359;
    right: 4px;
    top: 0;
    margin: 25px auto;
    bottom: 0;
}

.pro-data .product .tit {
    font-size: 17px;
    color: #000000;
    margin: 0;
    font-weight: 600;
    font-family: 'Karla', sans-serif !important;
}

.pro-data .product .scrore {
    font-size: 17px;
    color: #00CED5;
    margin: 0;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}

.pro-data {
    margin: 35px 15px;
}

.data-list span.text-left {
    color: #5A5B5B;
    font-size: 15px;
    font-weight: 500;
}

.data-list {
    padding: 10px 0;
}

.data-list span {
    display: block;
    line-height: 35px;
}

.data-list span b {
    color: #2E2E2E;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
}

.ul-list img {
    height: 18px;
}

.ul-list {
    padding: 0;
    list-style-type: none;
}

.ul-list li {
    float: left;
    padding: 0 15px 0 0;
}

.main-pro {
    padding: 20px 0px;
}

.filter-tit {
    margin: 0;
    padding: 10px 0;
}

.feture-list .feu-tit {
    font-size: 22px;
    font-weight: 500;
    color: rgb(0, 0, 0);
}

.feture-list .feture-card .btn-secondary {
    padding: 8px 20px;
}

.feture-card {
    padding: 20px 30px;
    width: 30%;
    text-align: center;
    border: 1px solid #D6D6D6;
    box-shadow: 0px 10px 10px 0px #00000017;
}

.span-block span {
    display: block
}

.feturecard-body.span-block span,
.feturecard-body.span-block h4 {
    text-align: left;
    margin: 0;
    line-height: 30px;
}

.feturecard-body.span-block span {
    font-size: 14px;
    font-weight: 400;
    color: #5A5B5B;
}

.feturecard-body.span-block span b {
    font-size: 14px;
    font-weight: 400;
    color: #2E2E2E;
}

.feturecard-body.span-block {
    padding: 0px 0 22px;
}

.feture-card .profile {
    width: 120px;
    height: 120px;
    margin: 20px auto;
    background: #f0f7ff;
}

.feture-card .profile .div {
    margin: 27px auto;
}

.feture-card .profile:before {
    left: 13em;
}

.feture-list .pa-d {
    padding: 0px 0 40px;
}

.top-auction {
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.grid-body .pro-hd b {
    color: #404040;
    font-weight: 600;
}

.grid-body span {
    line-height: 30px;
    color: #5A5B5B;
    font-size: 15px;
    font-weight: 500;
}

.grid-body span b {
    color: #2E2E2E;
    font-size: 15px;
    font-weight: 500;
}

.grid-footer span:nth-child(1) {
    font-weight: 800;
    font-size: 15px;
}

.grid-footer span img {
    padding: 0 10px 0 0;
    height: 18px;
}

.fetu-width .buyer-grid {
    width: 30%;
}

.img-widh .buyer-grid img {
    width: 100%;
    height: 221px;
}

.select-two.sort-select {
    border: .5px solid #00000061;
}

.img-before {
    position: relative;
}

.img-before img {
    width: 100%;
    height: 265px;
    object-fit: cover;
}

.img-before .auction-id {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
}

/* Buyer Dashboard */
section.filter-body.Buyer-dash {
    margin-top: 4em;
}

.nav-dash.box-shadow {
    padding: 11px 45px;
    margin-top: 79px;
}

.nav-dash .filter-tit {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Karla', sans-serif !important;
}

.filter-body .nav-dash h3 span:before {
    width: 5px;
    height: 14px;
    left: 20px;
    top: 10px;
}

.filter-body .nav-dash figure:after {
    content: '';
    position: absolute;
    width: 28px;
    height: 28px;
    background-image: url("/public/images/icons/edit-2.svg");
    background-repeat: no-repeat;
    background-position: left;
    right: 47px;
    bottom: 3px;
    border-radius: 50px;
    border: 1px solid #7070702b;
}

.filter-body .nav-dash figure {
    position: relative;
}

.filter-body .nav-dash img {
    margin: 15px auto;
    border-radius: 100px;
    object-fit: cover;
}

.filter-body .nav-dash .ul-list li.active,
.filter-body .nav-dash .ul-list li:hover {
    color: #00CED5;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.filter-body .nav-dash .ul-list li.active:before,
.filter-body .nav-dash .ul-list li:hover:before {
    content: '';
    position: absolute;
    background: #00CED5;
    border-radius: 100px;
    width: 7px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    height: 7px;
    left: -5px;
    top: 17px;
}

.filter-body .nav-dash .ul-list li {
    position: relative;
    float: unset;
    cursor: pointer;
    padding: 8px;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Karla', sans-serif !important;
}

.nav-profile img.img-responsive {
    width: 40px;
    height: 40px;
}

.pad-8 {
    padding: 8px !important;
}

.content-main .tab-connect {
    display: flex;
    justify-content: space-between;
}

.content-main .tab-connect .nav-tabs li {
    padding: 0 5px;
}

.content-main .tab-connect .nav-tabs li:nth-child(1) {
    padding-right: 40px;
    padding-left: 0;
}

.content-main .tab-connect .nav-tabs li:nth-child(3) {
    padding-left: 40px;
    padding-right: 0;
}

.content-main .tab-connect .nav-tabs li:nth-child(4) {
    padding-left: 40px;
    padding-right: 0;
}

.content-main .tab-connect .nav-tabs {
    border-bottom: 1px solid #000;
    padding: 10px 0;
    /* width: 35%; */
}

.content-main .tab-connect .nav-tabs li a {
    color: rgb(0, 0, 0);
    font-size: 16px;
    cursor: default;
    padding: 0px 0px 0px 0px;
    background-color: #fff;
    border: 1px solid #ddd0;
    border-bottom-color: transparent;
    font-weight: 600;
    margin: 0;
}

.content-main .tab-connect .nav-tabs li.active a {
    color: #00CED5;
    font-size: 16px;
    cursor: default;
}

.bids-data img {
    height: 250px;
    object-fit: cover;
    width: 100%;
}

.award-img .btn-secondary {
    padding: 10px 30px;
}

.award-img img.img-responsive {
    height: 65px;
    width: 100%;
    object-fit: contain;
    margin: 15px 0px;
}

.bids-details span {
    line-height: 35px;
    color: #000;
    font-size: 15px;
}

.bids-data {
    border: 1px solid #C9C3C3;
    border-right: 2px solid #00ced5;
    overflow: hidden;
}

.bids-details {
    padding: 10px 0px;
    line-height: 70px;
}

.bids-tit span {
    letter-spacing: 1pt;
}

.pad-left-0 {
    padding-left: 0;
}

.flex-height {
    height: 104px;
}

.flex-hgt {
    height: 150px;
}

.bids-data {
    margin: 30px auto;
}

.site-input {
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    height: 48px;
    font-family: 'Karla', sans-serif !important;
}

.site-textarea {
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    resize: none;
    font-family: 'Karla', sans-serif !important;
}

.site-form label {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}

.prc-rang .form-group {
    width: 100%;
    box-shadow: 0px 3px 10px 0px #0000001f;
}

.prc-rang .site-input {
    border-right: 0;
    box-shadow: none;
}

.prc-rang .form-group label {
    padding: 7px 20px;
    font-size: 24px;
    font-weight: 800;
    color: #BCB9B9;
}

.prc-rang .form-group.sha-none {
    box-shadow: none;
}

.prc-rang .input-group-addon:last-child {
    background: white;
    box-shadow: none;
    border: 1px solid #E2E2E2;
    border-radius: 2px;
    font-size: 20px;
    color: #BCB9B9;
    font-weight: 800;
    border-left: 0;
}

/* Prodct View Page */
.slider .owl-carousel .owl-item img {
    height: 350px;
    min-height: 350px;
    object-fit: cover;
}

.slider-two .owl-carousel .owl-item img {
    object-fit: cover;
}

section.carosal-product {
    border-radius: 3px;
    width: calc(100% - 20px);
    display: inline-block;
    height: auto;
    font-family: 'Source Sans Pro', sans-serif;
}

section.carosal-product .all {
    width: 100%;
    height: auto;
    display: inline-block;
}

section.carosal-product .all .slider {
    width: auto;
    height: auto;
    overflow: hidden;
    border-radius: 3px;
    position: relative;
}

section.carosal-product .all .slider .owl-nav,
section.carosal-product .all .slider .owl-dots {
    display: none;
}

section.carosal-product .all .slider:hover .left {
    visibility: visible;
    opacity: 1;
    left: 20px;
}

section.carosal-product .all .slider:hover .right {
    visibility: visible;
    opacity: 1;
    right: 20px;
}

section.carosal-product .all .slider .item-box {
    /* padding: 20px; */
    overflow: hidden;
    width: 100%;
    height: 350px;
    position: relative;
    border-radius: 3px;
    /* background: no-repeat url(http://www.cyw51.com/file/upload/201302/22/18-49-19-74-127.jpg) center center; */
    background-size: cover;
}

section.carosal-product .all .slider .item-box:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    content: "";
    display: block;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000); */
}

/* section.carosal-product .all .slider .left {
    position: absolute;
    z-index: 1;
    top: 167px;
    left: -20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: left ease .25s;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    background: url(http://svgshare.com/i/2V9.svg) top left;
  }
  section.carosal-product .all .slider .left:hover {
    visibility: visible;
    opacity: 1;
    left: 20px;
    background: url(http://svgshare.com/i/2UW.svg) top left;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  } */
section.carosal-product .all .slider .left.nonl {
    left: -20px;
    opacity: 0;
    visibility: hidden;
}

/* section.carosal-product .all .slider .right {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 167px;
    right: -20px;
    width: 40px;
    height: 40px;
    visibility: hidden;
    opacity: 0;
    transition: right ease .25s;
    background: url(http://svgshare.com/i/2V9.svg) top left;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  section.carosal-product .all .slider .right:hover {
    visibility: visible;
    opacity: 1;
    right: 20px;
    background: url(http://svgshare.com/i/2UW.svg) top left;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  } */
section.carosal-product .all .slider .right.nonr {
    right: -20px;
    opacity: 0;
    visibility: hidden;
}

section.carosal-product .all .slider-two {
    width: auto;
    height: auto;
    overflow: hidden;
    position: relative;
}

section.carosal-product .all .slider-two .owl-nav,
section.carosal-product .all .slider-two .owl-dots {
    display: none;
}

section.carosal-product .all .slider-two .left-t {
    visibility: visible;
    opacity: 1;
    left: 3px;
}

section.carosal-product .all .slider-two .right-t {
    visibility: visible;
    opacity: 1;
    right: 3px;
}

section.carosal-product .all .slider-two .item {
    -webkit-transition: all ease .25s;
    transition: all ease .25s;
    height: 100px;
    border-radius: 3px;
    /* border: solid 3px #e6ecf2; */
    cursor: pointer;
    /* background: no-repeat url(http://www.cyw51.com/file/upload/201302/22/18-49-19-74-127.jpg) center center; */
    background-size: cover;
}

section.carosal-product .all .slider-two .item.active {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    /* border: solid 3px #00ced5 */
}

section.carosal-product .all .slider-two .owl-stage {
    padding: 20px 40px;
    width: 100% !important;
}

section.carosal-product .all .slider-two .left-t {
    /* -webkit-transform: rotate(180deg);
            transform: rotate(180deg); */
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 60px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all ease .25s;
    transition: all ease .25s;
    width: 20px;
    border-radius: 50px;
    height: 20px;
    background: no-repeat #00ced5 url(/public/images/icons/left-arrow.svg) center center;
    left: 8px;
}

section.carosal-product .all .slider-two .left-t.nonl-t {
    left: 8px;
    opacity: 1;
    visibility: unset;
}

section.carosal-product .all .slider-two .left-t:hover {
    visibility: visible;
    opacity: 1;
    left: 3px;
}

section.carosal-product .all .slider-two .right-t {
    background: no-repeat rgba(204, 46, 56, 0.85) url(/public/images/icons/right-arrow.svg) center center;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 60px;
    right: 8px;
    visibility: unset;
    opacity: 1;
    -webkit-transition: all ease .25s;
    transition: all ease .25s;
    width: 20px;
    border-radius: 50px;
    height: 20px;
    background-color: #00ced5;
}

section.carosal-product .all .slider-two .right-t:hover {
    visibility: visible;
    opacity: 1;
    right: 3px;
}

section.carosal-product .all .slider-two .right-t.nonr-t {
    right: -40px;
    opacity: 0;
    visibility: hidden;
}

/* Prodct View Page */
.pro-pagi h5 {
    font-size: 14px;
    color: #C5C5C5;
    font-weight: 400;
}

.pro-pagi h5 b {
    font-size: 14px;
    color: #878585;
    font-weight: 800;
}

.product-hd h4 {
    font-size: 22px;
}

.product-hd .btn-secondary {
    padding: 10px 20px;
}

.product-hd {
    border-bottom: 1px solid #E8E8E8;
    padding: 0 0 18px 0px;
}

.proper-data span b {
    color: #000;
    font-weight: 500;
}

.proper-data span {
    line-height: 35px;
    font-family: 'Karla', sans-serif !important;
    font-size: 18px;
    font-weight: 500;
    color: #757575;
}

.fancy-btn button {
    position: absolute;

}

.fancy-btn {
    padding: 6px 0px 35px 0;
    border: 1px solid #00CED5;
    width: 80%;
    background: #EFFFFF;
    border-radius: 4px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
}

.fancy-btn button {
    position: absolute;
    left: auto;
    right: auto;
    bottom: -17px;
    text-align: center;
}

.twin-bath span {
    padding: 0 22px 0px 0;
    position: relative;
}

.twin-bath span:nth-child(1):after {
    border-right: 1px solid #DFDFDF;
    content: '';
    position: absolute;
    width: 1px;
    top: 10px;
    right: 7px;
    height: 14px;
    background: #000000;
}

.proper-data.span-block {
    padding: 20px 0;
}

.product-timer img.img-responsive {
    padding: 0 10px;
}

.product-timer.primary.pad-10.text-center {
    margin: 0 0 20px 0px;
}

.range-slider span.range {
    font-size: 15px;
    color: #9E9E9E !important;
    padding: 0px 17px;
    font-weight: 500;
}

.range-slider {
    display: flex;
    justify-content: space-between
}

.buyer-img img {
    height: 205px;
}

.pro-card-hgt.buyer-img {
    height: 205px;
}

/* Seller Dashboard */

.pro-card-hgt {
    height: 231px;
    overflow: hidden;
}

/* Seller Dashboard */
/* Priec range */
.price-range #slider {
    width: 65%;
    background: #00CED5;
    height: 4px;
    border-radius: 0;
    border: none;
    margin: 9px auto;
}

.price-range .value {
    position: absolute;
    top: 20px;
    left: 50%;
    margin: 0 0 0 -20px;
    width: 40px;
    text-align: center;
    display: block;
    font-weight: normal;
    font-family: Verdana, Arial, sans-serif;
    font-size: 14px;
    color: #333;
}

.price-range .price-range-both.value {
    width: 100px;
    margin: 0 0 0 -50px;
    top: 26px;
}

.price-range .price-range-both {
    display: none;
}

.price-range .value i {
    font-style: normal;
}

.price-range body div.ui-slider-range.ui-widget-header {
    background: #00ced5;
}

.price-range .ui-state-hover,
.price-range .ui-widget-content .ui-state-hover,
.price-range .ui-widget-header .ui-state-hover,
.price-range .ui-state-focus,
.price-range .ui-widget-content .ui-state-focus,
.price-range .ui-widget-header .ui-state-focus {
    background: #2ecaf9 !important;
}

.price-range .ui-state-default,
.price-range .ui-widget-content .ui-state-default,
.price-range .ui-widget-header .ui-state-default {
    background: #5892D8 !important;
    border-radius: 100%;
    border: none !important;
    padding: 4px;
    width: 15px;
    height: 15px;
}

.price-range .ui-widget-header {
    background: #00CED5 !important;
}

.price-range .range-slider .ui-slider-horizontal .ui-slider-handle {
    top: -0.4em;
    margin-left: -.6em;
}

.price-range span.ui-slider-handle.ui-corner-all.ui-state-default:focus {
    outline: none;
}

.price-range .range-slider span {
    color: #faad39;
}

/* Priec range  End */

/* 19/6/2019 */
/* .com-ty .list-group{
    background-color: #fff;
} */
.list-group-item{
    background-color: #fff0;
}
.sell-limity img{
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 213px;
    border: none!important;
}
.sell-limity span .fa-arrow-right:before{padding: 0px 15px;}
.sell-limity span{
    color: #00CED5;
}
.sell-limity p{
    font-size: 14px;
    font-weight: normal;
    color: #747474;
    line-height: 28px;
    letter-spacing: 1px;
}
.sell-limity h4{
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    color: #5A5454;
    line-height: 28px;
    padding: 10px 0;
}
.sell-limity .b4-img{
    position: relative;
}
.owl-you{
    margin: 15px;
}
.limity-body{
    padding: 10px 20px;
}
.sell-limity span{
    position: absolute;
    /* background-image: url(../../../public/images/pro-5.png);
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    right: 0; */
}
.sell-limity{
    min-height: 500px;
    height: 500px;
    color: #ccc;
    width: 50%;
    background: white;
    margin: 15px;
    box-shadow: 0px 2px 12px 4px #0000000f;
}
.limity-body p {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 140px;
    min-height: 140px;
    flex-wrap: nowrap;
}
.you-tube img{
    height: 213px;
    width: 100%;
    object-fit: cover;
    border: none!important;
}
.you-tube .b4-img,.sell-limity .b2-img,.sell-limity .b3-img{
    position: relative;
}
.you-tube .b4-img span.iim{
    position: absolute;
    background-image: url(/images/youtube.svg);
    height: 65px;
    width: 85px;
    top: 38%;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
    -webkit-transform: translate(150%);
            transform: translate(150%);
}
.sell-limity .b2-img span.iim{
    position: absolute;
    background-image: url(/images/b2-img.svg);
    height: 65px;
    width: 100%;
    bottom: -5px;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
}
.b4-img:after{
    position: absolute;
    content: '';
    background: #000000;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
}
.sell-limity .b3-img span.iim{
    position: absolute;
    background-image: url(/images/b3-img.svg);
    height: 85px;
    width: 100%;
    background-size: cover;
    bottom: -10px;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
}
.bg-light{
    background: #EEFCFE;
}
.you-tube h4{
    font-size: 16px;
    font-weight: 500;
    color: #707070;
    text-align: center;
}
.home-subscribe {
    background: #00ced5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
}
.home-subscribe .News-letter h4 {
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    text-transform: uppercase;
  
}
.home-subscribe  .navbar-form .form-control {
    padding: 18px;
    border: 1px solid #fff;
    margin-right: 5px;
}
.home-subscribe  button:hover {
    background: rgb(255, 255, 255);
    color: #000000;
}
.home-subscribe  button {
background: #077B8E;
}
.home-subscribe .News-letter p {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    margin: 0;
    margin-bottom: 20px;
}
/* 27/6/2019 */
.Home-form .btn-primary{
background: #faad39;
}
.Home-form textarea{
    resize: none;
}
.Home-form h4{
    color: #088397;
    font-size: 30px;
    font-weight: 400;
    margin-bottom:30px;
}
.client-cmt{
    position: relative;
    height: 600px;
}
.client-cmt:after{
    content: '';
    background-image: url(/images/client-circle.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: right;
    height: 100em;
    width: 100%;
    position: absolute;
    left: 33%;
    bottom: -65%;
    z-index: -1;
    opacity: 1;
}
.client-card{
    background: #fff;
    box-shadow: 0px 10px 11px 7px #00000008;
    padding: 45px 25px;
    width: 30%;
    position: relative;
}
.client-cmt h2{
    margin: 40px auto;
}
.client-card:after{
    position: absolute;
    content: '';
    background: #00CED5;
    width: 50%;
    height: 5px;
    bottom: 0;
    left: 0;
}
.star i.fa.fa-star {
    color: #FFAB1A;
    letter-spacing: 8px;
}
.client-card p {
    padding: 15px 0;
    line-height: 24px;
    font-size: 16px;
    color: #B1AAAA;
    font-weight: 500;
}
.client-card b{
    font-size: 16px;
}
.client-card img {
    width: 53px;
    height: 53px;
    border-radius: 60px;
}
.client-card .snd-card p span{
    color: #000;
}
.client-card .snd-card p{
    padding: 0px 0;
}
.snd-card {
    padding-left: 25px;
}
.client-cmt .nav-dots{
    text-align: center;
    margin: 55px auto;
}
.client-cmt .nav-dots .c-1.active{
background: #088397
}
.client-cmt .nav-dots .c-1{
    background: #00ced5;
    border-radius: 60px;
    padding: 0px 8px;
    margin: 8px;
}
.bg-sf-light{
    background: #00ced514;
}
/* .our-client {
    transform: translate(50%,50%)
} */


.search_btn {
    display: flex;
    justify-content: center;
}
.toggle.respond,
[id^=drop] {
	display: none;
}
/* 16/07/2019 */
.sp_banner .banner-bt button{
    font-weight: 600;
}
.sp_banner .banner-bt{
    /* padding: 0 4%; */
}
.sp_banner .banner-bottom button{
    font-weight: 600;
    margin: 3%;
}
.hiw .hiw-1 .hiw-box .hiw-links button,.hiw .hiw-2 .hiw-box .hiw-links button,.hiw .hiw-3 .hiw-box .hiw-links button{
    font-size: 17px;
}
.rt .btn-secondary,.lt .btn-secondary{
    padding: 10px 16px;
}
.rt .btn-secondary:hover,.lt .btn-secondary:hover{
   background: #00ced5;    border: 1px solid #00ced5;
}
.rt .btn-primary,.lt .btn-primary {
   padding: 9px 15px;
}
.Home-form .btn-primary.btn-block{
    padding: 24px;
    box-shadow:0px 4px 12px 6px #61616136;
    font-size: 16px;
}
.services .serv-data span h2{
    font-size: 55px;
    margin: 0;
    color: #faad39;
    font-family: -webkit-body;
}
.serv-data:hover span h2{
    -webkit-transition: 0.5s all ease-in;
    transition: 0.5s all ease-in;
}
section.feture-list {
    margin-top: 50px;
}
/* 26/08/19 */
.client-review{
    font-weight: 600;
}
/* 27/08/19 SELCT SEC */

.select-sec{
    margin-bottom: 16em;
}
.select-sec .sec-divided{
    background: #FFF;
    box-shadow: 0px 0px 13px 0px #56565636;
    border-radius: 6px;
    padding: 10px 35px;
    z-index: 999;
    position: absolute;
    bottom: -85px;
    width: 75%;
    margin: auto;
    left: 0;
    right: 0;
}
.posi-relative{
    position: relative;
}
.sec-divided-flex span p{
    font-size: 20px;
    font-weight: 800;
    color: #00ced5;
}
.sec-divided-flex span{
    text-align: left;
    width: 22%;
}
.sec-divided-flex{
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.sec-divided-flex span h4{
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
}
.divion-button{
    text-align: center;
}
.divion-button button:hover{
    background: #faad39;
    border: 1px solid #faad39;
    box-shadow: none;
}
.divion-button button{
    margin: 10px auto;
    border-bottom: 6px solid #e7a33db8;
    font-weight: 600;
}
.divion-button p{
    font-size: 18px;
    font-weight: 700;
    color: #faad39;
}
/* 3/9/19 */
.close-icon {
    padding: 0px 5px;
    color: #cfcfcf;
    font-size: 18px;
    cursor: pointer;
}
.close-icon{
    display: block;
}
/* 6/9/19 */
.bg-wht{
    background: white;
    border-radius: 4px;
    box-shadow: 8px 8px 10px 3px #8f8f8f0d;
    padding: 5px 15px;
    width: 450px;
}
.w-45{
    width: 45px;
}
/* 16/10/19 */
video {
    max-width: 700px;
    width: 700px;
    border: 5px solid #00ced524;
    border-radius: 8px;
    height: 350px;
  }
  .rt .btn-secondary, .lt .btn-secondary{
    padding: 10px 35px;
  }
  .identify-diff .D-card .card-fot .services-bt h4{    background: transparent;
    color: #3d3d3d;
    font-weight: 600!important;}
  .identify-diff .D-card .card-fot .services-bt{margin: 20px auto;}
  .identify-diff .D-card {width: 270px;}
  .identify-diff .D-card .card-body ul li.D-fot{
    color: #003d3d;
    font-size: 15px;
    margin-top: 0px;
    font-weight: 600;
   }
   .list{list-style-type: none!important;
   }
   /* .identify-diff .D-card .card-body ul li:before {
    content: "\2022";
    color: red;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
   } */
   .identify-diff .D-card .card-body ul li b {
    margin-right: 8px;   
    color: #006845;
    }
   .identify-diff .D-card .card-body ul li {
    line-height: 22px;
    margin: 8px 0;
    list-style-type: none;
    font-size: 14px;
    height: 50px;font-weight: 500;
    display: flex;
    color:#003d3d;
   }
   
   .identify-diff .D-card h4 {
    text-align: center;
    padding: 15px;
    margin: 0;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    background: #00ced5;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
   }
   
   .identify-diff .D-card .card-body ul li:nth-child(1){
       height: 40px;
   }
   .identify-diff .D-card .card-body {
       background: #18e1e87a;
       min-height: 320px;
       height: 320px;
       border-radius: 10px;
       border: 1px solid #18e1e87a;
       border-top: none;
       border-top-left-radius: 0;
       border-top-right-radius: 0;
   }
   
   .identify-diff .D-card .card-body ul {
       list-style-type: none;
       padding: 15px 10px;
       font-family: 'Karla', sans-serif !important;
       color: #ffffff;
       font-weight: 600;
       font-size: 16px;
       padding-right: 10px;
   }
   .color_back{
    background: white !important;
    }
    .feature-cnt{
        padding-bottom: 100px;
        padding-top: 6em;
    }
    .serv-data {
        background:#00ced5;
        padding: 5px;
        border-radius: 10px;
        min-height: 170px;
        height: 180px;
        margin: 15px;
        width: 30%;
    }
/* 31/10 */

.card-fot button,.learn-video button{
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    padding: 9px 33px!important;
}
.text-center.static-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}
























/* Responsive */
@media only screen and (max-width: 1264px) {
    .client-cmt {
        display: block!important;
        overflow: hidden;
    }
}

@media only screen and (max-width: 1024px) {
    .select-sec .sec-divided{
        top: -60px;
        bottom: unset;
    }
    .hiw-links .col-sm-1{
        display: none;
    }
    .client-cmt:after{
        display: none;
    }
    .sp_banner .main-h{
        font-size: 24px!important;
    }
    .hiw .hiw-1 ul li,.hiw .hiw-2 ul li,.hiw .hiw-3 p{
        font-size: 15px!important;
    }
    .googly-img img {
        height: 100%;
        object-fit: cover;
    }

    .width-400 {
        display: none;
    }

    .width-300 {
        width: 300px !important;
    }
    .you-tube .b4-img span.iim{
        transform: translate(130%);
        -webkit-transform: translate(130%);
        -moz-transform: translate(130%);
        -ms-transform: translate(130%);
        -o-transform: translate(130%);
    }
    .hiw .hiw-2 .hiw-box .hiw-links a{
        font-size: 14px;
    }
    .hiw .hiw-1 .hiw-box,.hiw .hiw-3 .hiw-box,.hiw .hiw-2 .hiw-box {
        padding-left: 36px!important;
        margin-top: 35px!important;
    }
    .sell-limity .b3-img span.iim{
        bottom: -31px;
    }
}

@media only screen and (max-width: 991px) {
    .blog-list .sell-limity:nth-child(3),.client-sec .client-card:nth-child(1){
        display: block;
    }
    .client-sec .client-card{
        width: 50%!important;
    }
    .sp_banner .banner-box{
        width: 65%!important;
        right: 127%!important;
    }
    .select-sec .sec-divided{
        padding: 25px 15px;
    }
    .sec-divided-flex span h4 {
        font-size: 14px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 990px) {
    .serv-data {
        text-align: center;
    }
    .serv-data {width: unset;}
    .services .serv-data span::after{
        left: 35%;
    }
    .you-tube {
        float: left;
        width: 50%;
    }
    .sp_banner .ban-lin-inner {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-flow: column;
        align-content: center;
        align-items: center;
    }
    .sp_banner .ban-lin-cnt .divider{
        display: none;
    }
}



@media only screen and (max-width: 768px) {
    .sp_banner .main-h {
        margin-top: 20%!important;}
        .sp_banner .main-h h1{
            font-size: 26px!important;
            text-align: center!important;
        }
        .sp_banner .main-h h3{
            font-size: 22px!important;
            text-align: center!important;
        }
    .sp_banner .banner-box{
        -webkit-transform: translate(208%, 40%)!important;
                transform: translate(208%, 40%)!important;
    }
    .you-tube {
        float: left;
        width: 48%;
        margin: 0 5px;
    }    
    .you-tube .b4-img span.iim{
        -webkit-transform:translate(150%);
        -moz-transform:translate(150%);
        -ms-transform:translate(150%);
        -o-transform:translate(150%);
    }
    /* .services-cnt .serv-tit h2{
        font-size: 18px!important;
    } */
    .services-cnt .serv-tit h4{
        font-size: 14px!important;
    }
    .serv-data {
        text-align: center;
    }
    .services .serv-data span::after{
        left: 35%!important;
    }
    .sp_banner .ban-lin-cnt{
        margin-top: 0px;
    }
    .sp_banner .ban-lin-inner{
        justify-content: flex-start;
        padding: 9px;
        margin: auto;
    }
    /* toggle */


	/* Stylinf the toggle lable */
	.toggle.respond {
		display: block;
		background-color: transparent;
		padding:14px 20px;	
		color:#FFF;
		font-size:17px;
		text-decoration:none;
        border:none;
        width: 75%;
        margin: auto;
	}

	.toggle.respond:hover {
		background-color: transparent;
	}

	/* Display Dropdown when clicked on Parent Lable */
	[id^=drop]:checked + .ban-lin-cnt {
        display: block!important;
        position: absolute;
        width: 94%;
        z-index: 999;
    }
    .toggle.respond + a,
	.ban-lin-cnt {
		display: none!important;
	}
    /* toggle */
    .navbar-default .navbar-collapse,
    .navbar-default .navbar-form {
        border-color: #e7e7e700;
    }

    .navbar-default .navbar-toggle {
        border-radius: 0px;
        border-color: #fff;
    }

    .navbar-default .navbar-toggle .icon-bar {
        background-color: #fff;
    }

    .navbar-default .navbar-toggle:focus,
    .navbar-default .navbar-toggle:hover {
        background-color: #00ced5;
    }


    .alpha-form {
        padding: 100px 0;
    }

    .News-letter {
        text-align: center;
    }

    .footer-above form.navbar-left {
        float: unset !important;
        text-align: center
    }

    .flex.justify-around {
        justify-content: space-evenly;
        padding: 35px 0;
        padding-bottom: 0;
    }

    .show-fotter .foot-bg {
        background-size: cover;
    }
    .sp_banner .ban-lin-inner{
        width: 75%;   
        text-align: center;
        grid-column-gap: 23px;
        grid-row-gap: 10px;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        background: #00ced5;
    }
    .sp_banner .ban-lin-cnt .divider{
        display: none;
    }
    /* 26/7/19 */
    .hiw .hiw-tit h2,.blog-cnt .blog-tit h2,.client-review{
        font-size: 24px!important;
    }
    .hiw .hiw-tit h4 {
        font-size: 22px!important;}
    .hiw .hiw-1 .hiw-box .hiw-links button, .hiw .hiw-2 .hiw-box .hiw-links button, .hiw .hiw-3 .hiw-box .hiw-links button {
        font-size: 15px;
    }
    .hiw .hiw-1 .hiw-box .hiw-links a, .hiw .hiw-2 .hiw-box .hiw-links a, .hiw .hiw-3 .hiw-box .hiw-links a {
        font-size: 14px!important;    padding-left: 5px!important;}
    .row.hiw-1 {
        padding-bottom: 30px;
    } 
    .hiw-links .col-sm-6:nth-child(1){
        padding: 0;
    }   
}

@media only screen and (max-width: 767px) {
    .sp_banner .main-h{
        margin-top:35%!important;
    }
    .sp_banner .main-h h3{
        font-size: 20px;
        text-align: left!important;
    }
    .sp-banner .banner-box {
        padding: 0;
    }
    .sp-banner .main-p {
        margin: 15px auto;
    }
    .sm-pd{
        padding:0px;
    }
    .w-res-mode{
        width:65%;
    }
    .sp_banner .main-h h1{
        font-size: 24px;
        text-align: left!important;
    }
    .sp_banner .main-h{
        margin: auto;
        line-height: 35px;
    }
    .checklist{
        flex-direction: column;
    }
    .Con-lft{
        height: 300px!important;    padding: 0% 10%;}
        .Home-form:after{
            height: 300px!important;    width: 100%!important;
        }
        .Con-lft h2 {
            padding-right: 0%!important;
            padding-bottom: 10px!important;}
    a.C-a {
        color: #fff;
        font-weight: 600;
        padding: 15px;
        font-size: 16px;
        text-align: center;}
    .C-a{display: block!important;}
    .res-call{display: none!important;}
    .identify-diff{flex-flow: column;}
    .footer-above .flex.justify-around{
        flex-direction: row;
    }
    .identify-diff .D-card{margin: auto;}
    .close-icon{
        display: block;
    }
    .profile.head{
        margin: 0;
    }
    .select-sec .sec-divided{
        /* display: none; */
    }
    .sp_banner .banner-box{
        position: relative;
        -webkit-transform: translate(220%, 40%)!important;
                transform: translate(220%, 40%)!important;
    }
    .services .serv-data span::after {
        left: 45%!important;
    }
    [id^=drop]:checked + .ban-lin-cnt {
        width: 96%;
    }
}

@media only screen and (max-width: 610px) {
    video{
        width: 100%;
        max-width: 100%;
    }
    .sp_banner .main-h h3,
    .sp_banner .main-h h1{
        text-align: left!important;
        padding-left:5px;
    }
    .sp_banner .banner-box{
        -webkit-transform: translate(220%, 0%)!important;
                transform: translate(220%, 0%)!important;
        padding:5px 0;
    }
    .client-sec .client-card{
        margin-bottom: 30px;
        width: 100%;
    }
    .client-sec{
        flex-flow: column;
    }
    .client-cmt {
        height: auto;
    }

    .identify-diff .D-card{margin: auto;}
    .blog-list .sell-limity:nth-child(3),.blog-list .sell-limity:nth-child(2),
    .client-sec .client-card:nth-child(1),.client-sec .client-card:nth-child(2)
    {
        display: block;
    }
    .client-sec .client-card{
        width: 100%!important;
    }
    [id^=drop]:checked + .ban-lin-cnt {
        width: 95%;
    }
    .you-tube {
        float: none;
        width: 100%;
    } 
    .you-tube .b4-img span.iim{
        -webkit-transform:translate(200%);
        -moz-transform:translate(200%);
        -ms-transform:translate(200%);
        -o-transform:translate(200%);
    }
    .Home-form{
        background-image: none!important;
    }
    .sell-limity .b3-img span.iim{
        height: 138px;
    }
    .fetu-width .buyer-grid{
        width: 100%;
    }
    .sell-limity{
        width: 95%;
    }
    .blog-cnt .flex{
        display: block!important;
    }
    .sell-limity .b2-img span.iim{
        background-size: cover;
        height: 100px;
        bottom: -17px;
    }
}

@media only screen and (max-width: 500px) {
    .sp_banner .main-h h3{
        font-size: 16px;
        text-align: left!important;
    }
    .sp_banner .main-h h1{
        font-size: 20px;
        text-align: left!important;
    }
    .main-flex button.btn-primary{
        padding: 6px 18px;
        margin: 0 4px 0;
        font-size: 16px;
    }
    .flex.soci{
        justify-content: flex-start!important;
    }
    .foot-link ul{
        text-align: left;
    }
    .identify-diff .D-card{
        width: 100%;
    }

    .sp_banner .banner-box {
        width: 90%!important;
        right: 192%!important;
    }
    
    .select-sec .sec-divided.buy{
        top: -323px;
    }
    .select-sec .sec-divided.sell{
        top: -215px;
    }
    .select-sec .sec-divided{
        position: absolute;
        margin: auto;
        width: 90%;
        left: 0;
        right: 0;
    }
    .sec-divided-flex{
        flex-direction: column;
    }
    .sec-divided-flex span {
        width: 100%;
    }
    .sp_banner .main-h{
        font-size: 18px!important;
        line-height:30px!important;
    }
    .serv-data {
        text-align: center;
    }
    .services .serv-data span::after{
        left: 42%!important;
    }
    /* .services-cnt .serv-tit h2{
        font-size: 18px;
    } */
    .services-cnt .serv-tit h4{
        font-size: 17px!important;
    }
    /* .blog-cnt .blog-tit h2{
        font-size: 18px!important;    
    } */
    .services-bt.flex{
        margin: 20px auto;
    }
    .services-bt .lt{
        /* margin-right: 0!important; */
    }
    .services-bt.ss .lt{
        margin-right: 0!important;
    }
    .services-bt .rt {
        margin-left: 0px!important;
    }
    .elty {
        width: 100%;
    }

    .width-300 {
        width: 100% !important;
    }

    .width-400 {
        display: block;
    }

    .foot-link.ft-after:before {
        display: none;
    }

    .foot-link.ft-before:before {
        display: none;
    }

    .footer-above form.navbar-left {
        box-shadow: none;
        border: none;
    }
    .rt .btn-secondary, .lt .btn-secondary,.rt .btn-primary, .lt .btn-primary {
        padding: 10px 46px;
        font-size: 14px;
        margin: 0px 10px;
    }
}

@media only screen and (max-width: 475px) {
    .w-res-mode{
        width:90%;
    }
    .res-pd{
        padding:0 10px;
    }
    .sp-banner{
        background-position: 57%;
    }
    .sp_banner .main-h h3{
        font-size: 18px;
        text-align: left!important;
    }
    .sp_banner .main-h h1{
        font-size: 22px;
        text-align: left!important;
    }
    .sp_banner .main-p{
        font-size: 15px!important;
    }
    .main-flex button.btn-primary{
        padding: 6px 18px;
        margin: 0 4px 0;
        font-size: 14px;
        width:100px;
    }
    .w-res-mode{
        width:80%;
    }
    .foot-link.ft-after.flex.justify-between {
        flex-flow: column;
        align-items: center;
        margin-bottom: 20px;
    }
    .flex.justify-between.main-fle {
        flex-direction: row;
    }
    .foot-link ul.pr-30 {
        padding-right: 30px;
        width: 200px;
    }
    .d-smn{height: 25px;
        display: none;
    }
    .mb-xs5{
        margin-bottom: 10px!important;
    }
    .sp_banner .main-p{   font-size: 18px!important;}
    .services-bt.flex,.main-flex{
        flex-flow: column;
    }
    .learn{
        display: none;
    }
    .services .serv-data h4,.services .serv-data p{
        text-align: left;
    }
    .L-a{font-size: 14px;
        color: #000;
        font-weight: 700;
        text-align: left;
        margin: 5px 8px;}
    .hidden.L-a{display: block!important;}
    .sp_banner .main-h{
        margin-top: 55%!important;
    }
    .sp_banner .main-h h1{font-size: 28px!important; text-align: left!important;}
    .sp_banner .main-h h3{font-size: 18px!important; text-align: left!important;}

    [id^=drop]:checked + .ban-lin-cnt {
        width: 94%;
    }
    /* .hiw .hiw-tit h2 {
        font-size: 18px!important;
    } */
    .hiw .hiw-tit h4 {
        font-size: 16px!important;
    }
    /* .hiw .hiw-tit{
        padding-bottom: 0!important;
    } */
    /* }  */
    .sp_banner .main-h h1,.sp_banner .main-h h3{
        padding-left: 0;
        padding-right: 0;
    }
    
}

@media only screen and (max-width: 425px) {
    .sp-banner .main-h{
        margin-top:70%!important;
    }
    .sp-banner .main-p,
    .sp-banner .main-h h1,
    .sp-banner .main-h h3{
        margin:5px auto;
    }
    .w-res-mode{
        width:65%;
    }
    .foot-link ul.pr-30{
        padding: 0;}
        .foot-link ul.pr-30 {
            padding-right: 30px;
            width: 160px;
        }
        .d-b{
            display:block!important
        }
    .footer-above .flex.justify-around {
        flex-direction: column;
    }
    .toggle.respond.filter{
        margin: 19% auto!important;
        margin-bottom: 5%!important;
    }
    [id^=drop]:checked + .ban-lin-cnt {
        width: 93%;
    }
    .you-tube .b4-img span.iim{
        -webkit-transform: translate(165%);
        -moz-transform: translate(165%);
        -ms-transform: translate(165%);
        -o-transform: translate(165%);
    }
}

@media only screen and (max-width: 375px) {
    .sp_banner .main-h h3, .sp_banner .main-h h1{
        padding-left: 0;
    }
    .w-res-mode{
        width:68%;
    }
    .main-flex{
        margin-left: auto!important;
    }
    .foot-link ul.pr-30 {
        padding-right: 30px;
        width: 140px;
    }
    .sp_banner .main-p{
        margin: 5px 0px!important;
    }
    .snow-header a.navbar-brand {
        font-size: 15px;
    }
    a.C-a {
        font-size: 14px!important;}
    /* .sp_banner .banner-box{
        transform: translateY(20%)!important;
    } */
    [id^=drop]:checked + .ban-lin-cnt {
        width: 92%;
    }
    .you-tube .b4-img span.iim{
        -webkit-transform: translate(115%);
        -moz-transform: translate(115%);
        -ms-transform: translate(115%);
        -o-transform: translate(115%);
    }
    .services .serv-data span::after {
        left: 38%!important;
    }
    .foot-bg.pad-30.pad-5 {
        padding: 5px;
    }

    .News-letter h4 {
        font-size: 18px;
    }

    .News-letter p {
        font-size: 14px;
    }

    .foot-link li {
        font-size: 12px;
    }

    .foot-link .social ul li {
        font-size: 13px;
        padding: 3px 5px;
    }

}

@media only screen and (max-width: 425px) {

    /* .pad-55.elty{
        padding: 30px;
        padding-top: 20px;
    } */
    .navbar-default .navbar-nav.header-right > li.divider {
        background: white;
        height: 2px;
        padding: 0px;
        width: 93%;
        margin: auto;
        margin-top: 0;
    }
 
    .navbar-default .navbar-nav.header-right > li > a {
        padding: 10px 20px;
    }
}

/* End Responsive */

/* chceklist design */
    .checklist ul li.list-btn button{
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        padding: 9px 33px!important;
    }
    .checklist ul li.list-btn{
        text-align: center;
        margin: 20px auto;
    }
    .checklist ul.complete li.list-btn::before{
        content: '';
    }
    .checklist {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .checklist ul {
        list-style: none;
        padding: 15px;
        box-shadow: 0px 0px 6px 0px #00000024;
        background: hsla(182, 100%, 42%, 0.04);
        border-radius: 8px;
    }
    .checklist ul h5{
        text-align: left;
        font-size: 24px;
        font-weight: 600;
        text-transform: capitalize;
    }
    .checklist ul li {
        font-size: 18px;
        line-height: 38px;
        font-weight: 500;
    }
  
    .checklist  ul.complete li::before {
        content: '✔';
        font-weight: 600;
        color: #faad39;
        padding-right: 10px;
    }
    
    .checklist  ul.incomplete li::before {
        content: '☐ ';
    }
    .pb-3{
        padding-bottom: 3em;
    }
    
/* chceklist design end */
.Con-lft a:hover{
    color: #ffffff;
}
.Con-lft a{
    padding: 10px 16px;
    font-weight: 600;
    background: #faad39;
    color: #ffffff;
}   
.Con-lft h2{
    padding-right: 25%;
    padding-bottom: 20px;
    font-size: 36px;
    font-weight: 600;
    color: #ffffff;
}
.Con-lft{
    height: 600px;
    display: flex;
    align-items: start;
    flex-flow: column;
    justify-content: center;
    z-index: 9999;
    position: relative;
}
.Home-form:after{
    content: '';
    position: absolute;
    background: #00ced5;
    width: 47%;
    /* background-image: linear-gradient(45deg, #00ced5, #1eacd7); */
    height: 100%;
    top: 0;
    z-index: 999;
}
.limity-body p{
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
    min-height: 140px;
    max-height: 140px;
    flex-wrap: nowrap;
}
.blog-carosal{
    margin: 5% auto;
    -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
    transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
    /* Chrome, Safari, Opera */
    -webkit-animation-name: moveit;
    -webkit-animation-duration: 2s;
    /* Standard syntax */
    animation-name: moveit;
    animation-duration: 2s;
}
.blog-tit {
    /* margin-top: 15%; */
    font-size: 24px;
    font-weight: 500;
}
@-webkit-keyframes moveit {
    from {-webkit-transform: scale(0);transform: scale(0);-webkit-transition: all 5s cubic-bezier(0.4, 0, 1, 1);transition: all 5s cubic-bezier(0.4, 0, 1, 1);}
    to {-webkit-transform: scale(1);transform: scale(1);}
}
@keyframes moveit {
    from {-webkit-transform: scale(0);transform: scale(0);-webkit-transition: all 5s cubic-bezier(0.4, 0, 1, 1);transition: all 5s cubic-bezier(0.4, 0, 1, 1);}
    to {-webkit-transform: scale(1);transform: scale(1);}
}


@media only screen and (max-width: 850px) {
    .blog-carosal .sell-limity:nth-child(1){
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .blog-carosal .sell-limity:nth-child(2){
        display: none;
    }
}
.popup-body{
    margin: 2em auto;
}
.popup-ft .d-block{
    margin: 15px;
} 

.popup-text-center{
    margin: 0 auto;
    text-align: center;
}

.pop-dialog{
    width: 40%;
    top: 25%;
}

.bid-hiy  .modal-body.video-body{
    margin: 30px;
}

.modal-body.video-body video{
    width: 100%;
    height: auto;
}

.bid-hiy .close{
    margin: 7px;
}
.trans-show{
    -webkit-transform: translate(0px, 30%);
            transform: translate(0px, 30%);
}
.top-15em{
    top:15em;
}

.center-arrow-featurelisting-left{
    font-size : 5rem;
    opacity : 0.4;
    margin : auto 0;
    padding-right : 1em;
    -webkit-transition: .5s;
    transition: .5s;
}
.center-arrow-featurelisting-left:hover{
    -webkit-transition: .5s;
    transition: .5s;
    opacity: 0.6;
}
.center-arrow-featurelisting-right:hover{
    -webkit-transition: .5s;
    transition: .5s;
    opacity: 0.6;
}
.center-arrow-featurelisting-right{
    font-size : 5rem;
    opacity : 0.4;
    margin : auto 0;
    padding-left : 1em;
    -webkit-transition: .5s;
    transition: .5s;
}
.text-align-left{
    text-align: left;
    font-weight: 400 !important;

}
.feture-title{
    padding: 10px 0;
    border-bottom: 1px solid #e3e3e3;
}
.feture-title span{
    font-size: 15px;
}


.mod-place-bid .modal-dialog.strip-pop{
    width: 600px!important;
}
.strip-pop .modal-content{
    padding: 50px 0;
}
input.plce-text::-webkit-input-placeholder{
    color: #000;
    font-size: 18px;
}
input.plce-text::-moz-placeholder{
    color: #000;
    font-size: 18px;
}
input.plce-text:-ms-input-placeholder{
    color: #000;
    font-size: 18px;
}
input.plce-text::-ms-input-placeholder{
    color: #000;
    font-size: 18px;
}
input.plce-text::placeholder{
    color: #000;
    font-size: 18px;
}

.pt-0{
    padding-top: 0px !important;
}

.modal-header h3 ::before {
    position: absolute;
    background: #00CED5;
    width: 7px;
    height: 20px;
    left: -15px;
    top: 4px;
    border-radius: 20px;
}
#paypal-button-container {
  width: 250px;
}
.result-padding { 
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  padding-top: 20px;
}
.search-count select {
max-width: 350px;
}
.search-count {
padding-bottom: 15px;
padding-top: 15px;
}
.search-inner {
 margin-top: 20px;
 margin-bottom: 50px;
}
.justify-content-bet{
justify-content: space-between
}
.savedSearch-dropdown{
border: 1px solid #00ced5;
color: #00ced5;
padding: 0.4em;
}
.prolist-wrap .pro-single {
flex-basis: 31%;
}
.pagination-text {
padding-top: 35px;
}
.pagination-wrap {
margin-top: 20px;
}
.content .show-check {
font-size: 18px;
color: #1d1d1d !important;
font-weight: 500;
font-family: 'Karla', sans-serif !important;
}
.cat-check {
font-size: 12px;
margin-bottom: 35px;
width: 100%;
float: left;
}
.buyer-grid a{
text-decoration: none;
color:#5a5b5b;
}
.main-mar-pro{
  margin-right: 15px!important;
}
.flex-start{
align-items: flex-start;
}
[id^=drop] {
display: none!important;
}

.center { 
align-items: center; 
margin: 6em;
} 
.watch{
content: '';
background-image: url(/images/icons/star.svg);
position: absolute;
width: 25px;
height: 25px;
background-repeat: no-repeat;
background-position: left;
left: 10px;
top: 10px;
cursor: pointer;
z-index: 9999;
}

.savedSearchBackground {
content: '';
position: absolute;
background-image: url(/images/star.svg);
background-repeat: no-repeat;
background-position: center;
background-size: contain;
width: 25px;
height: 25px;
right: 5px;
bottom: -4px;
}
































@media only screen and (max-width: 985px) {
.flex-center{
  justify-content:center!important;
}
.filter-tit{
  display: none;
}
[id^=drop]:checked + .category{
    display: block!important;
    position: relative;
    width: 75%;
    z-index: 999;
    margin: auto;
}
.filter-body .accordion{
  width: 100%;
}
.toggle.respond + a,
.category {
    display: none!important;
}
/* Stylinf the toggle lable */
.toggle.respond {
    display: block!important;
    background-color: #00ced5;
    padding:14px 20px;	
    color:#FFF;
    font-size:17px;
    text-decoration:none;
    border:none;
    width: 75%;
    margin: auto;
}
.toggle.respond:hover {
    background-color: #00ced5;
}
.toggle.respond {
  margin: 13% auto;
  margin-bottom: 5%;
}
.find-card .inner-card{
  padding: 30px 35px!important;
}
.sort {
  padding: 10px 0px;
  margin: auto;
}
}
@media only screen and (max-width: 600px){
.sort.width-15{
  width: 50% !important;
}
}
@media only screen and (max-width: 475px) {
.select-two.slect-sort{
  margin-bottom: 15px;
}
.flex-flow{
  flex-flow: column;
}
section.filter-body{
  margin-top: 20em;
}
}
@media only screen and (max-width: 425px) {

.find-card h3 {
  font-size: 22px!important;
  margin: 0 15px!important;
  margin-bottom: 30px!important;
}
.range-slider {
  display: block!important;
}
.range-slider span.range{
  width: 100%!important;
  text-align: center!important;
  display: block;
}
.find-card .inner-card{
  margin: 0px!important;
}
.pro-center{
  float: unset!important;
  text-align: center;
}
}
.PrivateValueLabel-label-19 {
    color: #fff !important;
    font-size: 10px;
}
.content.ranger span:nth-child(1),.content.ranger span:nth-child(3){
    width: 25%;
}
.content.ranger span:nth-child(3){
    text-align: right;
}
.watch_s {
    content: '';
    background-image: url(/images/icons/star.svg);
    position: absolute;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: left;
    left: -40px;
    top: -4px;
    cursor: pointer;
    z-index: 9999;
}
.watch_f {
    content: '';
    background-image: url(/images/star.svg);
    position: absolute;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: left;
    left: -40px;
    top: -4px;
    cursor: pointer;
    z-index: 9999;
}

.main-mar-pro.saved_search_buyer {
    margin: 20px 3px !important;
}
.checkbox-list::first-letter{
    text-transform: uppercase;
}
.show-check::first-letter{
    text-transform: uppercase;
}
.price-range .range-slider span{
    color:none !important;
}
.center-part .sch-banner-buyer {
    background-image: url(/images/home-banner.png);
    height: 60vh;
    background-repeat: no-repeat;
    background-position: 100% 38%;
    background-size: cover;
    position: relative;
}
.relative{
    position: relative;
}
.absolute{
    position: absolute;
}
#minmaxslider span:nth-child(1){
    left:13.5em;
}
#minmaxslider span:nth-child(2){
    right:-1.5em;
}



/* .B-da .price-rang input[value="$"] {
    color: #a5a5a5;
} */
.B-da .price-rang .P-input {
    background: #f3f3f3;
    width: 85px;
    border-radius: 2px;
    height: 30px;
    font-size: 14px;
    color: #000000;
    border: 1px solid #ddd;
    padding: 0 5px;
    text-align: left;
    outline: 0;
    box-shadow: none;
    margin-left: 7px;
}

.B-da .price-rang .ui-widget.ui-widget-content {
    border: 1px solid #dedede;
    margin: 20px auto;
    background: #4a4a4a30;
}

.B-da .price-rang .ui-slider-horizontal {
    height: 4px;
}
.B-da .slider-range{

}

.ui-slider-horizontal .ui-slider-handle {
    top: -6px;
}

.B-da .price-rang .ui-state-default,
.B-da .price-rang .ui-widget-content .ui-state-default,
.B-da .price-rang .ui-widget-header .ui-state-default,
.B-da .price-rang .ui-button,
.B-da .price-rang html .ui-button.ui-state-disabled:hover,
.B-da .price-rang html .ui-button.ui-state-disabled:active {
    border: 1px solid #00ced5  !important;
    background: #00ced5 !important;
    font-weight: normal;
    color: #ffffff;
    border-radius: 100px;
    outline: 0;
    width: 1em;
    height: 1em;
}

.B-da .price-rang .ui-widget-content .ui-state-default:hover,
.B-da .price-rang .ui-widget-content .ui-state-default:active {
    box-shadow: 0px 0px 0px 7px #00ced542;
}
.savedsearchform{
    display: flex;
    flex-direction: column;
}
.savedSearchFormNurron{
    /* width: 50%; */
}
.titleinput{
    display: flex;
    margin: 1em 0em 0em 0em;
}
.SavedsearchButton{
    margin: 0em 0em 0em 1em;
    border-radius: 10px;
    border: 1px solid #00ced5;
    color: #00ced5;
}
.SavedSearchButtonOne{
    margin: 0 0 0 8em;
    background: #fff;
    padding: 10px 14px;
    font-size: 15px;
    outline: 0;
}
.errclass{
    border : 1px solid red;
}
.savedclose{
    border: 0;
    color: red;
    opacity: 0.9;
}
.savedclose:hover{
    background:white
}
.gandd{
   padding: 0em 1em 0em 0em 
}
.card{
    border: 1px solid lightgrey;
    box-shadow: 0px 2px 10px -1px lightgrey;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
    }




    @media (max-width:986px){
        .SavedSearchButtonOne{
            margin: auto;
        }
    }
.result-padding { 
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    padding-top: 20px;
}
.search-count select {
 max-width: 350px;
}
.search-count {
  padding-bottom: 15px;
  padding-top: 15px;
}
.search-inner {
   margin-top: 20px;
   margin-bottom: 50px;
}

.prolist-wrap .pro-single {
flex-basis: 31%;
}
.pagination-text {
  padding-top: 35px;
}
.pagination-wrap {
  margin-top: 20px;
}
.cat-check {
  font-size: 12px;
  margin-bottom: 35px;
  width: 100%;
  float: left;
}

@media only screen and (max-width : 991px) {
  .toggle.respond.filter{
    margin: 6% auto;
    margin-bottom: 5%;
  }
  .filter-tit{
    display: none;
  }
  .toggle.respond.filter , [id^=dropfilter]{
    display: block!important;
  }
  .toggle.respond.filter + a, .category {
    display: none!important;
}
}

@media only screen and (max-width : 768px){
  .toggle.respond.filter{
    margin: 3% auto 3%!important;
  }
}
@media only screen and (max-width :475px) {
  .toggle.respond.filter {
    margin: 25% auto;
    margin-bottom: 5%;
  }
}

@media only screen and (max-width : 320px){
  .toggle.respond.filter {
    margin: 38% auto;
    margin-bottom: 5%;
  }
}

.searchwachlisthide:before{
  background: none !important;
}
 .modal-dialog .productPopUp {
    /* position: relative; */
    width: 65em !important;
    /* margin: 10px; */
} 
.text-center{
    text-align: center
}
.btn-secondary-err {
    background: #ffffff;
    color: red !important;
    font-size: 16px;
    font-weight: 400;
    border-radius: 9px;
    padding: 10px 50px;
    border-color: red !important;
    text-transform: capitalize;
}
.btn-secondary {
    background: #ffffff;
    color: #00ced5;
    font-size: 16px;
    font-weight: 400;
    border-radius: 9px;
    padding: 10px 50px;
    border-color: #00ced5;
    text-transform: capitalize;
    margin-top: 10px !important;
}
.property-con{
    width: 185px;
    line-height: 20px;
}
.property-location{
    width: 185px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.bid-hiy.selt-propty .modal-body {
    min-height: auto;
    height: auto;
    max-height: 1000px;
    overflow-y: auto;
    overflow-x: hidden;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  } 
  .upload-btn-wrapper input[type=file] {
    /* font-size: 100px;
    position: absolute; */
    left: 0;
    top: 0;
    opacity: 0;
  }
  .upload-btn-wrapper .btn-primary {
    padding: 8px 12px;
}
  .upload-btn-wrapper .flaticon-upload-button:before {
    font-size: 15px;
}

.upload-btn-wrapper img {
    height: 18px;
    width: 20px;
    float: right;
    padding-left: 4px;
}

.modal .d_block{
  display: block;
}

.deed-textarea{
  width: 100%;
  height: 6em;
  margin: 2em;
  padding: 1em;
}

.reff .d-inline{
  display: inline-block;
}
.fullwidth{
    width : 100%;
}
.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    /* line-height: 1; */
    color: #000;
    text-shadow: 30 8px 53 #fff;
    filter: alpha(opacity=20);
    opacity: .5;
}
.selectedOption{
    background:rgba(0,0,255,.1)
}

.pan-block span.token {
    padding: 8px 8px;
    border: 1px solid #A8A8A8;
    margin: 3px 2px;
    display: inline-flex;
    font-size: 13px;
    color: #000;
    cursor: pointer;
    font-family: 'Karla', sans-serif !important;
}
.pan-block span.token i {
    line-height: 20px;
    padding: 0 0px 0 20px;
}
.fs-16{
    font-size: 16px;
}
.display-flex{
    display:flex;
}
.radio-div-padding{
    flex-direction: column;
    padding: 1.8em 4em 0em 1em;
}
.position-relative{
    position:relative;
}
.position-absolute{
    position:absolute;
}
.top-negative{
    top: 9.2em;
}
.realtor-label{
    display: flex!important;
    flex-direction: column;
    align-items: center;
}
.top-em{
    margin-top : 1em
}
.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    /* line-height: 1; */
    color: #000;
    text-shadow: 30 8px 53 #fff;
    filter: alpha(opacity=20);
    opacity: .5;
}
.selectedOption{
    background:rgba(0,0,255,.1)
}

.pan-block span.token {
    padding: 8px 8px;
    border: 1px solid #A8A8A8;
    margin: 3px 2px;
    display: inline-flex;
    font-size: 13px;
    color: #000;
    cursor: pointer;
    font-family: 'Karla', sans-serif !important;
}
.pan-block span.token i {
    line-height: 20px;
    padding: 0 0px 0 20px;
}
.fs-16{
    font-size: 16px;
}
.display-flex{
    display:flex;
}
.radio-div-padding{
    flex-direction: column;
    padding: 1.8em 4em 0em 1em;
}
.position-relative{
    position:relative;
}
.position-absolute{
    position:absolute;
}
.top-negative{
    top: 9.2em;
}
.realtor-label{
    display: flex!important;
    flex-direction: column;
    align-items: center;
}
.top-em{
    margin-top : 1em
}
.googly-img img {
    object-fit: cover;
    min-height: 565px;
    height: 565px;
    max-height: 565px;
    width: 100%;
    object-position: center;
}



.main-mar-pro {
    margin: 20px 8px !important;
}

.dashboard-card{
    /* height: 18.5em; */
}
.text-red{
    color:red;
}
.bids-details span {
    line-height: 35px;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
    font-size: 15px;
}
.flex-direction-coloum{
    flex-direction: column;
}

















@media only screen and (max-width: 500px){
    .sell-rw .flex{
        flex-flow: column;
    }
    .sell-rw{
        text-align: center;
    }
    .sell-rw .pad-lft-20{
        padding-left: 15px;
    }
    .sell-rw .col-md-6 {
        width: 100%!important;
        float: left;
    }
}

.d_block{
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
}
.top-10{
    top: 10em;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  } 
  .upload-btn-wrapper input[type=file] {
    /* font-size: 100px;
    position: absolute; */
    left: 0;
    top: 0;
    opacity: 0;
  }
  .upload-btn-wrapper .btn-primary {
    padding: 8px 12px;
}
  .upload-btn-wrapper .flaticon-upload-button:before {
    font-size: 15px;
}

.upload-btn-wrapper img {
    height: 18px;
    width: 20px;
    float: right;
    padding-left: 4px;
}

.modal .d_block{
  display: block;
}

.deed-textarea{
  width: 100%;
  height: 6em;
  margin: 2em;
  padding: 1em;
}

.reff .d-inline{
  display: inline-block;
}

.main-mar-pro {
    margin: 20px 8px !important;
}


.dashboard-card{
    /* height: 18.5em; */
}
.text-red{
    color:red;
}
.bids-details span {
    line-height: 35px;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
    font-size: 15px;
}
.flex-direction-coloum{
    flex-direction: column;
}

















@media only screen and (max-width: 500px){
    .sell-rw .flex{
        flex-flow: column;
    }
    .sell-rw{
        text-align: center;
    }
    .sell-rw .pad-lft-20{
        padding-left: 15px;
    }
    .sell-rw .col-md-6 {
        width: 100%!important;
        float: left;
    }
}


.toggle.respond,
[id^=drop] {
	display: none!important;
}

@media only screen and (max-width: 768px) {
    [id^=drop]:checked + .dash-ul{
        display: block!important;
        position: relative;
        width: 75%;
        z-index: 999;
    }

    .toggle.respond + a,
    .dash-ul {
        display: none!important;
    }

   

    /* Stylinf the toggle lable */
    .toggle.respond {
        display: block!important;
        background-color: #00ced5;
        padding:14px 20px;	
        color:#FFF;
        font-size:17px;
        text-decoration:none;
        border:none;
        width: 75%;
        margin: auto;
    }

    .toggle.respond:hover {
        background-color: #00ced5;
    }

    
    .dash-ul .ul-list{
        width: 100%;
        text-align: left;
        padding: 15px;
    }
    .dash-ul .ul-list li{
        background: transparent;
    }
    .dash-ul .ul-list li.active{
        background: #003b3d;
        color: #FFF;
    }
    .filter-body .nav-dash .ul-list li.active:before, .filter-body .nav-dash .ul-list li:hover:before{
        display: none;
    }
    .filter-body .nav-dash .ul-list li.active a, .filter-body .nav-dash .ul-list li:hover a{
        color: #FFF!important;
        
    }
    .dash-ul{
        width: 75%;
        text-align: center;
        grid-column-gap: 23px;
        grid-row-gap: 10px;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        background: #00ced5;
        margin: auto;
    }

}

.dashboard_profile::before{
    background: none !important;
}
.grid-container .item0{
    grid-column: span 4;
    grid-row: 1 / span 3;
}
.grid-container .item0 img {
    width: 325px;
    height: 185px;
    min-height: 185px;
    object-fit: cover;
}

span.imageDelete {
    position: absolute;
    color: #fffffff7;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background: #48b1f8;
    padding: 2px 7px;
    border-radius: 50%;
    font-weight: 600;
}

span.imageDelete:hover {
    background: #fff;
    cursor: pointer;
}

.item1 {
    grid-column: 5 / span 2 !important;
    grid-row: 1 !important;
}

.item2 {
    grid-column: 5 / span 2 !important;
    grid-row: 2 !important;
}

.item3 {
    grid-column: 5 / span 3 !important;
    grid-row: 3 !important;
}

.grid-container .listImage img {
    width: 85px !important;
    height: 56px !important;
    object-fit: cover !important;
}
#singlealis .single-alis {
    /* width: 60%; */
    float: left;
    /* padding-right: 10%; */
}
#singlealis .multi-alis img{
    width: 85%;
    float: right;
    margin-right: 10px;
}
@media (max-width:1190px){
    #singlealis .single-alis img {
        height: 200px;
        min-width: 18em;
        margin: auto;
        object-fit: cover;
        margin-right: 10px;
    }
}
@media (max-width:986px){
    #singlealis .single-alis img {
        height: 200px;
        min-width: 24em;
        margin: auto;
        object-fit: cover;
        margin-right: 10px;
    }
    #singlealis .multi-alis img {
        width: 50%;
        float: right;
        margin-right: 40px;
    }
}
@media (max-width:734px){
    #singlealis .multi-alis img {
        width: 73.5%;
        float: right;
        margin-right: 40px;
        /* display: flex; */
        /* flex-direction: column; */
        float: left;
        margin-top: 10px;
    }
    #singlealis {
        flex-direction: column
    }
}
@media (max-width:600px){
     .single-alis img {
        width: 100%;
        min-width: 100%;
    }
}

.d-block{
    display: inline-block;
    margin: 0 3px;
}

.btn-primary_c {
    background: none;
    color: #18BBD5;
    font-size: 14px;
    font-weight: 400;
    border-radius: 24px;
    padding: 3px 7px;
    border-color: #18BBD5;
    text-transform: uppercase;
    top: 20%;
    margin-left: 0.5em;
}
.btn-primary_c:hover {
    background: #18BBD5;
    color: white;
    font-size: 14px;
    font-weight: 400;
    border-radius: 24px;
    padding: 3px 7px;
    border-color: #18BBD5;
    text-transform: uppercase;
    top: 20%;
    margin-left: 0.5em;
}
.text-dubdetail-label {
    /* font-size: 18px; */
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}

.overflow_chat{
    
}
.chating .chat-body .fixed-center.overflow_chat {
    overflow: auto ;
    overflow-x: hidden;
}
.user-info {
    padding: 5px;
}
.loadmore{
    margin: 21px 24px 16px 21px;
    background: rgba(55,204,148,0.4);
    text-align: center;
    color : black;
    box-shadow: 1px 2px 8px -1px rgba(55,204,148,0.4);
    max-width: 200px;
    /* padding-left: 25px; */
    margin-left: 158px;
}
.toggle.respond.msg,
[id^=dropmsg] {
    display: none!important;
    width: 100%;
}

.message-right-div{
    height: 40em;
    overflow: auto;
    scroll-behavior: auto;
    scrollbar-width: thin !important;
    /* scroll-padding: 1px; */
    /* scroll-margin: 0px; */
}

.user-chat .user-im{
    width: 60px;
    height: 60px;
    margin: 2px auto;
    border-radius: 100px;
    position: relative;
    border: 3px solid #496587;
    background: #F0F7FF;
}

.user-im div{
    margin: 10px auto;
}

.user-im div span{
    display: block;
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    color: #496587;
}

.user-im div .role{
    font-size: 10px;
}

@media only screen and (max-width: 768px){
   
   
    .toggle.respond.msg {
        display: block!important;
        background-color: #00ced5;
        padding:14px 20px;	
        color:#FFF;
        font-size:17px;
        text-decoration:none;
        border:none;
        width: 100%;
        margin: auto;
    }
    .toggle.respond.msg + a,
    .div-after {
        display: none!important;
    }
    [id^=dropmsg]:checked + .div-after{
        display: block!important;
        position: relative;
        width: 100%;
        z-index: 999;
    }

}
@media only screen and (max-width : 600px){
    .loadmore{
        margin-left: auto;
        margin: 21px auto;
    }
}
@media only screen and (max-width: 375px){
    .mesage-body .user-chat{
        margin: 16px 0px;
    }
}
 /* Hide the browser's default radio button */
 .check .con input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    margin: 0px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #00ced5;
  }
  
  /* On mouse-over, add a grey background color */
  .check .con:hover input ~ .checkmark {
    background-color: #fff;
  }
  
  /* When the radio button is checked, add a blue background */
  .check .con input:checked ~ .checkmark {
    background-color: #00ced5;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .check .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .check .con input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .check .con .checkmark:after {
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: white;
    border: 2px solid #abf3ff;
  }
  .check .con {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 17px;
    line-height: 25px;
    margin: 15px;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.d-inline-block{
    display: inline-block !important;
}
.ml-4{
    margin-left: 20px;
}
 .widthauto {
    width: auto !important;
    box-shadow: 0px 3px 10px 0px #0000001f;
}
.commission-question{
    display: flex;
    padding: 1em;
}
.justify-between{
    justify-content: space-between;
}
.width-100{
    width:100%;
}
.margin-finne input[type="datetime-local"]{
    width:13em;
}

.margin-finne .MuiInput-underline:before,.margin-finne .MuiInput-underline:after,
.date-mark .MuiInput-underline:before,.date-mark .MuiInput-underline:after
{display: none;}
.margin-finne .MuiFormControl-root{width:100%;}
.date-mark .MuiFormControl-root{width:100%;}
.margin-finne P,.date-mark p{display: none;}
.date-mark .MuiInputBase-input{   
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    height: 48px;
    font-family: 'Karla', sans-serif !important;}

.margin-finne .MuiInputBase-input,.date-mark .MuiInputBase-input{
    font-size: 1.5rem!important;
    color: #9E9E9E;
}
.date-mark .MuiInputBase-input{
    padding:0em 1em
}
.margin-finne .MuiInput-root{
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    height: 48px;
    font-size: 16px;
    font-family: 'Karla', sans-serif !important;
    padding: 10px 20px;
    width: 100%;
    text-transform: uppercase;
}
.commission-question-div{    display: flex;
    align-items: baseline;}

.commission-question-r{
    display: flex;
flex-direction: column;
}
#singlealis .single-alis{
    position: relative;
}

@media only screen and  (max-width:600px){
    .po-st{
        flex-flow: column;
    }
    .po-st .pad--both-100{
        padding: 30px 30px;
    }
    #singlealis .single-alis img{
        width: 100%;
        min-width: 100%;
    }
    .cl-bot{
        clear: both;
    }
}

.upload-btn-wrapper img{
    display: block;
}
.add-remove-card {
    text-align: right;
    padding: 5px 150px 0px 0px;
    color: #00ced5;
    cursor: pointer;
}

.add-remove-card i{
    padding: 0px 3px 0px 0px;
}





.flexcalss{
    display:flex;
    justify-content: center;
}
.alpha-form1_support{
    width: 50%;
    margin: auto;
}

.alpha-form1 label {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}
.text-uppercase{
    text-transform: uppercase;
}
th{
    text-align : center
}
td::first-letter{
    text-transform: uppercase;
}
 .text-noproduct-label {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}
.paddingem-top-1{
    padding-top:1em !important;
}


#tooltip{
    position: relative;
    display: inline-block;
}
#tooltip .tooltip_base{
    visibility: hidden;
  width: 120px;
  background-color: #6A6A6A;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
    word-wrap: break-word;
    width: 300px;
      height: 150px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
#tooltip .tooltip_base {
    bottom: -10px;
    right: 105%; 
  }
#tooltip:hover .tooltip_base {
    visibility: visible;
  }
  
.scnd-slider img {
    object-fit: cover;
    min-height: 70px;
    height: 70px;
    width: 130px;
}
.scnd-slider {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    position: relative;
}
.slider-a img {
    width: 100%;
    height: 350px;
    min-height: 350px;
    object-fit: cover;
}
.scnd-slider:after{
    content: '';
    position: absolute;
    right: 0;
    background: no-repeat #00ced5 url(/images/icons/right-arrow.svg) center center;
    padding: 10px;
    z-index: 1;
    top: 45px;
    border-radius: 50px;

    cursor: pointer;
}
.scnd-slider:before{
    content: '';
    position: absolute;
    left:0;
    border-radius: 50px;
    background:no-repeat #00ced5 url(/images/icons/left-arrow.svg) center center;
    padding: 10px;
    z-index: 1;
    top: 45px;
    cursor: pointer;
}

.d_block{
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
}

.bid_error{
    display: block;
    color: red;
    text-align: center;
}

.bidstatus{
    padding-top: 25px;
    text-align: center;
    width: 80%;
}
.view-elip span:nth-child(2){
cursor: pointer;
}
.view-elip span:nth-child(1){
    width: 70%;
    word-break: break-word;
}

.backBtn{
    content: '';
    position: absolute;
    left: 0;
    border-radius: 50px;
    background: no-repeat #00ced5 url(/images/icons/left-arrow.svg) center center;
    padding: 10px;
    z-index: 5;
    top: 45px;
    cursor: pointer;
}

.nextBtn{
    content: '';
    position: absolute;
    right: 0;
    background: no-repeat #00ced5 url(/images/icons/right-arrow.svg) center center;
    padding: 10px;
    z-index: 5;
    top: 45px;
    border-radius: 50px;
    cursor: pointer;
}
.font-size-1_rem{
    font-size: 1.7rem;
}
.hovpopup{
    position: relative;
}
.justifycontent-between{
    justify-content: space-between;
}
.hovpopup .hvpop:hover + .hovermsg{
    display: block;
}

span.hovermsg::first-letter{
    text-transform: uppercase;
}

span.hovermsg {
    border: 1px solid #eee;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    padding: 12px;
    position: absolute;
    z-index: 1031;
    opacity: 1;
    background: #fff;
    right: auto;
    top: 2;
    left: 0;
    width: 25em;
    display: none;
    text-align: center;
    word-wrap: inherit;
    color: #7d7875;
}

#bidModal .modal-body form {
    display: block;
}

@-webkit-keyframes FadeAnimation {
    0% {
      opacity: 1;
      visibility: visible;
    }
  
    100% {
      opacity: 0;
      visibility: hidden;
    }
}

@keyframes FadeAnimation {
    0% {
      opacity: 1;
      visibility: visible;
    }
  
    100% {
      opacity: 0;
      visibility: hidden;
    }
}
.contact {
    text-align: center;
}
.btn .btn-red{
    padding: 9px 33px;
}
.preTag{
    padding: 4px;
    border: 1px solid;
}

.errMsgmargin{
    margin:5px;
}
.errMsgmargin{
    margin:5px;
}
.top-hd.my-hd h4:before {
    left: 60px;
    width: 30px;
}
/* 31/07/19 */
.negosiate-bg{
    width: 100%;
    position: relative;
    padding-top: 15%;
}
.negosiate-bg svg{
    width: 100%;
}
.negosiate-bg .sample-txt h2{
 color: #002B5E;
 font-size: 28px;
}
.negosiate-bg .sample-txt p{
    font-size: 18px;
    font-weight: 500;
    color: #383839;
}
.negosiate-bg .sample-txt{
    position: absolute;
    top:0;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
    line-height: 25px;
}
.negosiate-bg .center-bt{
    position: absolute;
    bottom:0;
    text-align: center;
    width: 100%;
    padding-bottom: 50px;
    line-height: 25px;
}
path#path206:hover {
    fill: #00ced5;
    fill-rule: nonzero;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}
path#path204:hover {
    fill: #ff5555;
    fill-rule: nonzero;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}
path#path210:hover {
    fill: #6e95ff;
    fill-rule: nonzero;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}
/* animated */



/* Pricing */
.pricing h3{
    text-align: center;
    margin: 5% auto;
}
.pricing h4{
    line-height: 30px;
    letter-spacing: 0.6px
}
.pricing{
    text-align: justify;
    margin: 4% auto;
}
.video-card{
    box-shadow: 9px 10px 15px -7px #b6b6b6;
    width: 350px;
    max-width: 350px;

}
.video-card figure{
    position: relative;
}
.video-body {
    padding: 35px;
}
.video-body h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}
.video-body p {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
    letter-spacing: 0.8px;
    line-height: 30px;
}
.video-card figure:after{
    position: absolute;
    content: '';
    background: #000000;
    top:0;
    bottom: 0;
    left: 0;
    right:0;
    opacity: 0.5;
    background-image: url('/images/info-play.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
}
.video-card  img{
    object-fit: cover;
    width: 100%;
    height: 220px;
}
.videos-info{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15% ;
}
/* Pricing End */
.top-clan{
    padding-bottom: 30px;
}


/* 31/10 */
.pricing .nav-tabs {
    border-bottom: 1px solid #00ced5;
}
.pricing .tab-content p a{
    color: #00ced5;
}
.pricing .tab-content p{
    font-size: 16px;
    line-height: 25px;
    color: #000;
    margin: 15px;
} 
.pricing .tab-content .btn a {
  color: #fff;
}
.pricing .tab-content h5 {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    padding: 25px 0px 10px 0px;
}
.pricing .tab-content h4 {
    line-height: 20px;
    letter-spacing: 0.6px;
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 15px;
    padding-top: 20px;
}
.pricing .nav-tabs>li.active>a,
.pricing .nav-tabs>li.active>a,
.pricing .nav-tabs>li.active>a:focus,
.pricing .nav-tabs>li.active>a:hover{
    color: #fff;
    cursor: default;
    background-color: #00ced5;
    border: 1px solid #00ced5;
    border-bottom-color: #00ced5;
}
.pricing .nav-tabs>li>a{
    width: auto;
    font-size: 15px;
    text-align: center;
    margin-right: 0px;
    line-height: 1.42857143;
    border: 1px solid #00ced5;
    border-radius: 0px 0px 0 0;
    color: #5a5a5a;
}
.pricing .sta-tabs {
    padding: 10px 0;
}


/* accordiant */
.static-accordient input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

/* Accordion styles */
.static-accordient .tabs {
    border-radius: 0px;
    overflow: hidden;
    box-shadow: none;
}

.static-accordient .tab {
  width: 100%;
  color: white;
  overflow: hidden;
  /* margin-bottom: 10px; */
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.static-accordient .tab-label {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-size: 16px;
    background: -webkit-linear-gradient(45deg, #027e83, #0dd4db);
    background: linear-gradient(45deg, #027e83, #0dd4db);
    margin: 0;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
  /* Icon */
}
.static-accordient .tab-label:hover {
  background: #006f72;
}
.static-accordient .tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  -webkit-transition: all .35s;
  transition: all .35s;
  margin-left: 20px;
}
.static-accordient .tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  -webkit-transition: all .35s;
  transition: all .35s;
}
.static-accordient .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.static-accordient .tab-close:hover {
  background: #006f72;
}

.static-accordient input:checked + .tab-label {
  background: #006f72;
}
.static-accordient input:checked + .tab-label::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.static-accordient input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 2em;
}
/* accordiant end */
.before-cont h4{
    line-height: 20px;
    letter-spacing: 0.6px;
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 20px;
    text-transform: uppercase;
}
.before-cont{
    padding: 25px 0px;
    font-size: 18px;
}
.static-btn .btn{
    margin: 20px;
    padding: 15px 30px;
    font-weight: 600;
    font-size: 16px;
}
.pricing .tab-content b{
  font-size: 18px;
}
.pricing .tab-content ul{padding: 10px 55px;}
.pricing .tab-content ul li{
  font-size: 16px;
  list-style-type: decimal;
  padding: 5px 0;
}
/* css popup */
/* .new-st .makeStyles-paper-1,.new-st .makeStyles-paper-2
.new-st div[aria-labelledby="simple-modal-title"] div[role="document"]{
  top: 50%!important;
  right: 50%!important;
  position: absolute;
  transform: translate(-50%, -50%)!important;
} */
div[aria-labelledby="simple-modal-title"].new-st{
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-st div[role="document"]{
    width: auto;
    border: 1px solid #757d7e;
    /* padding: 8px 24px 8px 24px; */
    position: absolute;
    /* box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12); */
    box-shadow: none;
    background-color:transparent;
}
@media (max-width:768px) {
  .makeStyles-paper-1{
    width: 95%!important;
    margin: auto;  
  }
}

/* our team */
.team .heading-title {
    margin-bottom: 50px;
}
.team .text-center {
    text-align: center;
}
.team .heading-title h3 {
    margin-bottom: 0;
    letter-spacing: 2px;
    font-weight: normal;
}
.team .p-top-30 {
    padding-top: 30px;
}
.team .half-txt {
    width: 60%;
    margin: 0 auto;
    display: inline-block;
    line-height: 25px;
    color: #7e7e7e;
}
.team  .text-uppercase {
    text-transform: uppercase;
}

.team .team-member, .team-member .team-img {
    position: relative;
}
.team .team-member {
    overflow: hidden;
}
.team .team-member, .team-member .team-img {
    position: relative;
}

.team .team-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    border: 20px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.90);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.team .team-member:hover .team-hover .desk {
    top: 35%;
}
.team .team-member:hover .team-hover, .team-member:hover .team-hover .desk, .team-member:hover .team-hover .s-link {
    opacity: 1;
}
.team .team-hover .desk {
    position: absolute;
    top: 0%;
    width: 100%;
    opacity: 0;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    padding: 0 20px;
}
.team .desk, .desk h4, .team-hover .s-link a {
    text-align: center;
    color: #222;
}
.team .team-member:hover .team-hover .s-link {
    bottom: 10%;
}
.team .team-member:hover .team-hover, .team-member:hover .team-hover .desk, .team-member:hover .team-hover .s-link {
    opacity: 1;
}
.team .team-hover .s-link {
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;
    text-align: center;
    -webkit-transform: translateY(45%);
    transform: translateY(45%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    font-size: 35px;
}
.team .desk, .desk h4, .team-hover .s-link a {
    text-align: center;
    color: #222;
}
.team .team-member .team-img img {
    height: 250px;
    object-fit: cover;
}
.team .team-member .s-link a {
    margin: 0 10px;
    color: #333;
    font-size: 16px;
}
.team .team-title {
    position: static;
    padding: 20px 0;
    display: inline-block;
    letter-spacing: 2px;
    width: 100%;
}
.team {
    margin: 10% auto;
}
.team .team-title h5 {
    margin-bottom: 0px;
    display: block;
    text-transform: uppercase;
}
.team-title span {
    font-size: 12px;
    text-transform: uppercase;
    color: #a5a5a5;
    letter-spacing: 1px;
}
/*end  our team */
.vid-eo:hover{
    text-decoration: none;
    color: white;
}
.vid-eo{
    font-size: 15px;
    background: #faad39;
    padding: 10px;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    text-decoration: none;
    border: none;
}
.about-content p{
    font-size: 16px;
}
.btn-secondary-red{
    background: #ffffff;
    color: red;
    font-size: 16px;
    font-weight: 400;
    border-radius: 9px;
    padding: 10px 50px;
    border-color: red;
    text-transform: capitalize;
}
body {
    font-family: 'Karla', sans-serif !important;
    font-family: 'Lato', sans-serif !important;
    overflow-x: hidden !important;
    -webkit-transition: all linear 1s;
    transition: all linear 1s;
}
.txt-orange{
    color: #faad39!important;

}
.karla {
    font-family: 'Karla', sans-serif !important;
}

.form-control:focus {
    border-color: #00ced5;
}

.btn.focus,
.btn:focus,
.btn:hover {
    outline: 0;
}

.primary {
    background: #00CED5 !important;
}

.secondary {
    background: #7AECB0 !important;
}

.gray {
    background: #242A31 !important;
}

.text-light {
    color: #00CED5 !important;
}

.text-dark {
    color: #242A31 !important;
}
.fs-18{
    font-size: 18px;
}

.text-white {
    color: #ffffff !important;
}

.text-green {
    color: #0EC882 !important;
}

.text-blue {
    color: #2660A8 !important;
}

.text-error {
    color: #D85858 !important;
}
.p-0{padding:0px;}

.text-black {
    color: #000000 !important;
}

.text-input {
    color: #9E9E9E !important;
}

.box-shadow {
    box-shadow: 0px 0px 10px 2px #00000014;
}

.text-gray {
    color: #848888 !important;
}

.font-12 {
    font-size: 12px !important;
}

.m-auto {
    margin: auto;
}

.font-15 {
    font-size: 15px !important;
}

.font-20 {
    font-size: 20px !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.pad-10 {
    padding: 10px !important;
}

.pad-0 {
    padding: 0 !important;
}

.pad-lt-30 {
    padding-left: 30px
}

.mar-0 {
    margin: 0 !important;
}

.mar-top-0 {
    margin-top: 0
}

.w-100{
    width: 100%!important;
}

.width-100 {
    width: 100px !important;
}

.width-15 {
    width: 15% !important;
}

.width-200 {
    width: 200px !important;
}

.width-300 {
    width: 300px !important;
}

.width-400 {
    width: 400px !important;
}

.width-500 {
    width: 500px !important;
}

.custom-container {
    width: 95%;
}

.flex-wrap {
    flex-wrap: wrap;
}

.line-height-30 {
    line-height: 30px;
}

.main-mar-pro {
    margin: 20px 0px;
    width: 220px;
    max-width: 220px;
}

    .btn-primary {
    background:#faad39;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    padding: 9px 33px;
    border-color: #faad39;
    text-transform: uppercase;
    -webkit-transition: all .3s;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    }
    /* button.btn-secondary:after,
    button.btn-primary:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #faad39;
    border-radius: 1rem;
    z-index: -2;
    } */
    /* button.btn-secondary:before,
    button.btn-primary:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ea8d00;
    transition: all .3s;
    border-radius: 1rem;
    z-index: -1;
    } */
    /* button.btn-secondary:hover:before,
    button.btn-primary:hover:before {
    width: 100%;
    } */

.btn-white {
    background: white !important;
    color: #00CED5 !important;
    font-size: 14px !important;
    padding: 8px 16px !important;
    margin: 8px !important;
}
/* 
.btn-primary:hover,.btn-primary.active,.btn-primary:focus {
    background: #faad39;
    color: #fff;
    border-color: #faad39;
    box-shadow: 0 0 0 3px #faad3933;
    transition: all 0.4s;
} */
.btn-primary:hover,.btn-primary.active,.btn-primary:focus {
    background: #00ced5;
    color: #fff;
    border-color: #00ced5;
    box-shadow: 0 0 0 3px #00ced530;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

.btn-secondary {
    background: #faad39;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 9px;
    padding: 10px 50px;
    border-color: #faad39;
    text-transform: capitalize;
    -webkit-transition: all .3s;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.btn-blury {
    background: rgb(255, 255, 255);
    color: #063B7B;
    font-size: 16px;
    font-weight: 400;
    border-radius: 9px;
    padding: 10px 30px;
    border-color: #063B7B;
    text-transform: capitalize;
}

.btn-blury:hover {
    color: #063B7B;
    box-shadow: 0px 5px 10px 0px #063b7b3d;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

.btn-green {
    background: #37CC94;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px 20px;
    border-color: #37CC94;
    text-transform: capitalize;
}
.btn-orange {
    background:  #faad39;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px 20px;
    border-color: #faad39;
    text-transform: capitalize;
}
.btn-grey {
    background: #d3d3d3;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px 20px;
    border-color: #d3d3d3;
    text-transform: capitalize;
}

.btn-green:hover {
    color: rgb(255, 255, 255);
}

.btn-secondary:hover,.btn-secondary:focus {
    color: #fff;
    box-shadow:-1px 8px 10px 0px rgba(0, 0, 0, 0.02);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

.select-two {
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    height: 40px;
}
.flex-column{
    flex-direction: column;
}
.flex {
    display: flex;
    display: -moz-flex;
}
.justify-center {
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
}
.justify-end {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
}
.justify-start {
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
}
.justify-around {
    justify-content: space-around;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
}

.justify-between {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
}

.align-center {
    align-items: center;
    -webkit-align-items: center;
    -moz-align-items: center;
}

.pad-20 {
    padding: 20px !important;
}

.pad-15 {
    padding: 15px !important;
}

.pad-30 {
    padding: 30px !important;
}

.align-end {
    align-items: flex-end !important;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
}

.pad-55 {
    padding: 55px !important;
}

.pad-btm-7em {
    padding-bottom: 7em !important;
}

.pad-btm-20 {
    padding-bottom: 20px !important;
}

.pad-top-20 {
    padding-top: 20px !important;
}
.pad-btm-15 {
    padding-bottom: 20px !important;
}

.pad-top-15 {
    padding-top: 20px !important;
}

.pad-lr-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.pad-lr-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}
.pad-lr-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.pad-tb-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.mar-tb-7 {
    margin-top: 0px !important;
    margin-bottom: 22px !important;
}

.pad-top-30 {
    padding-top: 30px;
}

.mar-top-30 {
    padding-top: 30px;
}

.mar-top-20 {
    padding-top: 20px;
}

.pad-btm-30 {
    padding-bottom: 30px;
}

.border {
    border: 1px solid #01152D;
}

.border-none {
    border: none;
}
header .navbar-fixed-top{
    z-index: 99999;
}

.mar-btn-30 {
    margin-bottom: 30px !important;
}


/* css */
.snow-pad .snow-header a.navbar-brand {
    font-family: 'Karla', sans-serif;
    font-size: 32px;
    font-weight: 400;
    color: #fff;
}

.navbar-default .navbar-nav.header-right > li > a {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
}

.navbar-default .navbar-nav.header-right > li.divider {
    background: white;
    height: 13px;
    padding: 0 0.6px;
    margin-top: 19px;
}

.show-fotter .foot-bg {
    background-image: url("/images/foot-img-1.png");
    background-repeat: no-repeat;
}

.show-fotter .foot-bg:after {
    content: '';
    position: absolute;
    background: #E1E2E4;
    bottom: 0;

}

.foot-link ul {
    padding: 0;
    list-style-type: none;
    color: #fff;
    margin-bottom: 0;
}

.foot-link ul li.main-li {
    font-weight: 600;
    padding-bottom: 10px;
    margin-bottom: 0;
    color: #ffffff;

}

.News-letter h4 {
    font-size: 22px;
    font-weight: 500;
}

.News-letter p {
    color: #fff;
    font-weight: 400;
    font-size: 44px;
    margin: 40px 0;
}

.footer-above .navbar-form input {
    border: 1px solid #E1E2E4;
    border-radius: 4px;
    padding: 20px 18px;
}

.footer-above .navbar-form input.form-control:focus {
    border-color: #00ced5;
}

.foot-link address {
    margin-bottom: 0;
}

.foot-link li {
    padding: 3px 0;
    color: #919191;
    cursor: pointer;
}

.foot-link li:hover {
    /* color: #fff; */
    color: #c4c4c4;
    cursor: auto;
    cursor: initial;
}




.elty {
    box-shadow: 0px 3px 30px 0px #6161611f;
    width: 480px;
    margin: auto;
    border-bottom: 5px solid #00ced5;
    padding-bottom: 20px !important;
    position: relative;
}

.elty label {
    color: #242A31;
    font-size: 15px;
    font-family: 'Karla', sans-serif !important;
    font-weight: 400;
}

.elty input.form-control:focus {
    border-color: #00ced5;
}

.elty input {
    height: 44px;
    font-family: 'Karla', sans-serif !important;
}

.link-redir p {
    font-family: 'Karla', sans-serif !important;
}

input.check-height {
    height: 15px;
}

.alpha-form {
    padding-top: 100px;
}

.top-hd {
    position: absolute;
    top: -24px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 4px;
    width: 65%;
    color: #fff;
    border-radius: 4px;
    background: #00ced5;
    margin: auto;
}

.top-hd h4 {
    font-size: 20px;
    font-family: 'Lato', sans-serif !important;
    position: relative;
}

.top-hd h4:before {
    /* content: '';
    position: absolute;
    left: 130px;
    bottom: -4px;
    width: 25px;
    height: 2px;
    background: #fff; */
}

.center-part {
    margin: 69px auto;
    margin-bottom: 0;
}

.checkbox .mar-btm-30 {
    margin-bottom: 30px;
}

/* Custom checkbox */
.show-check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    float: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.show-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.show-check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    background: #e7e7e7;
    border-color: #CECECE;
    width: 18px;
}

/* On mouse-over, add a grey background color */
.show-check:hover input ~ .checkmark {
    background-color: #E5E1E1;
}

/* When the checkbox is checked, add a blue background */
.show-check input:checked ~ .checkmark {
    background-color: #E5E1E1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.show-check .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.show-check input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.show-check .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #00ced5;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* end */
.foot-link.ft-after,
.foot-link.ft-before {
    position: relative;
}

.foot-link.ft-before:before {
    content: '';
    position: absolute;
    height: 65px;
    left: -35px;
    top: 40px;
    width: 2px;
    background: #919191;
}

.foot-link.ft-after:before {
    content: '';
    position: absolute;
    height: 65px;
    right: -35px;
    top: 40px;
    width: 2px;
    background: #919191;
}

.elty select {
    height: 44px;
    font-family: 'Karla', sans-serif !important;
}

.elty select.form-control:focus {
    border-color: #00ced5;
}

.top-hd.reg-hd h4:before {
    left: 61px;
    bottom: -4px;
    width: 31px;
}

.forgot-form {
    padding: 100px 0;
    margin: auto;
}

.top-hd.for-hd h4:before {
    left: 79px;
    width: 30px;
}

/* search Page */
.center-part .sch-banner {
    background-image: url("/images/findpropersec.jpg");
    height: 60vh;
    background-repeat: no-repeat;
    background-position: 100% 30%;
    background-size: cover;
    position: relative;
}
.center-part .find-banner{
    background-image: url("/images/home-banner.png");
    height: 60vh;
    background-repeat: no-repeat;
    background-position: 100% 60%;
    background-size: cover;
    position: relative;
}

.center-part .sch-banner .sch-content {
    background-color: #000000;
    opacity: 0.2;
    filter: alpha(opacity=60);
    height: 60vh;
}

.find-card h3 {
    font-size: 26px;
    font-weight: 600;
    color: #07192E;
    margin: 35px auto;
    margin-top: 0;
}

.find-card h3 span {
    position: relative;
}

.find-card .inner-card {
    padding: 30px 100px;
    background: white;
    border-radius: 17px;
    margin: 0 75px;
    box-shadow: 0px 3px 16px 0px #0000001a;
}

.find-card {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 70%;
}

.find-card h3 span:before {
    content: '';
    position: absolute;
    background: #00CED5;
    width: 7px;
    height: 20px;
    left: -15px;
    top: 6px;
    border-radius: 20px;
}

/* Filter */
.filter-body .accordion {
    max-width: 30em;
}

.filter-body .toggle {
    display: none;
}

.filter-body .option {
    position: relative;
}

.filter-body .title,
.content {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.filter-body .title.active {
    color: #00CED5;
}

.filter-body .title {
    padding: 1em;
    display: block;
    color: #17365D;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
}

.filter-body .title:after,
.filter-body .title:before {
    content: '';
    position: absolute;
    right: 1.25em;
    top: 1.25em;
    width: 2px;
    height: 14px;
    background-color: #17365D;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.filter-body .title:after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.filter-body .title.active:after {
    background: #00CED5
}

.filter-body .content {
    max-height: 0;
    overflow: hidden;
    background-color: #fff;
}

.filter-body .content p {
    margin: 0;
    padding: 0.5em 1em 1em;
    font-size: 0.9em;
    line-height: 1.5;
}

/* .toggle:checked + .title, .toggle:checked + .title + .content {
    box-shadow: 3px 3px 6px #ddd, -3px 3px 6px #ddd;
  } */
.filter-body .toggle:checked + .title + .content {
    max-height: 500px;
}

.filter-body .toggle:checked + .title:before {
    -webkit-transform: rotate(90deg) !important;
    transform: rotate(90deg) !important;

}

.filter-body h3 span {
    position: relative;
    padding: 6px 28px;
}

.filter-body h3 span:before {
    content: '';
    position: absolute;
    background: #00CED5;
    width: 7px;
    height: 20px;
    left: 14px;
    top: 10px;
    border-radius: 20px;
}

section.filter-body {
    margin: auto;
    margin-top: 16em;
    margin-bottom: 5em;
}

/* End Filter */
.content .show-check span.checkmark {
    width: 21px;
    height: 21px;
    border: 1px solid #BFBFBF;
    background: #EBEBEB;
}

.content .show-check {
    font-size: 18px;
    color: #2660A8;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}

.category.fill .content ul {
    padding-left: 15px;
}
.content ul {
    list-style-type: none;
}

.content .show-check {
    float: unset;
}

.contact {
    margin: 15px 20px;
}

.content .show-check .checkmark:after {
    left: 8px;
    top: 4px;
}

.sort {
    padding: 10px 0px;
}

.list-pro {
    box-shadow: 0px 0px 10px 2px #00000014;
    margin-bottom: 15px;
}

.profile {
    width: 100px;
    height: 100px;
    margin: 10px auto;
    border-radius: 100px;
    position: relative;
    border: 3px solid #496587;
    background: #F0F7FF;
}

.profile span {
    display: block;
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    color: #496587;
}

.profile span:nth-child(2) {
    font-size: 14px;
}

.profile .div {
    margin: 17px auto;
}

.profile:before {
    content: '';
    /* background-image: url(/images/icons/star.svg); */
    position: absolute;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: left;
    left: -40px;
    top: -10px;

}

.contact .btn-secondary {
    padding: 8px 40px;
}

.contact:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 75%;
    background: #e3e3e359;
    right: 4px;
    top: 0;
    margin: 25px auto;
    bottom: 0;
}

.pro-data .product .tit {
    font-size: 17px;
    color: #000000;
    margin: 0;
    font-weight: 600;
    font-family: 'Karla', sans-serif !important;
}

.pro-data .product .scrore {
    font-size: 17px;
    color: #00CED5;
    margin: 0;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}

.pro-data {
    margin: 15px 15px;
}

.data-list span.text-left {
    color: #5A5B5B;
    font-size: 15px;
    font-weight: 600;
}

.data-list {
    padding: 0px 0;
}

.data-list span {
    display: block;
    color: #5a5b5b;
    font-size: 15px;
    font-weight: 600;
    line-height: 28px;
}

.data-list span b {
    color: #2E2E2E;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
}

.ul-list img {
    height: 18px;
}

.ul-list {
    padding: 0;
    list-style-type: none;
}

.ul-list li {
    float: left;
    padding: 0 15px 0 0;
}

.main-pro {
    padding: 20px 0px;
}

.filter-tit {
    margin: 0;
    padding: 10px 0;
}

.feture-list .feu-tit {
    font-size: 22px;
    font-weight: 500;
    color: rgb(0, 0, 0);
}

.feture-list .feture-card .btn-secondary {
    padding: 8px 20px;
}

.feture-card {
    padding: 20px 30px;
    width: 30%;
    text-align: center;
    border: 1px solid #D6D6D6;
    box-shadow: 0px 10px 10px 0px #00000017;
}

.span-block span {
    display: block
}

.feturecard-body.span-block span,
.feturecard-body.span-block h4 {
    text-align: left;
    margin: 0;
    line-height: 30px;
}

.feturecard-body.span-block span {
    font-size: 14px;
    font-weight: 400;
    color: #5A5B5B;
}

.feturecard-body.span-block span b {
    font-size: 14px;
    font-weight: 400;
    color: #2E2E2E;
}

.feturecard-body.span-block {
    padding: 0px 0 22px;
}

.feture-card .profile {
    width: 120px;
    height: 120px;
    margin: 20px auto;
    background: #f0f7ff;
}

.feture-card .profile .div {
    margin: 27px auto;
}

.feture-card .profile:before {
    left: 13em;
}

.feture-list .pa-d {
    padding: 0px 0 40px;
}

.top-auction {
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.grid-body .pro-hd b {
    color: #404040;
    font-weight: 600;
}

.grid-body span {
    line-height: 30px;
    color: #5A5B5B;
    font-size: 14px;
    font-weight: 500;
}

.grid-body span b {
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 500;
}

.grid-footer span:nth-child(1) {
    font-weight: 800;
    font-size: 15px;
}

.grid-footer span img {
    padding: 0 10px 0 0;
    height: 18px;
}

.fetu-width .buyer-grid {
    width: 30%;
    margin-right: 37px;
}

.img-widh .buyer-grid img {
    width: 100%;
    height: 221px;
}

.select-two.sort-select {
    border: .5px solid #00000061;
}

.img-before {
    position: relative;
}

.img-before img {
    width: 300px;
    height: 230px;
    object-fit: cover;
}

.img-before .auction-id {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
}

/* Buyer Dashboard */
section.filter-body.Buyer-dash {
    margin-top: 4em;
}

.nav-dash.box-shadow {
    padding: 11px 45px;
    margin-top: 79px;
}

.nav-dash .filter-tit {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Karla', sans-serif !important;
}

.filter-body .nav-dash h3 span:before {
    width: 5px;
    height: 22px;
    left: 20px;
    top: 10px;
}

.filter-body .nav-dash figure.img-pads .edit {
    position: absolute;
    background-image: url(/images/icons/edit-1.svg);
    background-repeat: no-repeat;
    background-position: center;
    right: 22px;
    bottom: 0px;
    border-radius: 50px;
    padding: 0;
    width: 60px;
    height: 60px;
    background-size: auto;
    cursor: pointer;
}

.filter-body .nav-dash figure.img-pads {
    position: relative;
}

.filter-body .nav-dash img {
    margin: 0px auto;
    border-radius: 100px;
    object-fit: cover;
    padding: 20px;
}

.filter-body .nav-dash .ul-list li.active,
.filter-body .nav-dash .ul-list li:hover {
    color: #00CED5;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.filter-body .nav-dash .ul-list li.active:before,
.filter-body .nav-dash .ul-list li:hover:before {
    content: '';
    position: absolute;
    background: #00CED5;
    border-radius: 100px;
    width: 7px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    height: 7px;
    left: -5px;
    top: 17px;
}

.filter-body .nav-dash .ul-list li {
    position: relative;
    float: unset;
    cursor: pointer;
    padding: 8px;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Karla', sans-serif !important;
}
.text-uppercase{
    text-transform: uppercase;
}
.nav-profile img.img-responsive {
    width: 40px;
    height: 40px;
}

.pad-8 {
    padding: 8px !important;
}

.content-main .tab-connect {
    display: flex;
    justify-content: space-between;
}

.content-main .tab-connect .nav-tabs li {
    padding: 0 5px;
}

.content-main .tab-connect .nav-tabs li:nth-child(1) {
    padding-right: 40px;
    padding-left: 0;
}

.content-main .tab-connect .nav-tabs li:nth-child(3) {
    padding-left: 40px;
    padding-right: 0;
}

.content-main .tab-connect .nav-tabs li:nth-child(4) {
    padding-left: 40px;
    padding-right: 0;
}

.content-main .tab-connect .nav-tabs {
    border-bottom: 1px solid #000;
    padding: 10px 0;
    /* width: 35%; */
}

.content-main .tab-connect .nav-tabs li a {
    color: rgb(0, 0, 0);
    font-size: 16px;
    cursor: default;
    padding: 0px 0px 0px 0px;
    background-color: #fff;
    border: 1px solid #ddd0;
    border-bottom-color: transparent;
    font-weight: 600;
    margin: 0;
}

.content-main .tab-connect .nav-tabs li.active a {
    color: #00CED5;
    font-size: 16px;
    cursor: default;
}

.bids-data img {
    height: 265px;
    object-fit: cover;
    width: 100%;
}

.award-img .btn-secondary {
    padding: 10px 30px;
}

.award-img img.img-responsive {
    height: 65px;
    width: 100%;
    object-fit: contain;
    margin: 15px 0px;
}

.bids-details span {
    line-height: 30px;
    color: #000;
    font-size: 15px;
}

.bids-data {
    border: 1px solid #C9C3C3;
    border-right: 2px solid #00ced5;
    overflow: hidden;
}

.bids-details {
    padding: 10px 0px;
    line-height: 70px;
}

.bids-tit span {
    letter-spacing: 1pt;
}

.pad-left-0 {
    padding-left: 0;
}

.flex-height {
    height: 104px;
}

.border-radius-0 {
    border-radius: 0;
}

.flex-hgt {
    height: 150px;
}

.bids-data {
    margin: 30px auto;
}

.site-input::-webkit-input-placeholder {
    color: #565656;
}

.site-input::-moz-placeholder {
    color: #565656;
}

.site-input:-ms-input-placeholder {
    color: #565656;
}

.site-input::-ms-input-placeholder {
    color: #565656;
}

.site-input::placeholder {
    color: #565656;
}
.site-input {
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    height: 48px;
    font-size: 16px;
    font-family: 'Karla', sans-serif !important;
}

.site-textarea {
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    resize: none;
    font-family: 'Karla', sans-serif !important;
}

.site-form label {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}

.prc-rang .form-group {
    width: 100%;
    box-shadow: 0px 3px 10px 0px #0000001f;
}

.prc-rang .site-input {
    border-right: 0;
    box-shadow: none;
}

.prc-rang .form-group label {
    padding: 7px 20px;
    font-size: 24px;
    font-weight: 800;
    color: #BCB9B9;
}

.prc-rang .form-group.sha-none {
    box-shadow: none;
}

.prc-rang .input-group-addon:last-child {
    background: white;
    box-shadow: none;
    border: 1px solid #E2E2E2;
    border-radius: 2px;
    font-size: 20px;
    color: #BCB9B9;
    font-weight: 800;
    border-left: 0;
}

/* Prodct View Page */
.slider .owl-carousel .owl-item img {
    height: 350px;
    min-height: 350px;
    object-fit: cover;
}

.slider-two .owl-carousel .owl-item img {
    object-fit: cover;
    min-height: 70px;
    height: 70px;
}

.slider-two .owl-carousel .owl-item {
    width: 125px !important;
    margin-right: 0px !important;
    margin: 0px 10px !important;
}

section.carosal-product {
    border-radius: 3px;
    width: calc(100% - 20px);
    display: inline-block;
    height: auto;
}

section.carosal-product .all {
    width: 100%;
    height: auto;
    display: inline-block;
}

section.carosal-product .all .slider {
    width: auto;
    height: auto;
    overflow: hidden;
    border-radius: 3px;
    position: relative;
}

section.carosal-product .all .slider .owl-nav,
section.carosal-product .all .slider .owl-dots {
    display: none;
}

section.carosal-product .all .slider:hover .left {
    visibility: visible;
    opacity: 1;
    left: 20px;
}

section.carosal-product .all .slider:hover .right {
    visibility: visible;
    opacity: 1;
    right: 20px;
}

section.carosal-product .all .slider .item-box {
    /* padding: 20px; */
    overflow: hidden;
    width: 100%;
    height: 350px;
    position: relative;
    border-radius: 0px;
    /* background: no-repeat url(http://www.cyw51.com/file/upload/201302/22/18-49-19-74-127.jpg) center center; */
    background-size: cover;
}

section.carosal-product .all .slider .item-box:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    content: "";
    display: block;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000); */
}

/* section.carosal-product .all .slider .left {
    position: absolute;
    z-index: 1;
    top: 167px;
    left: -20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: left ease .25s;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    background: url(http://svgshare.com/i/2V9.svg) top left;
  }
  section.carosal-product .all .slider .left:hover {
    visibility: visible;
    opacity: 1;
    left: 20px;
    background: url(http://svgshare.com/i/2UW.svg) top left;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  } */
section.carosal-product .all .slider .left.nonl {
    left: -20px;
    opacity: 0;
    visibility: hidden;
}

/* section.carosal-product .all .slider .right {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 167px;
    right: -20px;
    width: 40px;
    height: 40px;
    visibility: hidden;
    opacity: 0;
    transition: right ease .25s;
    background: url(http://svgshare.com/i/2V9.svg) top left;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  section.carosal-product .all .slider .right:hover {
    visibility: visible;
    opacity: 1;
    right: 20px;
    background: url(http://svgshare.com/i/2UW.svg) top left;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  } */
section.carosal-product .all .slider .right.nonr {
    right: -20px;
    opacity: 0;
    visibility: hidden;
}

section.carosal-product .all .slider-two {
    width: auto;
    height: auto;
    overflow: hidden;
    position: relative;
}

section.carosal-product .all .slider-two .owl-nav,
section.carosal-product .all .slider-two .owl-dots {
    display: none;
}

section.carosal-product .all .slider-two .left-t {
    visibility: visible;
    opacity: 1;
    left: 3px;
}

section.carosal-product .all .slider-two .right-t {
    visibility: visible;
    opacity: 1;
    right: 3px;
}

section.carosal-product .all .slider-two .item {
    -webkit-transition: all ease .25s;
    transition: all ease .25s;
    height: 70px;
    border-radius: 0px;
    /* border: solid 3px #e6ecf2; */
    cursor: pointer;
    overflow: hidden;
    /* background: no-repeat url(http://www.cyw51.com/file/upload/201302/22/18-49-19-74-127.jpg) center center; */
    background-size: cover;
}

section.carosal-product .all .slider-two .item.active {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    /* border: solid 3px #00ced5 */
}

section.carosal-product .all .slider-two .owl-stage {
    padding: 20px 25px;
    width: 636px !important;
    overflow: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    display: flex;
    margin: auto
}

section.carosal-product .all .slider-two .left-t {
    /* -webkit-transform: rotate(180deg);
            transform: rotate(180deg); */
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 45px;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all ease .25s;
    transition: all ease .25s;
    width: 20px;
    border-radius: 50px;
    height: 20px;
    background: no-repeat #00ced5 url(/images/icons/left-arrow.svg) center center;
    left: 8px;
}

section.carosal-product .all .slider-two .left-t.nonl-t {
    left: 5px;
    opacity: 1;
    visibility: unset;
}

section.carosal-product .all .slider-two .left-t:hover {
    visibility: visible;
    opacity: 1;
    left: 3px;
}

section.carosal-product .all .slider-two .right-t {
    background: no-repeat rgba(204, 46, 56, 0.85) url(/images/icons/right-arrow.svg) center center;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 45px;
    right: 5px;
    visibility: unset;
    opacity: 1;
    -webkit-transition: all ease .25s;
    transition: all ease .25s;
    width: 20px;
    border-radius: 50px;
    height: 20px;
    background-color: #00ced5;
}
section.carosal-product .all .slider-two .right-t.nonr-t {
    right: 5px;
    opacity: 2;
    visibility: visible;;
}

section.carosal-product .all .slider-two .right-t:hover {
    visibility: visible;
    opacity: 1;
    right: 3px;
}



/* Prodct View Page */
.pro-pagi h5 {
    font-size: 14px;
    color: #C5C5C5;
    font-weight: 400;
}

.pro-pagi h5 b {
    font-size: 14px;
    color: #878585;
    font-weight: 800;
}

.product-hd h4 {
    font-size: 21px;
}

.product-hd .btn-secondary {
    padding: 8px 22px
}

.product-hd {
    border-bottom: 1px solid #E8E8E8;
    padding: 0 0 5px 0px;
}

.proper-data span b {
    color: #000;
    font-weight: 500;
}

.proper-data span {
    line-height: 35px;
    font-family: 'Karla', sans-serif !important;
    font-size: 18px;
    font-weight: 500;
    color: #757575;
}

.fancy-btn button {
    position: absolute;

}

.fancy-btn {
    padding: 6px 0px 35px 0;
    border: 1px solid #faad39;
    width: 80%;
    background: #fffdfb;
    border-radius: 4px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
}

.fancy-btn button {
    position: absolute;
    left: auto;
    right: auto;
    bottom: -17px;
    text-align: center;
}

.twin-bath span {
    padding: 0 22px 0px 0;
    position: relative;
}

.twin-bath span:nth-child(1):after {
    border-right: 1px solid #DFDFDF;
    content: '';
    position: absolute;
    width: 1px;
    top: 10px;
    right: 7px;
    height: 14px;
    background: #000000;
}

.proper-data.span-block {
    padding: 20px 0;
}

.product-timer img.img-responsive {
    padding: 0 10px;
}

.product-timer.primary.pad-10.text-center {
    margin: 0 0 20px 0px;
}

.range-slider span.range {
    font-size: 15px;
    color: #9E9E9E !important;
    padding: 0px 17px;
    font-weight: 500;
    width: 255px;
}

.range-slider {
    display: flex;
    justify-content: space-between
}

.buyer-img img {
    height: 235px;
    max-height: 235px;
}

.pro-card-hgt.buyer-img {
    height: 233px;
    max-height: 235px;
}

/* Seller Dashboard */

.pro-card-hgt {
    height: 265px;
    overflow: hidden;
}

/* Seller Dashboard */
/* Priec range */
.price-range #slider {
    width: 65%;
    background: #00CED5;
    height: 4px;
    border-radius: 0;
    border: none;
    margin: 9px auto;
}

.price-range .value {
    position: absolute;
    top: 20px;
    left: 50%;
    margin: 0 0 0 -20px;
    width: 40px;
    text-align: center;
    display: block;
    font-weight: normal;
    font-family: Verdana, Arial, sans-serif;
    font-size: 14px;
    color: #333;
}

.price-range .price-range-both.value {
    width: 100px;
    margin: 0 0 0 -50px;
    top: 26px;
}

.price-range .price-range-both {
    display: none;
}

.price-range .value i {
    font-style: normal;
}

.price-range body div.ui-slider-range.ui-widget-header {
    background: #00ced5;
}

.price-range .ui-state-hover,
.price-range .ui-widget-content .ui-state-hover,
.price-range .ui-widget-header .ui-state-hover,
.price-range .ui-state-focus,
.price-range .ui-widget-content .ui-state-focus,
.price-range .ui-widget-header .ui-state-focus {
    background: #2ecaf9 !important;
}

.price-range .ui-state-default,
.price-range .ui-widget-content .ui-state-default,
.price-range .ui-widget-header .ui-state-default {
    background: #5892D8 !important;
    border-radius: 100%;
    border: none !important;
    padding: 4px;
    width: 15px;
    height: 15px;
}

.price-range .ui-widget-header {
    background: #00CED5 !important;
}

.price-range .range-slider .ui-slider-horizontal .ui-slider-handle {
    top: -0.4em;
    margin-left: -.6em;
}

.price-range span.ui-slider-handle.ui-corner-all.ui-state-default:focus {
    outline: none;
}

.price-range .range-slider span {
    color: #faad39;
}

/* Priec range  End */

/* Message */
.message-head {
    border: 1px solid #00CED5;
}

.message-head input#srch-term {
    background: #f7f7f7;
    border: none;
    box-shadow: none;
    padding: 20px;
    border-radius: 3px;
}

.message-head .btn-msg {
    color: #ABABAB;
    background-color: #f7f7f7;
    border: none;
    font-size: 14px;
    padding: 10px;
}

.message-head .navbar-form .input-group {
    width: 100%;
}

.message-head .search {
    position: relative;
}

.message-head .search:after {
    content: '';
    position: absolute;
    background: #00ced5;
    width: 1px;
    height: 39px;
    top: 0;
    right: 0;
}

.message-head form.navbar-form {
    padding: 0 20px;
}

.message-head .navbar-form .input-group .input-group-btn {
    width: 35px;
}

.mesage-body .user-chat {
    border-left: 2px solid #00CED5;
    padding: 8px 0;
    /* margin: 35px 23px; */
    margin: 16px 23px;
    box-shadow: 0px 3px 10px 0px #0000001f;
}

.mesage-body .user-chat.user-active {
    border: 2px solid #00CED5;
}

.mesage-body .user-chat img {
    width: 45px;
    height: 45px;
    margin: 0 15px;
    border-radius: 100px;
    object-fit: cover;
}

.mesage-body .user-info h4 {
    font-size: 14px;
    font-family: 'Karla', sans-serif !important;
    margin: 5px auto;
}

.user-info {
    width: 72%;
}

.user-info span {
    color: #ABABAB;
    font-size: 14px;
    font-family: 'Karla', sans-serif !important;
}

.user-info span.ellps {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    float: left;
    width: 100px;
}

.mesage-body .user-chat .user-im {
    position: relative;
}

/* .mesage-body .user-chat .user-im:after {
    position: absolute;
    content: '';
    background: #0EC882;
    width: 8px;
    height: 8px;
    bottom: 2px;
    right: 18px;
    border-radius: 100px;
} */

.mesage-body .user-chat .user-im.gray-dot:after {
    background: #ABABAB;
}

.div-after {
    position: relative;
}

.div-after:after {
    content: '';
    position: absolute;
    background: #DCDCDC;
    width: 1px;
    height: 87%;
    top: 0;
    right: 0;
    margin: 35px auto;
}

.mesage-body .chating .user-chat {
    background: #F5F5F5;
    padding: 10px 0;
    box-shadow: none;
}

.mesage-body .chating .user-chat img {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    object-fit: cover;
}

.message-head input::-webkit-input-placeholder,
.chating .chat-body .nav-fixed-bottom .form-control::-webkit-input-placeholder {
    color: #000000;
}

.message-head input::-moz-placeholder,
.chating .chat-body .nav-fixed-bottom .form-control::-moz-placeholder {
    color: #000000;
}

.message-head input:-ms-input-placeholder,
.chating .chat-body .nav-fixed-bottom .form-control:-ms-input-placeholder {
    color: #000000;
}

.message-head input::-ms-input-placeholder,
.chating .chat-body .nav-fixed-bottom .form-control::-ms-input-placeholder {
    color: #000000;
}

.message-head input::placeholder,
.chating .chat-body .nav-fixed-bottom .form-control::placeholder {
    color: #000000;
}

.chating .chat-body .nav-fixed-bottom .input-group-addon {
    width: 50px;
}

.chating .chat-body .nav-fixed-bottom .form-control {
    border-left: 1px solid #E2E0E0 !important;
    border-right: 1px solid #E2E0E0 !important;
    margin: 8px auto;
    height: 20px;
}

.chating .chat-body .nav-fixed-bottom .form-control,
.chating .chat-body .nav-fixed-bottom .input-group-addon {
    background-color: #fff;
    box-shadow: none;
    border: none;
}
.pre-content p{
    background: #00ced5;
    width: auto;
    display: inline-block;
    padding: 6px 16px;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-transform: capitalize;
    cursor: pointer;
    margin-right: 6px;
    margin-bottom: 0;
    margin-top: 10px;
}
.pre-content{
    display: block;
    overflow: auto;
    white-space: nowrap;
}

.chating .chat-body .nav-fixed-bottom {
    border: 1px solid #282e36;
    width: 100%;
}

.chating .chat-body .fixed-center {
    height: 335px;
    min-height: 335px;
    max-height: 335px;
}

.chat-body .fixed-center {
    width: 100%;
}

.chat-body .fixed-center .left-chat {
    float: left;
    background: #EEFEFF;
}

.chat-body .fixed-center .right-chat {
    float: right;
    background: #F3F3F3;
}

.chat-body {
    padding: 0px 35px;
}

.chat-body .fixed-center .macro {
    margin-top: 5px;
    width: 75%;
    border-radius: 5px;
    padding: 18px;
    display: flex;
    box-shadow: 0px 2px 10px 0px #4d4d4d1f;
    margin-bottom: 25px;
    word-break: break-all;
}

.left-chat:before {
    width: 0;
    height: 0;
    content: "";
    top: -11px;
    left: -32px;
    position: relative;
    border-style: solid;
    border-width: 13px 18px 3px 0;
    border-color: #a9949400 #eefeff #32313100 #ffffff00;
}

.right-chat:after {
    width: 0;
    height: 0;
    content: "";
    top: -11px;
    left: 35px;
    position: relative;
    border-style: solid;
    border-width: 0px 16px 19px 5px;
    border-color: #ed232300 #07bfca00 #F3F3F3 #441b1b00;
}

/* Message end */
.pre-txt span:nth-child(1) {
    font-size: 24px;
    height: 45px;
}

.pre-txt {
    margin-top: 15px;
}

.product-span {
    padding: 10px 0;
    border-bottom: 1px solid #E3E3E3;
}

.product-span .pro-hd {
    font-size: 15px;
}

.awarded {
    height: 100px !important;
    width: 200px !important;
    object-fit: contain !important;
}

.site-form .form-control-feedback {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.site-form .has-feedback img {
    height: 16px;
    width: 18px;
    color: #BDB9B9;
}




/* Select dropdown icon */
.selectdiv {
    float: left;
    width: 100%;
    position: relative;
}

.selectdiv.buy-sch:after {
    top: 6px;
    font-size: 14px;
}

.selectdiv:after {
    font-family: "Font Awesome 5 Free";
    content: "\f0d7";
    color: #BCB9B9;
    right: 12px;
    line-height: 38px;
    top: 36px;
    font-size: 20px;
    display: inline-block;
    padding-right: 3px;
    padding-left: 5px;
    background: #fff;
    vertical-align: middle;
    font-weight: 900;
    position: absolute;
}

/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
    display: none;
}

.selectdiv select {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 48px;
    float: right;
    font-size: 16px;
    line-height: 1.75;
    color: #BCB9B9;
    background-image: none;
    -ms-word-break: normal;
    word-break: normal;
}

/* Select dropdown icon END */

.select-two.slect-sort {
    border-radius: 4px;
    box-shadow: none;
    color: #676363;
    height: 40px;
}

.reg-hgt img {
    height: auto;
    min-height: 1250px;
    max-height: 1250px;
}

.modal-header h3 span {
    position: relative;
}

/* .modal-header h3 span:before {
    content: '';
    position: absolute;
    background: #00CED5;
    width: 7px;
    height: 20px;
    left: -15px;
    top: 4px;
    border-radius: 20px;
} */

.show-model  {
    -webkit-transform: translate(0px, 15%);
            transform: translate(0px, 15%);
}
.show-model .modal-header {
    border: none;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
    padding: 8px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.upload-btn-wrapper img {
    height: 18px;
    width: 20px;
}

.modal-header h6 {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
}

.proper-data a {
    text-decoration: none;
    cursor: pointer;
}


/*CUSTOM-2*/
.mod-place-bid .modal-dialog {
    width: 730px;
}

.mod-place-bid .modal-dialog .show-check {
    color: #4D4E50;
    font-size: 16px;
    padding-left: 30px;
}

.mod-place-bid .modal-dialog .dispute a {
    color: #D85858;
    font-size: 16px
}

.mod-place-bid .modal-dialog .dispute p {
    font-size: 16px;
    color: #4D4E50;

}
.mod-place-bid .bid-amt .input-group-addon{
    background: #fff;
    border: 1px solid #efefef;
    font-size: 16px;
    font-weight: 600;
    border-left: none;
    color: #BCB9B9;
}
.mod-place-bid .bid-amt input::-webkit-input-placeholder{
    color: #000000;
}
.mod-place-bid .bid-amt input::-moz-placeholder{
    color: #000000;
}
.mod-place-bid .bid-amt input:-ms-input-placeholder{
    color: #000000;
}
.mod-place-bid .bid-amt input::-ms-input-placeholder{
    color: #000000;
}
.mod-place-bid .bid-amt input::placeholder{
    color: #000000;
}
.mod-place-bid .bid-amt input{
    background: #fff;
    border: 1px solid #efefef;
    font-size: 14px;
    border-right: none;
    box-shadow: none;
}
/*CUSTOM-2 END*/
/* BId his */
.bid-hiy .modal-body{
    margin: 10px 80px;
    padding: 0;
}
.bid-hiy .modal-body .table>tbody>tr:nth-child(even){
background: #f7f7f7;
}
.bid-hiy .modal-body .table{
    border-spacing: 3px;
    border-collapse: separate;
}
.bid-hiy .modal-body .table>tbody>tr>td{
    border-top: none;
    font-size: 15px;
    color: #646464;
    font-family: 'Karla', sans-serif !important;
    padding: 8px;
}
.bid-hiy .modal-body .table>thead>tr>th{
    vertical-align: bottom;
    border: 2px solid #f7f7f7;
    text-align: center;
    border-top: 2px solid #f7f7f7;
    font-size: 16px;
    padding: 6px;
    color: #000;
    font-family: 'Karla', sans-serif !important;
}
/* BId his */
.prc-rang.post-range .form-group label{
    padding: 7px 50px;
    margin: 0;
}
.grid-container {
    display: grid;
    grid-template-columns:-webkit-max-content;
    grid-template-columns:max-content;
    grid-gap: 10px;
    background-color: rgb(255, 255, 255);
  }  
  .grid-container > div {
    background-color: #ffffff;
  }
  .grid-container  img{
        width:145px;
        height: 56px;
        object-fit: cover;
  }
  .item1 {
    grid-column: 5 / span 2;
    grid-row: 1;
  }
  
  .item3 {
    grid-column: 5 / span 2;
    grid-row: 2;
  }
  
  .item2 {
    grid-column: span 4;
    grid-row: 1 / span 3;
  }
  
  .item4 {
    grid-column: 5 / span 2;
    grid-row: 3;
  }
  /* Custom upload */
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  } 
  .upload-btn-wrapper input[type=file] {
    /* font-size: 100px;
    position: absolute; */
    left: 0;
    top: 0;
    opacity: 0;
  }
  .upload-btn-wrapper .btn-primary {
    padding: 10px 12px;
}
  .upload-btn-wrapper .flaticon-upload-button:before {
    font-size: 15px;
}
  /* Custom upload  End*/
.timer-font{
    font-size: 18px;
}
.product-hd a{
    color: #063B7B;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    
}
.product-hd a svg{
    padding-right: 5px;
    color: #063B7B;
}
.modal-header p{
    font-size: 18px;
    font-weight: 400;
}
.Property-list img {
    width: 181px;
    height: 116px;
    border-radius: 4px;
}
.mod-place-bid .modal-dialog.modal-lg{
    width: 900px;
}
.property-body{
    width: 75%;
    padding: 5px 25px;
}
.property-body span{
    line-height: 32px;
}
.mod-place-bid .modal-dialog .Property-list .show-check{
    font-size: 14px;
    padding-left: 18px;
    margin-bottom: 0;
    line-height: 18px;
}
.Property-list .show-check input:checked ~ .checkmark {
    background-color: #E5E1E1;
    border: 1px solid #00ced5;
}
.Property-list  .show-check .checkmark:after{
    left: 6px;
    top: 2px
}
.Property-list .show-check .checkmark{
    border: 1px solid #00ced5;
}
.pro-chk .show-check .checkmark:after {
    left: 6px;
    top: 2px;
}
.pro-chk .show-check input:checked ~ .checkmark {
    background-color: #E5E1E1;
    border: 1px solid #BFBFBF;
    /* margin-top: 7px; */
}
.pro-chk .show-check {
    padding-left: 30px;
    line-height: 25px;
    font-size: 17px;
    font-weight: 400;
}
.pad--both-100{
    padding: 30px 100px;
}
#buyer .checkbox label,
#seller .checkbox label,
#realtor .checkbox label{
    padding-left: 30px;
}
#buyer .show-check .checkmark,
#seller .show-check .checkmark,
#realtor .show-check .checkmark{
    top: 5px;
}




/* 18/06/19 */
.bids-details .mora-split:before {
    content: '';
    position: absolute;
    background: #E6E6E6;
    width: 1px;
    height: 80px;
    left: 0;
    margin: 15px auto;
}
.pad-lft-20{
    padding-left: 20%;
}
.single-alis img {
    height: 200px;
    min-width: 325px;
    margin:  auto;
    object-fit: cover;
    /* margin-right: 10px; */
}
.multi-alis {
    overflow-y: auto;
    height: 210px;
    min-height: 210px;
}
.single-alis {
    height: 200px;
    min-height: 200px;
}
.multi-alis img:nth-child(last) {
    margin-bottom:0px;
}
.multi-alis img {
    height: 60px;
    width: 100%;
    margin-bottom:10px;
    object-fit: cover;
}
.scrollbar
{
	margin-left: 30px;
	float: left;
	height: 200px;
	width: 65px;
	background: #F5F5F5;
	overflow-y: auto;
	margin-bottom: 25px;
}
#style-roll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

#style-roll::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

#style-roll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
    background-image: 
    -webkit-gradient(linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(0, 206, 206, 1)),
    color-stop(0.72, rgb(0, 206, 206, 1)),
    color-stop(0.86, rgb(8, 194, 247)));
}
.pad-lr-15{
    padding: 0 15px;
}
.sel-provw button{
    margin: 0 15px;
}
.inspec-box{
    padding: 20px;
    width: 31%;
    margin: 20px;
    margin-top: 0;
    margin-left: 0;
    border-radius: 4px;
}
.box-head img{
    width: 60px;
    height: 60px;
    border-radius: 50px;
    object-fit: cover;
    object-position: center center;
    margin: 10px auto;
    margin-top: 0;
}
.box-head h4{
    font-size: 16px;
    font-weight: 800;
    color: #42505C;
    margin: 0;
}
.box-head span:nth-child(2){
    line-height: 35px;
    padding: 10px 10px 0px 15px;
}
.box-head p{
    font-size: 13px;
    font-weight: 600;
    color: #79828B;
    margin: 0;
}
.box-footer {
    border-top: 1px solid #E6EBF0;
    padding: 15px 0 0px 0;
    display: flex;
    justify-content: space-between;
}
.box-body {
    height: 120px;
}
.box-footer button{
    width: 115px;
    text-align: center;
    padding: 9px;
}
.report-btn button{
    padding: 9px 20px;
    margin: 0 15px;
}
.report-form .selectdiv select,.report-form textarea::-webkit-input-placeholder{
    color: #000000;
}
.report-form .selectdiv select,.report-form textarea::-moz-placeholder{
    color: #000000;
}
.report-form .selectdiv select,.report-form textarea:-ms-input-placeholder{
    color: #000000;
}
.report-form .selectdiv select,.report-form textarea::-ms-input-placeholder{
    color: #000000;
}
.report-form .selectdiv select,.report-form textarea::placeholder{
    color: #000000;
}
#report .modal-dialog {
    width: 550px;
}
.site-table thead tr th{
    padding: 10px;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 16px;
    border-bottom: none;
    box-shadow: 0px 0px 4px 2px #00000014;
    color: black;
}
.site-table tbody tr td button{
    padding: 8px 30px;
}
.site-table tbody tr:nth-child(even){
background: #F8F8F8;
}
.site-table tbody tr td:nth-child(3){
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 300px;
    display: block;
    margin: auto;
    text-align: left;
}
.site-table tbody tr td{
    padding: 10px;
    text-align: center;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 15px;
    border-top: none;
    vertical-align: middle;
}
.referal h4{
    padding: 0px 0px 60px 0px;
}
/* Radio box */

/* The container */
.check button {
    margin: 15px;}
.check .con {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 17px;
    line-height: 25px;
    margin: 15px;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .check .con input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    margin: 0px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #00ced5;
  }
  
  /* On mouse-over, add a grey background color */
  .check .con:hover input ~ .checkmark {
    background-color: #fff;
  }
  
  /* When the radio button is checked, add a blue background */
  .check .con input:checked ~ .checkmark {
    background-color: #00ced5;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .check .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .check .con input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .check .con .checkmark:after {
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: white;
    border: 2px solid #abf3ff;
  }
.cus-tom{
    width: 60%;
}
.pad--both-60{
    padding: 30px 60px;
}
.pad-both-60{
    padding: 0px 60px;
}
.pad-both-170{
    padding: 0px 170px;
}
.pad-t-30{
    padding-top: 30px;
}
.seller-model{
    padding: 15px;
}
.seller-model .modal-header,.seller-model .modal-body{
    padding: 0;
}
.pocket-search {
    padding: 4px;
    background: #00ced5;
    margin: 15px;
}
.pocket-search button:hover{
    background: #fff;
    color: #00ced5;
}
.pocket-search button{
    background: #fff;
    color: #00ced5;
    padding: 9px 50px;
    text-transform: capitalize;
}
.pocket-search .navbar-form .form-control::-webkit-input-placeholder{
color: #000000;

}
.pocket-search .navbar-form .form-control::-moz-placeholder{
color: #000000;

}
.pocket-search .navbar-form .form-control:-ms-input-placeholder{
color: #000000;

}
.pocket-search .navbar-form .form-control::-ms-input-placeholder{
color: #000000;

}
.pocket-search .navbar-form .form-control::placeholder{
color: #000000;

}
.pocket-search .navbar-form .form-control{
    width: 87%;    padding: 18px;border: none;
}
section.filter-body.poctect-body {
    margin-top: 5em;
}
.find-pocket h3 span:before{
    content: '';
    position: absolute;
    background: #00CED5;
    width: 7px;
    height: 20px;
    left: -15px;
    top: 6px;
    border-radius: 20px;
}
.find-pocket h3 span{
    position: relative;
}
.find-pocket h3{
    font-size: 26px;
    font-weight: 600;
    color: #07192E;
    margin: 35px auto;
    margin-top: 0;
}
.home-subscribe {
    background: #00ced5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
}
.home-subscribe .News-letter h4 {
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    text-transform: uppercase;
  
}
.home-subscribe  .navbar-form .form-control {
    padding: 18px;
    border: 1px solid #fff;
}
.home-subscribe  button:hover {
    background: rgb(255, 255, 255);
    color: #000000;
}
.home-subscribe  button {
background: #077B8E;
}
.home-subscribe .News-letter p {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    margin: 0;
    margin-bottom: 20px;
}



/* 27/6/2019 */
.scnd-slider img {
    object-fit: cover;
    min-height: 70px;
    height: 70px;
    width: 130px;
}
.scnd-slider {
    display: flex;
    justify-content: space-evenly;
    padding: 20px;    
}
.arrow{
    position: relative;
}
.slider-a img {
    width: 100%;
    height: 350px;
    min-height: 350px;
    object-fit: cover;
}
.af-arrow:after{
    content: '';
    position: absolute;
    right: 0;
    background: no-repeat #00ced5 url(/images/icons/right-arrow.svg) center center;
    padding: 10px;
    z-index: 1;
    top: 45px;
    border-radius: 50px;
    cursor: pointer;
}
.bf-arrow:before{
    content: '';
    position: absolute;
    left:0;
    border-radius: 50px;
    background:no-repeat #00ced5 url(/images/icons/left-arrow.svg) center center;
    padding: 10px;
    z-index: 1;
    top: 45px;
    cursor: pointer;
}
/* 1/7/2019 */
.selectdiv select{
    color: #565656;
}

.drop .selectBox {
    position: relative;
  }
  
  .drop  .selectBox select {
    width: 100%;
    font-weight: bold;   
     padding: 0px 10px;  
       border: none;   
        color: #9E9E9E;
        font-weight: 400;
  }
  
  .drop .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  .drop  #checkboxes {
    display: none;
    border: 1px #f2f2f2  solid;
    position: absolute;
    z-index: 99;
    background: #e9feff;
    width: 94%;
    overflow: hidden;
  }
  
  .drop #checkboxes label input{
    margin: 0px 10px;
  }
  .drop #checkboxes label {
    display: block!important;
    padding: 3px;
  }
  
  .drop #checkboxes label:hover {
    background-color: #e0e0e0;
  }
  /* 02/07/2019 */
  .profile.dash:before{
    display: none;
  }
  .profile.dash {
    width: 130px;
    height: 130px;
  }
  .profile.dash .div {
    margin: 25px auto;
}
.profile.dash span {
    font-size: 36px;
}
.profile.dash span:nth-child(2) {
    font-size: 16px;
}
.profile.dash:after{
    content: '';
    position: absolute;
    background-image: url(/images/icons/pre-app.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 126px;
    height: 30px;
    right: 0px;
    bottom: -12px;
}
.alxou-line{
    line-height: 10px;
}
.bids-details .alxou.mora-split:before{
    height: 52px;
}
.profile.head {
    width: 40px;
    height: 40px;
    margin: 0px auto;
    border: 2px solid #496587;
}
.profile.head:before{
    display: none;
}
.profile.head .div {
    margin: 10px auto;
    line-height: 1;
}
.profile.head span {
font-size: 16px;
}
.main-sav-pro img {
width: 100%;
}
.main-sav-pro {
    margin: 20px 0px;
    width: 45%;
}
.img-before .auction-id h5{
    position: relative;font-size: 12px;    text-align: right;
}
.img-before .auction-id h5.star:after{
    content: '';
    position: absolute;
    background-image: url(/images/star.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 25px;
    height: 25px;
    right: 5px;
    bottom: -4px;
}
.mesage-body .user-chat .profile.head.user-im{
    margin: 0px 10px;
}
.profile.head.curnt-user{
    width: 50px;
    height: 50px;
}
.profile.head.curnt-user .div {
    margin: 13px auto;
}
.profile.head.curnt-user span {
    font-size: 20px;
}
.add-card .fa{
    border: 1px solid #00ced5;
    border-radius: 100px;
    padding: 2px;
    font-size: 11px;
}
.add-card{
    text-align: right;
    padding: 28px 0;
    color: #00ced5;
    cursor: pointer;
}
.profile.dash.sel:after{
    display: none;
}
.profile.save:before{
    background-image: url(/images/star.svg)
}
.show-check.PAYMT-CHK{
    padding-left: 26px;
    margin-bottom: 0px;
    line-height: 18px;
}
.bor-none{
    border: none;
}
.of-prc{
    padding-top:15px;
    color: #FF5837;
    font-size: 18px;
    font-weight: 400;
}
.seller-model{
    width: 85%;
    margin: auto
}
/* 03/07/2019 */
svg#Capa_1 {
    margin: -5px 5px;
    cursor: pointer;
}
.red-tooltip + .tooltip > .tooltip-inner {background-color: rgb(109, 109, 109);padding:10px;text-align:justify; color:rgb(255, 255, 255);width:200px;
height: auto;}
.androd h4{
    padding: 10px 30px;
    line-height: 22px;
    color: #4D4E50;
    font-size: 16px;
}
.filter-body .nav-dash .ul-list li a{
    color: #000;
    text-decoration: none;
}
.filter-body .nav-dash .ul-list li.active a, .filter-body .nav-dash .ul-list li:hover a{
    color: #00CED5;-webkit-transition: all .2s ease-in-out;transition: all .2s ease-in-out;display: block;
}

.txt-center {
  text-align: center;
}

.popup{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow-x: hidden;
  background: #00000057;
  overflow-y: auto;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: -webkit-box;
}

.txt-right {
  text-align: right
}

.txt-left {
  text-align: left
}

/*BANNER*/
.sp_banner {
  background-image: url('/images/Interracial-Couple.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 670px;
  position: relative;
  background-position: 100% -20%;
  -webkit-filter: brightness(1.30);   
   filter: brightness(1.30);
}
.sp_banner:after{
    position: absolute;
    content: '';
    background: #00000070;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
    z-index: 9;
    opacity: 0.5;
    width: 100%;
    height: 100%;
}

.sp_banner .banner-box {
    position: absolute;
    -webkit-transform: translate(40%, 5%);
            transform: translate(40%, 5%);
    right: 78%;
    z-index: 999;
    border-radius: 6px;
    padding: 25px;
    width: 65%;
}

.sp_banner .main-h h1{
    font-size: 45px;
    font-weight: 700;
    text-align: left;
    line-height: 60px;
    color: hsl(206, 100%, 19%);
}
.sp_banner .main-h h3{
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    line-height: 35px;
    color: #000;
}
.sp_banner .main-h{
    color: #ffffff;  
    margin-top: 10%;    
    margin-left: 100px;
    position: relative;
    z-index: 999;
}
.mian-cont{
    text-align: center;
}
.snd-bg{
    background: #fff!important;
}
.hiw.snd-bg .hiw-tit h2{
    font-size: 36px;
    padding-bottom: 8px;
    margin-top: 50px;
}
.hiw.snd-bg .hiw-tit{
    padding-top: 0px;
}
.hiw.snd-bg .hiw-tit h4{
    font-size: 24px;
    font-weight: 500;
}
.sp_banner .main-p {
    font-size: 24px;
    line-height: 30px;
    color: #000;
    margin: 15px 30px;
    font-weight: 500;
    text-align: left;    
}
.sp_banner .main-span a {
    background: #faad39;
    padding: 8px 14px;
    border-radius: 6px;
}
.sp_banner .learn {
    font-size: 18px;
    color: hsl(182, 89%, 34%);
    font-weight: 700;
    text-align: left;
    margin: 25px auto;  
    margin-left: 35px;  
}
.sp_banner .main-span {
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    margin: 25px auto;
    margin-top: 15px;
}

.sp_banner .ban-lin-cnt {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
}

.sp_banner .ban-lin-cnt a {
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  padding-left: 10px;
}

.sp_banner .ban-lin-cnt .divider {
  background: white;
  height: 23px;
  padding: 2px 1px;
}

.sp_banner .ban-lin-inner {
  display: flex;
  justify-content: space-between
}

.sp_banner .banner-bt {
  position: relative;
  padding-top: 25px;
  padding-bottom: 0px;
}

.sp_banner .banner-bt.txt-right span {
  line-height: 15px;
  position: absolute;
  left: 32%;
  font-size: 20px;
  color: #fff;
  letter-spacing: 1px;
}

.sp_banner .banner-bt.txt-left span {
  line-height: 15px;
  position: absolute;
  left: 32%;
  font-size: 20px;
  color: #fff;
  letter-spacing: 1px;
}

.sp_banner .banner-bt button {
  margin-top: 30px;
  padding: 11px 15px;
  width: 180px;
  font-size: 18px;
  height: 50px;

}

.sp_banner .banner-bottom {
  text-align: center
}

.sp_banner .banner-bottom h3 {
  font-size: 23px;
  color: #fff;
  line-height: 42px;
  text-align: center
}

.sp_banner .banner-bottom button span img {
  padding-right: 10px
}

.sp_banner .banner-bottom button {
  background: transparent;
  color: #00ced5;
  border: 1px solid #00ced5;
  font-size: 18px;
  padding: 12px 15px;
  height: 50px;

}

/*BANNER*/

/*HOW IT WORKS*/
.hiw {
  background: #EEFCFE;
}

.hiw .hiw-tit {
  padding-top: 50px;
  padding-bottom: 40px;
}

.hiw .hiw-tit h2 {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 30px;
  color: #000;
}

/* .hiw .hiw-tit h4 {
  font-size: 34px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 55px;
  color: #000;
} */
/* 
.hiw .hiw-1 {
  padding-bottom: 80px;
} */

.hiw .hiw-1 .hiw-t2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 1px;
  color: #000;
}

.hiw .hiw-1 ul li {
  border: none;
  padding-left: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #000;
  padding-bottom: 0;
  padding-top: 10px;
}

.hiw .hiw-1 .hiw-box {
  padding-left: 100px;
  margin-top: 60px;
}

.hiw .hiw-1 .hiw-box .hiw-links {
  padding-top: 15px;
}

.hiw .hiw-1 .hiw-box .hiw-links svg:hover ,
.hiw .hiw-2 .hiw-box .hiw-links svg:hover,
.hiw .hiw-3 .hiw-box .hiw-links svg:hover{
    background: white;
    cursor: pointer;
    width: 20px;
    height: 20px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}
.hiw .hiw-1 .hiw-box .hiw-links .divider,
.hiw .hiw-2 .hiw-box .hiw-links .divider,
.hiw .hiw-3 .hiw-box .hiw-links .divider{
    position: absolute;
    left: 0;
    background: #E4E4E4;
    width: 1px;
    height: 48px;
}
.hiw .hiw-1 .hiw-box .hiw-links svg,
.hiw .hiw-2 .hiw-box .hiw-links svg,
.hiw .hiw-3 .hiw-box .hiw-links svg {
    fill:#00ced5;
    margin: -4px;
}
.hiw .hiw-1 .hiw-box .hiw-links a ,
.hiw .hiw-2 .hiw-box .hiw-links a ,
.hiw .hiw-3 .hiw-box .hiw-links a {
  font-size: 15px;
  color: #3D3D3D;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 45px;
  padding-left: 15px;
}

.hiw .hiw-1 .hiw-box .hiw-links button {
  padding: 11px 16px;
}

.hiw .hiw-1 .hiw-box .hiw-links button:hover {
  color: #fff;
  box-shadow: 0px 5px 10px 0px #00ced547;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background: #00ced5;
  border-color: #00ced5;
}

.hiw .hiw-2 .hiw-t2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 1px;
  color: #000;
}

.hiw .hiw-2 ul li {
  border: none;
  padding-left: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #000;
  padding-bottom: 0;
  padding-top: 10px;
}

.hiw .hiw-2 .hiw-box {
  padding-left: 100px;
  margin-top: 0px;
}

.hiw .hiw-2 .hiw-box .hiw-links {
  padding-top: 15px;
}

.hiw .hiw-2 .hiw-box .hiw-links a {
  font-size: 15px;
  color: #3D3D3D;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 41px;
}

.hiw .hiw-2 .hiw-box .hiw-links button {
  padding: 11px 16px;
}

.hiw .hiw-2 .hiw-box .hiw-links button:hover {
  color: #fff;
  box-shadow: 0px 5px 10px 0px #00ced547;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background: #00ced5;
  border-color: #00ced5;
}

.hiw .hiw-3 {
  padding-bottom: 0px;
}

.hiw .hiw-3 .hiw-t2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 1px;
  color: #000;
}

.hiw .hiw-3 p {
  border: none;
  padding-left: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #000;
  padding-bottom: 0;
  padding-top: 10px;
}

.hiw .hiw-3 .hiw-box {
  padding-left: 100px;
  margin-top: 90px;
}

.hiw .hiw-3 .hiw-box .hiw-links {
  padding-top: 15px;
}

.hiw .hiw-3 .hiw-box .hiw-links a {
  font-size: 15px;
  color: #3D3D3D;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 41px;
}

.hiw .hiw-3 .hiw-box .hiw-links button {
  padding: 11px 16px;
}

.hiw .hiw-3 .hiw-box .hiw-links button:hover {
  color: #fff;
  box-shadow: 0px 5px 10px 0px #00ced547;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background: #00ced5;
  border-color: #00ced5;
}

/*HOW IT WORKS END*/

/*FEATURED LISTINGS*/
.feat-list {
  padding: 20px;
}
.feat-list span{
    color:#5a5b5b;
}
.buyer-grid a{
    color:#5a5b5b;
}
.feat-list-cnt .feu-tit {
  font-size: 25px;
  font-weight: 600;
  color: #000;
  letter-spacing: 1px;
}

/*FEATURED LISTINGS END*/

/*SERVICES*/
.services-cnt {
  margin-top: 50px;
  margin-bottom: 50px;
}

.services-cnt .serv-tit h2,.client-review {
    padding-bottom: 0px;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 35px;
    color: #000;
}

.services-cnt .serv-tit h4,.hiw .hiw-tit h4 {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 30px;
  color: #000;
}

.services-cnt .services {
  margin-top: 15px;
}

.services-cnt .services:nth-child(3) {
  margin-top: 0px;
}

.services .serv-data img {
    position: absolute;
    z-index: 1000;
}

.services .serv-data span {
    background: #ffffff;
    height: 75px;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100px;
    margin: auto;
}


.services .serv-data h4 {
    font-family: 'Karla';
    font-size: 20px;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 600;
}

.services .serv-data p {
  font-size: 14px;
  color: #fff;
  letter-spacing: 1px;
  line-height: 24px;
}

.services-bt {
  justify-content: center;
  margin:50px auto;
}

.services-bt .lt {
  margin-right: 15px;
}

.services-bt .rt {
  margin-left: 15px;
}

/*SERVICES END*/

/*BLOG*/
.blog-cnt {
    padding-bottom: 60px;
    padding-top: 50px;
}

.blog-cnt .blog-tit {
  padding-bottom: 40px;
}

.blog-cnt .blog-tit h2 {
    padding-bottom: 0px;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 25px;
    color: #000;

}

.blog-cnt .blog-tit h4 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 30px;
  color: #000000;
}

.blog-cnt img {
  border: 15px solid #242A31;
}

.blog-cnt .blog-desc {
  padding-top: 15px;
  padding-bottom: 30px;
}

.blog-cnt .blog-desc h3 {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 26px;
}

.blog-cnt .blog-desc p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.blog-cnt .blog-list h4 {
  font-size: 20px;
  font-weight: 600;
  color: #808080;
  letter-spacing: 1px;
}

.blog-cnt .blog-list li {
  border: none;
  font-size: 16px;
  padding-bottom: 5px;
}

/*BLOG END*/

/*FOOTER*/
address {
  line-height: 27px;
}

.foot-link li {
  color: #C4C4C4;
  padding: 5px 0;
}

.foot-link.ft-before:before {
  height: 85px;
  top: 37px;
  left: -50px;
  width: 1px;
}

.foot-link.ft-after:before {
  height: 85px;
  top: 37px;
  width: 1px;
  display: none;
  right: -45px;
}
.foot-link ul li.main-li {
  font-size: 16px;
}
.foot-link .foot-addr{
  padding-top: 2px;
}
/*FOOTER END*/

.has-error input[type="text"], .has-error input[type="email"], .has-error select {
  border: 1px solid #a94442;
}
.bid-hiy .table-responsive{
    height: auto;
    max-height: 483px;
    min-height: auto;
    overflow: auto;
}
.grid-body span{
    overflow: hidden;
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.buyer-grid button {
    font-size: 14px;
}
.bids-details .span-block span:nth-child(1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.modal-body form{
    display: inline-table;
}

/* 25/07/19 */
.offer-more span{
    color: #18bbd5;
    line-height: 21px;
    cursor: pointer;
}
.offer-more p{
    line-height: 20px;
    overflow: hidden;
    width: 70%;
    height: 21px;
    white-space: nowrap;
    text-overflow: ellipsis;
    float: left;
    margin-right: 20px;
}

/* 03/08/2019 */
.jss19{
    color: #fff!important;
}
.modal{
    z-index: 9999!important;
}
.d-hotline{
    background-color: #00ced5 !important;
    border-color: transparent #00ced5 transparent transparent !important;
}
.fixed-bottom{
    position: fixed;
    bottom: 45%;
    right: -25px;
    background: #00ced5;
    width: 47px;
    height: 150px;
    border-radius: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 9999;
    box-shadow: 0 0px 10px 0 rgba(0,0,0,.26);
    
}
.fixed-bottom h4{
    /* width: 40px;
    height: 40px;
    object-fit: contain; */
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    margin: 0;
    padding: 0px 0px 0px 3px;
    color: #fff;
    cursor: pointer;
  
}
.fixed-bottom .after-class{
    position: relative;
}
.fixed-bottom .after-class .after-ico{
display: none;
}
.fixed-bottom .after-class:hover .after-ico .ico{
    padding: 3px;
}
.fixed-bottom .after-class:hover .after-ico{
    display: block;
    position: absolute;
    bottom: 0;
    background: #00ced5;
    border-radius: 100px;
    width: 65px;
    height: 150px;
    left: -13px;
    z-index: -1;
    -webkit-transition: all 5s ease-in;
    transition: all 5s ease-in; 
}
.fixed-bottom .after-class:hover .after-ico{   
    padding: 15px 12px;
}
.main-flex{
    display: flex;
    justify-content: left;
    margin-left: 25px;
}
.main-flex button.btn-primary{
    font-size: 18px;
    font-weight: 600;
    background: #faad39;
    border: 1px solid #faad39; 
    border-radius: 8px;
    padding: 9px 33px;
    margin: 0 8px;
    margin-top:15px;
    
}
.main-flex button.btn-primary:focus{
    background: #ffffff;
    color: black;
    border: 1px solid #faad39;
}
.main-flex button.btn-primary.btn-whi-ban{
    background: #e18b09;
    border: 0px solid #e18b09;
    color: rgb(255, 255, 255);
}
.main-flex button.btn-primary.btn-whi-ban:hover{
    background: #0a9fa4;
    border: 0px solid #0a9fa4;
    color: rgb(255, 255, 255);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    box-shadow: none;
}

.pac-container{
    z-index:  9999 !important;
}
/* 16/10/19 */
/* 31/10 */
.pricing.nav-tabs {
    border-bottom: 1px solid #00ced5;
}
.btn-primary.focus, .btn-primary:focus,
.btn-primary.focus, .btn-primary:active {
    color: #000;
    background-color: #ffffff;
    border-color: #faad39;
}
.sp_banner .main-span .btn{
    padding: 9px 28px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 800;
    text-transform: capitalize;
}
.header-right li.active a{color:#fff!important; background: transparent;    background-color:transparent!important;}
.header-right li.active:after,.header-right li:hover:after{
    position: absolute;
    content: '';
    background: #ffffff;
    width: 35%;
    height: 4px;
    bottom: 0;
    left: 0px;
    right: 0;
    margin: auto;
    border-radius: 100px;
}
.content.ranger span:nth-child(1), .content.ranger span:nth-child(3) {
    width: 100%;
}
.accordion .option .range-slider span.range {
    width: 25%!important;
}
/* .content.ranger span{width: 100%} */
/* 21/01/2020 */
.static-cnt ul li{
    padding: 4px 0;
    line-height: 20px;
    font-size: 15px;
}
.list-roman {
    list-style: lower-roman;
    list-style-position: inside;
}
.list-number {
    list-style-position: inside;
    list-style: decimal;
}

/* 18/03/2020 */
.hiw.snd-bg .hiw-tit h2,
.hiw .hiw-tit h2,
.blog-cnt .blog-tit h2,
.services-cnt .serv-tit h2,
.Home-form h4{
    color: hsl(206, 100%, 25%);
}































































/* Responsive */
@media only screen and (max-width: 1264px) {
    .contact .btn-secondary {
        padding: 8px 20px;
    }
    .custom-container {
        width: 100%;
    }
    .client-cmt{
        display: none;
    }
    .profile:before{
        left: -25px;
    }
}
@media only screen and (max-width: 1024px) {
    .mesage-body .user-chat{
        margin: 16px 10px;
    }
    .filter-body .title{
        font-size: 15px;
    }
    .profile{
        margin: 20px auto;
    }
    .profile:before{
        left: -20px;
    }
    .contact .btn-secondary{
        padding: 8px 10px;
    }
    .googly-img img {
        height: 100%;
        object-fit: cover;
    }

    .width-400 {
        display: none;
    }

    .width-300 {
        width: 300px !important;
    }

    .custom-container {
        width: 100%;
    }

    .nav-dash.box-shadow {
        padding: 11px 25px;
    }

    .filter-body .nav-dash .ul-list li{
        font-size: 16px;
    }
}

@media only screen and (max-width: 991px) {
    .sp_banner .main-h{
        margin-left: auto;
    }
    .sp_banner .main-p{font-size: 22px;}
    .sp_banner .main-h h3 {
        font-size: 22px;}
    .sp_banner .main-h h1{
    font-size: 38px;
    line-height: 40px;
    }
    .sp_banner .banner-box{
        right: 74%!important;
        width: 100%!important;
    }
    .pricing {
        margin: 4% 25px;
    }
    .videos-info {
        flex-flow: column;
        margin-bottom: 17%;
    }
    .pricing h4 {
        padding: 0 90px;
    }
    .video-card {
        width: 500px;
        max-width: 500px;
        margin: auto;
        margin-bottom: 43px;
    }
    .loadmore{
        margin : 15px auto!important;
    }
    .googly-img {
        display: none;
    }
    .bids-data .pad-left-0 {
        padding-left: 0;
        padding-right: 0;
    }
    .sell-rw .col-md-6{
        width: 50%;
        float: left;
    }
    .nav-dash.box-shadow{
        margin-top: 0px;
    }
}
@media only screen and (max-width: 800px) {
    .navbar-default .navbar-nav.header-right > li > a {
        font-size: 14px!important;
        padding: 12px 10px!important;
    }
}
@media only screen and (max-width: 768px) {
    .sp_banner .banner-box{
        position: relative;
        right: 5%!important;
        left: 0;
        width: 100%!important;
        -webkit-transform: translate(0, 5%)!important;
                transform: translate(0, 5%)!important;
    }
    .mod-place-bid .modal-dialog {
        width: 100%;
        margin: 15% auto;
    }
    .single-alis img{
        margin: auto 0;
    }
    .post-up{
        margin-top: 0em!important;
    }

    .navbar-default .navbar-collapse,
    .navbar-default .navbar-form {
        border-color: #e7e7e700;
    }

    .navbar-default .navbar-toggle {
        border-radius: 0px;
        border-color: #fff;
    }

    .navbar-default .navbar-toggle .icon-bar {
        background-color: #fff;
    }

    .navbar-default .navbar-toggle:focus,
    .navbar-default .navbar-toggle:hover {
        background-color: #00ced5;
    }

    .navbar-default .navbar-nav.header-right > li > a {
        font-size: 18px;
        padding: 15px 14px;
    }

    .alpha-form {
        padding: 100px 0;
    }

    .News-letter {
        text-align: center;
    }

    .footer-above form.navbar-left {
        float: unset !important;
        text-align: center
    }

    .flex.justify-around {
        justify-content: space-evenly;
        padding: 35px 0;
        padding-bottom: 0;
    }

    .show-fotter .foot-bg {
        background-size: cover;
    }
    .pricing {
        margin: 4% 25px;
    }
}

@media only screen and (max-width: 767px) {
    .sp_banner .main-p{
        font-size:16px;
        margin:15px auto;
    }
    .main-flex{
        margin-left: 0px;
    }
    .sp_banner{
        background-position: left;
    }
    section.filter-body{
        margin-top: 20em;
    }
    .pricing h4 {
        padding: 0 70px;
    }
    .videos-info {
        flex-flow: column;
    }
    .video-card img {
        object-fit: cover;
        height: 100%;
    }
    .video-card figure {
        width: 550px;
    }
    .video-card {
        width: 100%;
        max-width: 100%;
        display: flex;
        margin: 3% auto;
    }
    .navbar-default .navbar-collapse, .navbar-default .navbar-form{
        overflow: scroll;
        text-align: center;
    }
    .mod-place-bid .modal-dialog .Property-list .show-check{
        font-size: 14px;
    }
    .bids-data{
        border-bottom: 2px solid #00ced5;
        border-right: 1px solid #C9C3C3;
    }
    /* responsive togglre */
  .toggle._aee.sea_ch{
    display: block;
    background-color: #05396B;
    padding: 14px 20px;
    color: #FFF;
    font-size: 17px;
    text-decoration: none;
    border: none;
      }
  [id^=drop]:checked + ._sch {
      display: block;
      }
  .toggle.sea_ch + a, .menuu {
      display: none;
    }
  .toggle.sea_ch {
          display: block;
          background-color: #254441;
          padding:14px 20px;
          color:#FFF;
          font-size:17px;
          text-decoration:none;
          border:none;
        }
  /* responsive togglre */
    .font-15{
        font-size: 14px!important;
    }
    .fetu-width .buyer-grid {
        width: 48%;
        margin: 15px 0;
    }
    .feture-list .justify-between{
        flex-wrap: wrap;
    }
    .footer-above .navbar-form input.width-400{
        display: block!important;
        width:100%!important;
    }
    .bids-details .flex.align-end{
        justify-content: center;
        height: auto;
        align-items: center!important;
    }
}

@media only screen and (max-width: 600px) {
    /* .sp_banner .main-p,
    .sp_banner .learn{
        text-align: center;
    } */
    .sp_banner .banner-box {
        padding: 10px;
    }
    .ifram-wth{
        width:425px!important;
    }
    .sp_banner .learn{
        text-align:left!important;
    }
    .team .heading-title{
        margin-top: 100px;
    }
    .pull-right.pro-center {
        text-align: center;
        float: unset!important;
    }
    .select-two.slect-sort{
        margin: 5% auto;
    }
    .content.ranger span:nth-child(1), .content.ranger span:nth-child(3) {
        width: 100%;}
       .accordion .option .range-slider{
        flex-flow: row;
        display: flex!important;
        align-items: center;
       }
       .accordion .option .range-slider span.range {
        width: 15%!important;
    }
    .range-slider {
        flex-flow: column;
        display: block;
        text-align: center;
    }
    .range-slider span.range {
        width: 100%!important;}
    .find-card .inner-card{
        margin: 0 20px;
    }
    #minmaxslider span:nth-child(1) {
        left: 0!important;
    }
    .pricing .tab-content ul {
        padding: 10px 20px;
    }
    .text-center.static-btn{
        flex-flow: column;
    }
    .static-accordient .tab{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-radius: 8px;
        margin-bottom: -8px;
    }
    .pricing .tab-content h4{
        font-size: 16px;
    }
    .pricing h4 {
        padding: 0;
    }
    .pricing {
        margin: 4% 25px;
    }
    .static-accordient .tab-label{
        padding: 10px;
        font-size: 13px;
        z-index: 99;
        position: relative;
    }
    .site-form label{
        font-size: 15px;
    }
    #singlealis .single-alis {
        width: 100%;
        float: left;
        padding-right: 0;
    }
    .sp_banner .main-h{   
        margin-left: 0px;}
    .sp_banner{
        background-position: 70% 52%;width: 100%;
    }.sp_banner .main-h h1,.sp_banner .main-h h3{line-height: 35px;    margin: 20px auto;}
    
    .alpha-form1_support{
        width: 100%!important;
    }
    .bid-hiy .modal-body.pro-ty {
        margin: 10px 35px;}
    .contact {
        margin: 15px 16px;
    }
    .contact:after{
        display:none;
    }
    .mod-place-bid .modal-dialog{
        width: 100%;
        margin: 55px auto;
    }
    .foot-link.ft-after:before {
        display: none;
    }

    .foot-link.ft-before:before {
        display: none;
    }
    .foot-bg.pad-30{
        padding: 30px 5px!important;
    }
    .contact .btn-secondary {
        padding: 8px 20px;
        font-size: 14px;
    }
    .commission-question-div{    display: flex;
        align-items: baseline;
    flex-flow: column;}
    a.C-a{    width: 200px;
        margin: auto;}
    
}

@media only screen and (max-width: 500px) {
    .pricing .nav-tabs {
        border-bottom: 0px solid #00ced5;
    }
    .pricing .nav-tabs>li,.pricing .nav-tabs>li>a {
        width: 100%;
        margin-bottom: 5px;
    }    
    .sp_banner .banner-box{    padding: 0 5px;}
    .video-card {
        flex-flow: column;
    }
    .video-card figure {
        width: 100%;
    }
    .Property-list{
        display: flex;
        flex-flow: column;
        padding: 25px;
    }
    .list-pro .col-xs-4,.list-pro .col-xs-8{
        width: 100%;
    }
    .scnd-slider{
        overflow: auto;
    }
    .timer-font{
        font-size: 15px;
    }
    .fetu-width .buyer-grid {
        width: 100%;
    }
    .elty {
        width: 100%;
    }

    .width-300 {
        width: 100% !important;
    }

    .width-400 {
        display: block;
    }

    .footer-above form.navbar-left {
        box-shadow: none;
        border: none;
    }

    
}

@media only screen and (max-width: 475px) {
    .sp_banner{
        background-position: 52%;
    }
    .sp-banner .main-p{
        margin:5px auto;
    }
    .main-flex button.btn-primary{
        padding: 6px 18px;
        margin: 0 0px 0;
        font-size: 14px;
        width:100px;
    }
    .checklist ul h5{
        text-align: center;
    }
    .logo-design img{
        width: 110px;
    }
    .center-part .sch-banner{
        background-position: center;
    }
    .sp_banner .main-h h1, .sp_banner .main-h h3{
        line-height: 30px;
    }
    .select-two.slect-sort{
        margin-bottom: 15px;
      }
    .select-two.slect-sort{
        margin: 5% auto;
        width: 80%;
    }
    .property-body {
        width: 100%;
        padding: 15px 0px;
    }
    .contact {
        text-align: center;
    }
    .services .serv-data p{
        /* background: #fff; */
    }
    .product {
        padding: 0 30px;
    }
    .product .col-xs-7,.product .col-xs-5{
        width: 100%;
    }
    .timer-font {
        font-size: 13px;
    }
    .fancy-btn{
        width: 100%; 
    }
    .content-main .tab-connect .nav-tabs{
        width: 100%;
    }
    .fancy-btn button {
        position: absolute;
        left: 30%;
    }
    .filter-body .nav-dash figure.img-pads .edit{
        right: 92px;
    }
    .sp_banner .banner-box{-webkit-transform: translate(0, 0%)!important;transform: translate(0, 0%)!important;
    }
}

@media only screen and (max-width: 425px) {
    .hiw .hiw-tit h2, .blog-cnt .blog-tit h2, .client-review{
        font-size: 20px;
    }
    .ifram-wth{
        width:320px!important;
    }
    .sp_banner .main-h h3,.sp_banner .main-p{
        line-height: 20px;
    }
    .sp_banner .main-h h1{
        line-height: 25px;
    }
    .sp_banner .main-h h1, .sp_banner .main-h h3,.sp_banner .main-p{
        margin: 0 0 10px 0;
    }
    .checklist ul li.list-btn{
        text-align: center;
    }
    .services-cnt .services:nth-child(3) {
        margin-top: -14px;
    }
    .services-bt .ss.lt{
        margin-right: 0px;
        margin-bottom: 15px;
    }
    .card-fot button{
        width: auto;
    }
    .learn-video iframe{
        width: 100%;
    }
    .main-mar-pro,.img-before img {
        width: 100%;
        max-width: 100%;
    }
    .grid-body span{
        width: 100%;
    }
    .foot-link.social ul li{
        font-size: 18px;
    }
    .bid-hiy .modal-body {
        margin: 10px 30px;
    }
    .content-main .tab-connect .nav-tabs li:nth-child(1) {
        padding-right: 20px;
        padding-left: 25px;
    }
    .content-main.pad-30{
        padding: 20px !important;
    }
    .filter-body.Buyer-dash .flex.justify-between.pre-txt {
        display: inline-grid;
        text-align: center;
        justify-content: center;
        margin: auto;
        width: 100%;
        line-height: 55px;
    }
    .proper-data span{
        font-size: 16px;
    }
    /* .pad-55.elty{
        padding: 30px;
        padding-top: 20px;
    } */
    /* }  */
    .navbar-default .navbar-nav.header-right > li.divider {
        background: white;
        height: 2px;
        padding: 0px;
        width: 93%;
        margin: auto;
        margin-top: 0;
    }
    
    .navbar-default .navbar-nav.header-right > li > a {
        padding: 10px 20px!important;
    }
}

@media only screen and (max-width: 375px) {
    .logo-design img{
        width:100px;
    }
    .vid-eo,.pricing .tab-content h4{
        font-size: 14px!important;
        padding: 10px 6px!important;
    }
    .property-body{
        text-align: center;
    }
    .center-part .find-banner{
        background-position: center;
    }
    .property-body .flex.justify-between {
        flex-flow: column;
        align-items: center;
    }
    .bid-submit{
        flex-flow: column;    padding: 0 0!important;
    }
    .content-main .tab-connect .nav-tabs li:nth-child(1) {
        padding-right: 20px;
        padding-left: 20px;
    }
    .content-main .tab-connect .nav-tabs li:nth-child(3),
     .content-main .tab-connect .nav-tabs li:nth-child(4) {
        padding-left: 20px;
        padding-right: 0;
    }
    .foot-bg.pad-30.pad-5 {
        padding: 5px;
    }

    .News-letter h4 {
        font-size: 18px;
    }

    .News-letter p {
        font-size: 30px;
        margin: 0px 0;
    }

    .foot-link li {
        font-size: 12px;
    }

    
    .proper-data .flex{
        display: block;
    }
    .product-hd .flex{
        display: block;
        text-align: center;
        line-height: 55px;
    }
}

@media only screen and (max-width: 320px) {
    .ifram-wth{
        width:320px!important;
    }
    .content-main .tab-connect .nav-tabs li:first-child {
        padding-right: 10px;
        padding-left: 10px;
    }
    .content-main .tab-connect .nav-tabs li:nth-child(3), .content-main .tab-connect .nav-tabs li:nth-child(4) {
        padding-left: 15px;
    }
}
/* End Responsive */

.option.fill .checkbox-list li:nth-child(1) .show-check,
.option.fill .checkbox-list li:nth-child(2) .show-check{
        color: #00ced5;
}

.makeStyles-paper-3,
.makeStyles-paper-2 {
    top: 50%!important;
    right: 50%!important;
    position: absolute;
    -webkit-transform: translate(-50%, -50%)!important;
            transform: translate(-50%, -50%)!important;

}
.matchg{
    font-size: 18px;
    cursor: pointer;
    color:#d52700;
    text-decoration: none;
}
