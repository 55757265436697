.Con-lft a:hover{
    color: #ffffff;
}
.Con-lft a{
    padding: 10px 16px;
    font-weight: 600;
    background: #faad39;
    color: #ffffff;
}   
.Con-lft h2{
    padding-right: 25%;
    padding-bottom: 20px;
    font-size: 36px;
    font-weight: 600;
    color: #ffffff;
}
.Con-lft{
    height: 600px;
    display: flex;
    align-items: start;
    flex-flow: column;
    justify-content: center;
    z-index: 9999;
    position: relative;
}
.Home-form:after{
    content: '';
    position: absolute;
    background: #00ced5;
    width: 47%;
    /* background-image: linear-gradient(45deg, #00ced5, #1eacd7); */
    height: 100%;
    top: 0;
    z-index: 999;
}