.main-mar-pro {
    margin: 20px 8px !important;
}


.dashboard-card{
    /* height: 18.5em; */
}
.text-red{
    color:red;
}
.bids-details span {
    line-height: 35px;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
    font-size: 15px;
}
.flex-direction-coloum{
    flex-direction: column;
}

















@media only screen and (max-width: 500px){
    .sell-rw .flex{
        flex-flow: column;
    }
    .sell-rw{
        text-align: center;
    }
    .sell-rw .pad-lft-20{
        padding-left: 15px;
    }
    .sell-rw .col-md-6 {
        width: 100%!important;
        float: left;
    }
}
