.upload-btn-wrapper img{
    display: block;
}
.add-remove-card {
    text-align: right;
    padding: 5px 150px 0px 0px;
    color: #00ced5;
    cursor: pointer;
}

.add-remove-card i{
    padding: 0px 3px 0px 0px;
}
