
/* our team */
.team .heading-title {
    margin-bottom: 50px;
}
.team .text-center {
    text-align: center;
}
.team .heading-title h3 {
    margin-bottom: 0;
    letter-spacing: 2px;
    font-weight: normal;
}
.team .p-top-30 {
    padding-top: 30px;
}
.team .half-txt {
    width: 60%;
    margin: 0 auto;
    display: inline-block;
    line-height: 25px;
    color: #7e7e7e;
}
.team  .text-uppercase {
    text-transform: uppercase;
}

.team .team-member, .team-member .team-img {
    position: relative;
}
.team .team-member {
    overflow: hidden;
}
.team .team-member, .team-member .team-img {
    position: relative;
}

.team .team-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    border: 20px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.90);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.team .team-member:hover .team-hover .desk {
    top: 35%;
}
.team .team-member:hover .team-hover, .team-member:hover .team-hover .desk, .team-member:hover .team-hover .s-link {
    opacity: 1;
}
.team .team-hover .desk {
    position: absolute;
    top: 0%;
    width: 100%;
    opacity: 0;
    -webkit-transform: translateY(-55%);
    -ms-transform: translateY(-55%);
    transform: translateY(-55%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    padding: 0 20px;
}
.team .desk, .desk h4, .team-hover .s-link a {
    text-align: center;
    color: #222;
}
.team .team-member:hover .team-hover .s-link {
    bottom: 10%;
}
.team .team-member:hover .team-hover, .team-member:hover .team-hover .desk, .team-member:hover .team-hover .s-link {
    opacity: 1;
}
.team .team-hover .s-link {
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;
    text-align: center;
    -webkit-transform: translateY(45%);
    -ms-transform: translateY(45%);
    transform: translateY(45%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    font-size: 35px;
}
.team .desk, .desk h4, .team-hover .s-link a {
    text-align: center;
    color: #222;
}
.team .team-member .team-img img {
    height: 250px;
    object-fit: cover;
}
.team .team-member .s-link a {
    margin: 0 10px;
    color: #333;
    font-size: 16px;
}
.team .team-title {
    position: static;
    padding: 20px 0;
    display: inline-block;
    letter-spacing: 2px;
    width: 100%;
}
.team {
    margin: 10% auto;
}
.team .team-title h5 {
    margin-bottom: 0px;
    display: block;
    text-transform: uppercase;
}
.team-title span {
    font-size: 12px;
    text-transform: uppercase;
    color: #a5a5a5;
    letter-spacing: 1px;
}
/*end  our team */
.vid-eo:hover{
    text-decoration: none;
    color: white;
}
.vid-eo{
    font-size: 15px;
    background: #faad39;
    padding: 10px;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    text-decoration: none;
    border: none;
}
.about-content p{
    font-size: 16px;
}