.overflow_chat{
    
}
.chating .chat-body .fixed-center.overflow_chat {
    overflow: auto ;
    overflow-x: hidden;
}
.user-info {
    padding: 5px;
}
.loadmore{
    margin: 21px 24px 16px 21px;
    background: rgba(55,204,148,0.4);
    text-align: center;
    color : black;
    box-shadow: 1px 2px 8px -1px rgba(55,204,148,0.4);
    max-width: 200px;
    /* padding-left: 25px; */
    margin-left: 158px;
}
.toggle.respond.msg,
[id^=dropmsg] {
    display: none!important;
    width: 100%;
}

.message-right-div{
    height: 40em;
    overflow: auto;
    scroll-behavior: auto;
    scrollbar-width: thin !important;
    /* scroll-padding: 1px; */
    /* scroll-margin: 0px; */
}

.user-chat .user-im{
    width: 60px;
    height: 60px;
    margin: 2px auto;
    border-radius: 100px;
    position: relative;
    border: 3px solid #496587;
    background: #F0F7FF;
}

.user-im div{
    margin: 10px auto;
}

.user-im div span{
    display: block;
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    color: #496587;
}

.user-im div .role{
    font-size: 10px;
}

@media only screen and (max-width: 768px){
   
   
    .toggle.respond.msg {
        display: block!important;
        background-color: #00ced5;
        padding:14px 20px;	
        color:#FFF;
        font-size:17px;
        text-decoration:none;
        border:none;
        width: 100%;
        margin: auto;
    }
    .toggle.respond.msg + a,
    .div-after {
        display: none!important;
    }
    [id^=dropmsg]:checked + .div-after{
        display: block!important;
        position: relative;
        width: 100%;
        z-index: 999;
    }

}
@media only screen and (max-width : 600px){
    .loadmore{
        margin-left: auto;
        margin: 21px auto;
    }
}
@media only screen and (max-width: 375px){
    .mesage-body .user-chat{
        margin: 16px 0px;
    }
}