.limity-body p{
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
    min-height: 140px;
    max-height: 140px;
    flex-wrap: nowrap;
}
.blog-carosal{
    margin: 5% auto;
    transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
    /* Chrome, Safari, Opera */
    -webkit-animation-name: moveit;
    -webkit-animation-duration: 2s;
    /* Standard syntax */
    animation-name: moveit;
    animation-duration: 2s;
}
.blog-tit {
    /* margin-top: 15%; */
    font-size: 24px;
    font-weight: 500;
}
@keyframes moveit {
    from {transform: scale(0);transition: all 5s cubic-bezier(0.4, 0, 1, 1);}
    to {transform: scale(1);}
}


@media only screen and (max-width: 850px) {
    .blog-carosal .sell-limity:nth-child(1){
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .blog-carosal .sell-limity:nth-child(2){
        display: none;
    }
}