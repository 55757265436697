.flexcalss{
    display:flex;
    justify-content: center;
}
.alpha-form1_support{
    width: 50%;
    margin: auto;
}

.alpha-form1 label {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}