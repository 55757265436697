.grid-container .item0{
    grid-column: span 4;
    grid-row: 1 / span 3;
}
.grid-container .item0 img {
    width: 325px;
    height: 185px;
    min-height: 185px;
    object-fit: cover;
}

span.imageDelete {
    position: absolute;
    color: #fffffff7;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background: #48b1f8;
    padding: 2px 7px;
    border-radius: 50%;
    font-weight: 600;
}

span.imageDelete:hover {
    background: #fff;
    cursor: pointer;
}

.item1 {
    grid-column: 5 / span 2 !important;
    grid-row: 1 !important;
}

.item2 {
    grid-column: 5 / span 2 !important;
    grid-row: 2 !important;
}

.item3 {
    grid-column: 5 / span 3 !important;
    grid-row: 3 !important;
}

.grid-container .listImage img {
    width: 85px !important;
    height: 56px !important;
    object-fit: cover !important;
}
#singlealis .single-alis {
    /* width: 60%; */
    float: left;
    /* padding-right: 10%; */
}
#singlealis .multi-alis img{
    width: 85%;
    float: right;
    margin-right: 10px;
}
@media (max-width:1190px){
    #singlealis .single-alis img {
        height: 200px;
        min-width: 18em;
        margin: auto;
        object-fit: cover;
        margin-right: 10px;
    }
}
@media (max-width:986px){
    #singlealis .single-alis img {
        height: 200px;
        min-width: 24em;
        margin: auto;
        object-fit: cover;
        margin-right: 10px;
    }
    #singlealis .multi-alis img {
        width: 50%;
        float: right;
        margin-right: 40px;
    }
}
@media (max-width:734px){
    #singlealis .multi-alis img {
        width: 73.5%;
        float: right;
        margin-right: 40px;
        /* display: flex; */
        /* flex-direction: column; */
        float: left;
        margin-top: 10px;
    }
    #singlealis {
        flex-direction: column
    }
}
@media (max-width:600px){
     .single-alis img {
        width: 100%;
        min-width: 100%;
    }
}
