.center-arrow-featurelisting-left{
    font-size : 5rem;
    opacity : 0.4;
    margin : auto 0;
    padding-right : 1em;
    transition: .5s;
}
.center-arrow-featurelisting-left:hover{
    transition: .5s;
    opacity: 0.6;
}
.center-arrow-featurelisting-right:hover{
    transition: .5s;
    opacity: 0.6;
}
.center-arrow-featurelisting-right{
    font-size : 5rem;
    opacity : 0.4;
    margin : auto 0;
    padding-left : 1em;
    transition: .5s;
}
.text-align-left{
    text-align: left;
    font-weight: 400 !important;

}
.feture-title{
    padding: 10px 0;
    border-bottom: 1px solid #e3e3e3;
}
.feture-title span{
    font-size: 15px;
}
