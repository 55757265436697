#tooltip{
    position: relative;
    display: inline-block;
}
#tooltip .tooltip_base{
    visibility: hidden;
  width: 120px;
  background-color: #6A6A6A;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
    word-wrap: break-word;
    width: 300px;
      height: 150px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
#tooltip .tooltip_base {
    bottom: -10px;
    right: 105%; 
  }
#tooltip:hover .tooltip_base {
    visibility: visible;
  }
  