@import url('https://fonts.googleapis.com/css?family=Karla:400,700|Lato:300,400,700,900&display=swap');

body {
    font-family: 'Karla', sans-serif !important;
    font-family: 'Lato', sans-serif !important;
    overflow-x: hidden !important;
}

.karla {
    font-family: 'Karla', sans-serif !important;
}

.lato {
    font-family: 'Lato', sans-serif !important;
}

.bannercarosal .thumbs-wrapper.axis-vertical {
    display: none;
}

.btn.focus,
.btn:focus,
.btn:hover {
    outline: 0;
}

.primary {
    background: #00CED5 !important;
}

.secondary {
    background: #7AECB0 !important;
}

.gray {
    background: #242A31 !important;
}

.text-light {
    color: #00CED5 !important;
}

.text-dark {
    color: #242A31 !important;
}

.text-white {
    color: #ffffff !important;
}

.text-green {
    color: #0EC882 !important;
}

.text-blue {
    color: #2660A8 !important;
}

.text-error {
    color: #D85858 !important;
}

.text-black {
    color: #000000 !important;
}

.text-input {
    color: #9E9E9E !important;
}

.box-shadow {
    box-shadow: 0px 0px 10px 2px #00000014;
}

.text-gray {
    color: #848888 !important;
}

.pointer{
    cursor: pointer;
}

.font-12 {
    font-size: 12px !important;
}

.m-auto {
    margin: auto;
}

.font-15 {
    font-size: 15px !important;
}

.font-20 {
    font-size: 20px !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.pad-10 {
    padding: 10px !important;
}

.pad-0 {
    padding: 0 !important;
}

.mar-0 {
    margin: 0 !important;
}

.width-100 {
    width: 100px !important;
}

.width-15 {
    width: 15% !important;
}

.width-200 {
    width: 200px !important;
}

.width-300 {
    width: 300px !important;
}

.width-400 {
    width: 400px !important;
}

.width-500 {
    width: 500px !important;
}

.custom-container {
    width: 1306px;
}

.flex-wrap {
    flex-wrap: wrap;
}

.line-height-30 {
    line-height: 30px;
}

.main-mar-pro {
    margin: 20px 0px;
}

.btn-primary {
    background: #00CED5;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    padding: 9px 33px;
    border-color: #00ced5;
    text-transform: uppercase;
}

.btn-white {
    background: white !important;
    color: #00CED5 !important;
    font-size: 14px !important;
    padding: 8px 16px !important;
    margin: 8px !important;
}

.btn-primary:hover {
    background: #00CED5;
    color: #fff;
    border-color: #00ced5;
    box-shadow: 0px 0px 0px 3px #00ced53b;
    transition: all 0.4s;
}

.btn-secondary {
    background: #faad39;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 9px;
    padding: 10px 50px;
    border-color: #faad39;
    text-transform: capitalize;;
}

.btn-blury {
    background: rgb(255, 255, 255);
    color: #063B7B;
    font-size: 16px;
    font-weight: 400;
    border-radius: 9px;
    padding: 10px 30px;
    border-color: #063B7B;
    text-transform: capitalize;
}

.btn-secondary:hover {
    color: #fff;
    box-shadow: 0px 5px 10px 0px #00ced547;
    transition: all 0.4s;
}

.select-two {
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    height: 40px;
}

.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.justify-around {
    justify-content: space-around;
}

.justify-between {
    justify-content: space-between;
}

.pad-20 {
    padding: 20px !important;
}

.pad-15 {
    padding: 15px !important;
}

.pad-30 {
    padding: 30px !important;
}

.align-end {
    align-items: flex-end !important;
}

.pad-55 {
    padding: 55px !important;
}

.pad-btm-7em {
    padding-bottom: 7em !important;
}

.pad-btm-20 {
    padding-bottom: 20px !important;
}

.pad-top-20 {
    padding-top: 20px !important;
}

.pad-lr-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.pad-lr-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.pad-tb-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.pad-top-30 {
    padding-top: 30px;
}

.mar-top-30 {
    padding-top: 30px;
}

.mar-top-20 {
    padding-top: 20px;
}

.pad-btm-30 {
    padding-bottom: 30px;
}

.border {
    border: 1px solid #01152D;
}

.border-none {
    border: none;
}

.mar-btn-30 {
    margin-bottom: 30px !important;
}


/* css */
.client-review{
    font-size: 42px;
}
.snow-pad .snow-header a.navbar-brand {
    font-family: 'Karla', sans-serif;
    font-size: 32px;
    font-weight: 400;
    color: #fff;
}

.navbar-default .navbar-nav.header-right > li > a {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
}

.navbar-default .navbar-nav.header-right > li.divider {
    background: white;
    height: 13px;
    padding: 0 0.6px;
    margin-top: 19px;
}

.Home-form {
    /* background-image: url("/images/home-f-bg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 600px;
    display: flex;
    align-items: center; */
    position: relative;
}
.show-fotter .foot-bg {
    background-image: url("/images/foot-img-1.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.show-fotter .foot-bg:after {
    content: '';
    position: absolute;
    background: #E1E2E4;
    bottom: 0;

}

.foot-link ul {
    padding: 0;
    list-style-type: none;
    color: #fff;
    margin-bottom: 0;
}

.foot-link ul li.main-li {
    font-weight: 600;
    padding-bottom: 10px;
    margin-bottom: 0;
    color: #ffffff;

}

.News-letter h4 {
    font-size: 22px;
    font-weight: 500;
}

.News-letter p {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
}

.footer-above .navbar-form input {
    border: 1px solid #E1E2E4;
    border-radius: 4px;
    padding: 20px 18px;
}

.footer-above .navbar-form input.form-control:focus {
    border-color: #00ced5;
}

.foot-link address {
    margin-bottom: 0;
}

.foot-link li {
    padding: 3px 0;
    color: #919191;
    cursor: pointer;
}

.foot-link li:hover {
    color: #c4c4c4;
    cursor: initial;
}

/* .foot-link .social ul li {
    padding-right: 20px;
    font-size: 20px;
    color: #cccccc;
} */

.elty {
    box-shadow: 0px 3px 30px 0px #6161611f;
    width: 480px;
    margin: auto;
    border-bottom: 5px solid #00ced5;
    padding-bottom: 20px !important;
    position: relative;
}

.elty label {
    color: #242A31;
    font-size: 15px;
    font-family: 'Karla', sans-serif !important;
    font-weight: 400;
}

.elty input.form-control:focus {
    border-color: #00ced5;
}

.elty input {
    height: 44px;
    font-family: 'Karla', sans-serif !important;
}

.link-redir p {
    font-family: 'Karla', sans-serif !important;
}

input.check-height {
    height: 15px;
}

.alpha-form {
    padding-top: 100px;
}

.top-hd {
    position: absolute;
    top: -24px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 4px;
    width: 65%;
    color: #fff;
    border-radius: 4px;
    background: #00ced5;
    margin: auto;
}

.top-hd h4 {
    font-size: 20px;
    font-family: 'Lato', sans-serif !important;
    position: relative;
}

/* .top-hd h4:before {
    content: '';
    position: absolute;
    left: 130px;
    bottom: -4px;
    width: 25px;
    height: 2px;
    background: #fff;
} */

.center-part {
    margin: 69px auto;
    margin-bottom: 0;
}

.checkbox .mar-btm-30 {
    margin-bottom: 30px;
}

/* Custom checkbox */
.show-check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    float: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.show-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.show-check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    border-color: #CECECE;
    width: 18px;
}

/* On mouse-over, add a grey background color */
.show-check:hover input ~ .checkmark {
    background-color: #E5E1E1;
}

/* When the checkbox is checked, add a blue background */
.show-check input:checked ~ .checkmark {
    background-color: #E5E1E1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.show-check .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.show-check input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.show-check .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #00ced5;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* end */
.foot-link.ft-after,
.foot-link.ft-before {
    position: relative;
}

.foot-link.ft-before:before {
    content: '';
    position: absolute;
    height: 65px;
    left: -35px;
    top: 40px;
    width: 2px;
    background: #919191;
}

.foot-link.ft-after:before {
    content: '';
    position: absolute;
    height: 65px;
    right: -35px;
    top: 40px;
    width: 2px;
    background: #919191;
}

.elty select {
    height: 44px;
    font-family: 'Karla', sans-serif !important;
}

.elty select.form-control:focus {
    border-color: #00ced5;
}

.top-hd.reg-hd h4:before {
    left: 117px;
    bottom: -4px;
    width: 31px;
}

.forgot-form {
    padding: 100px 0;
    margin: auto;
}

.top-hd.for-hd h4:before {
    left: 79px;
    width: 30px;
}

/* search Page */
.center-part .sch-banner {
    background-image: url("/public/images/sch-banner.png");
    height: 60vh;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;
}

.center-part .sch-banner .sch-content {
    background-color: #000000;
    opacity: 0.2;
    filter: alpha(opacity=60);
    height: 60vh;
}

.find-card h3 {
    font-size: 26px;
    font-weight: 600;
    color: #07192E;
    margin: 35px auto;
    margin-top: 0;
}

.find-card h3 span {
    position: relative;
}

.find-card .inner-card {
    padding: 30px 100px;
    background: white;
    border-radius: 17px;
    margin: 0 75px;
    -moz-box-shadow: 0px 3px 16px 0px #0000001a;
    -webkit-box-shadow: 0px 3px 16px 0px #0000001a;
    box-shadow: 0px 3px 16px 0px #0000001a;
}

.find-card {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 70%;
}

.find-card h3 span:before {
    content: '';
    position: absolute;
    background: #00CED5;
    width: 7px;
    height: 20px;
    left: -15px;
    top: 6px;
    border-radius: 20px;
}

/* Filter */
.filter-body .accordion {
    max-width: 30em;
}

.filter-body .toggle {
    display: none;
}

.filter-body .option {
    position: relative;
}

.filter-body .title,
.content {
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: all 0.2s;
}

.filter-body .title.active {
    color: #00CED5;
}

.filter-body .title {
    padding: 1em;
    display: block;
    color: #17365D;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
}

.filter-body .title:after,
.filter-body .title:before {
    content: '';
    position: absolute;
    right: 1.25em;
    top: 1.25em;
    width: 3px;
    height: 0.75em;
    background-color: #17365D;
    transition: all 0.2s;
}

.filter-body .title:after {
    transform: rotate(90deg);
}

.filter-body .title.active:after {
    background: #00CED5
}

.filter-body .content {
    max-height: 0;
    overflow: hidden;
    background-color: #fff;
}

.filter-body .content p {
    margin: 0;
    padding: 0.5em 1em 1em;
    font-size: 0.9em;
    line-height: 1.5;
}

/* .toggle:checked + .title, .toggle:checked + .title + .content {
    box-shadow: 3px 3px 6px #ddd, -3px 3px 6px #ddd;
  } */
.filter-body .toggle:checked + .title + .content {
    max-height: 500px;
}

/* content ranger */
.filter-body .toggle:checked + .title + .content.ranger {
    overflow: unset;
}

/* content ranger */
.filter-body .toggle:checked + .title + .content.ranger .MuiSlider-thumb .MuiSlider-valueLabel{
    width: 32px;
    text-align: center;
}

/* content ranger */
.filter-body .toggle:checked + .title + .content.ranger .MuiSlider-thumb .MuiSlider-valueLabel span,
.filter-body .toggle:checked + .title + .content.ranger .MuiSlider-thumb .MuiSlider-valueLabel span span{
    width: 100%;
}

.filter-body .toggle:checked + .title:before {
    -webkit-transform: rotate(90deg) !important;
    transform: rotate(90deg) !important;

}

.filter-body h3 span {
    position: relative;
    padding: 6px 28px;
}

.filter-body h3 span:before {
    content: '';
    position: absolute;
    background: #00CED5;
    width: 7px;
    height: 20px;
    left: 14px;
    top: 10px;
    border-radius: 20px;
}

section.filter-body {
    margin: auto;
    margin-top: 16em;
    margin-bottom: 5em;
}

/* End Filter */
.content .show-check span.checkmark {
    width: 18px;
    height: 18px;
    border: 1px solid #7adadd;
    background: #ffffff;
}

.content .show-check {
    font-size: 18px;
    color: #2660A8;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}

.content ul {
    list-style-type: none;
}

.content .show-check {
    float: unset;
}

.contact {
    margin: 35px 37px;
}

.content .show-check .checkmark:after {
    left: 6px;
    top: 2px;
}

.sort {
    padding: 10px 0px;
}

.list-pro {
    box-shadow: 0px 0px 10px 2px #00000014;
}

.profile {
    width: 100px;
    height: 100px;
    margin: 32px auto;
    border-radius: 100px;
    position: relative;
    border: 3px solid #496587;
}

.profile span {
    display: block;
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    color: #496587;
}

.profile span:nth-child(2) {
    font-size: 14px;
}

.profile .div {
    margin: 17px auto;
}

.profile:before {
    content: '';
    background-image: url(/public/images/icons/star.svg);
    position: absolute;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: left;
    left: -40px;
    top: -25px;

}

.contact .btn-secondary {
    padding: 8px 19px;
}

.contact:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 75%;
    background: #e3e3e359;
    right: 4px;
    top: 0;
    margin: 25px auto;
    bottom: 0;
}

.pro-data .product .tit {
    font-size: 17px;
    color: #000000;
    margin: 0;
    font-weight: 600;
    font-family: 'Karla', sans-serif !important;
}

.pro-data .product .scrore {
    font-size: 17px;
    color: #00CED5;
    margin: 0;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}

.pro-data {
    margin: 35px 15px;
}

.data-list span.text-left {
    color: #5A5B5B;
    font-size: 15px;
    font-weight: 500;
}

.data-list {
    padding: 10px 0;
}

.data-list span {
    display: block;
    line-height: 35px;
}

.data-list span b {
    color: #2E2E2E;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
}

.ul-list img {
    height: 18px;
}

.ul-list {
    padding: 0;
    list-style-type: none;
}

.ul-list li {
    float: left;
    padding: 0 15px 0 0;
}

.main-pro {
    padding: 20px 0px;
}

.filter-tit {
    margin: 0;
    padding: 10px 0;
}

.feture-list .feu-tit {
    font-size: 22px;
    font-weight: 500;
    color: rgb(0, 0, 0);
}

.feture-list .feture-card .btn-secondary {
    padding: 8px 20px;
}

.feture-card {
    padding: 20px 30px;
    width: 30%;
    text-align: center;
    border: 1px solid #D6D6D6;
    box-shadow: 0px 10px 10px 0px #00000017;
}

.span-block span {
    display: block
}

.feturecard-body.span-block span,
.feturecard-body.span-block h4 {
    text-align: left;
    margin: 0;
    line-height: 30px;
}

.feturecard-body.span-block span {
    font-size: 14px;
    font-weight: 400;
    color: #5A5B5B;
}

.feturecard-body.span-block span b {
    font-size: 14px;
    font-weight: 400;
    color: #2E2E2E;
}

.feturecard-body.span-block {
    padding: 0px 0 22px;
}

.feture-card .profile {
    width: 120px;
    height: 120px;
    margin: 20px auto;
    background: #f0f7ff;
}

.feture-card .profile .div {
    margin: 27px auto;
}

.feture-card .profile:before {
    left: 13em;
}

.feture-list .pa-d {
    padding: 0px 0 40px;
}

.top-auction {
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.grid-body .pro-hd b {
    color: #404040;
    font-weight: 600;
}

.grid-body span {
    line-height: 30px;
    color: #5A5B5B;
    font-size: 15px;
    font-weight: 500;
}

.grid-body span b {
    color: #2E2E2E;
    font-size: 15px;
    font-weight: 500;
}

.grid-footer span:nth-child(1) {
    font-weight: 800;
    font-size: 15px;
}

.grid-footer span img {
    padding: 0 10px 0 0;
    height: 18px;
}

.fetu-width .buyer-grid {
    width: 30%;
}

.img-widh .buyer-grid img {
    width: 100%;
    height: 221px;
}

.select-two.sort-select {
    border: .5px solid #00000061;
}

.img-before {
    position: relative;
}

.img-before img {
    width: 100%;
    height: 265px;
    object-fit: cover;
}

.img-before .auction-id {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
}

/* Buyer Dashboard */
section.filter-body.Buyer-dash {
    margin-top: 4em;
}

.nav-dash.box-shadow {
    padding: 11px 45px;
    margin-top: 79px;
}

.nav-dash .filter-tit {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Karla', sans-serif !important;
}

.filter-body .nav-dash h3 span:before {
    width: 5px;
    height: 14px;
    left: 20px;
    top: 10px;
}

.filter-body .nav-dash figure:after {
    content: '';
    position: absolute;
    width: 28px;
    height: 28px;
    background-image: url("/public/images/icons/edit-2.svg");
    background-repeat: no-repeat;
    background-position: left;
    right: 47px;
    bottom: 3px;
    border-radius: 50px;
    border: 1px solid #7070702b;
}

.filter-body .nav-dash figure {
    position: relative;
}

.filter-body .nav-dash img {
    margin: 15px auto;
    border-radius: 100px;
    object-fit: cover;
}

.filter-body .nav-dash .ul-list li.active,
.filter-body .nav-dash .ul-list li:hover {
    color: #00CED5;
    transition: all .2s ease-in-out;
}

.filter-body .nav-dash .ul-list li.active:before,
.filter-body .nav-dash .ul-list li:hover:before {
    content: '';
    position: absolute;
    background: #00CED5;
    border-radius: 100px;
    width: 7px;
    transition: all .2s ease-in-out;
    height: 7px;
    left: -5px;
    top: 17px;
}

.filter-body .nav-dash .ul-list li {
    position: relative;
    float: unset;
    cursor: pointer;
    padding: 8px;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Karla', sans-serif !important;
}

.nav-profile img.img-responsive {
    width: 40px;
    height: 40px;
}

.pad-8 {
    padding: 8px !important;
}

.content-main .tab-connect {
    display: flex;
    justify-content: space-between;
}

.content-main .tab-connect .nav-tabs li {
    padding: 0 5px;
}

.content-main .tab-connect .nav-tabs li:nth-child(1) {
    padding-right: 40px;
    padding-left: 0;
}

.content-main .tab-connect .nav-tabs li:nth-child(3) {
    padding-left: 40px;
    padding-right: 0;
}

.content-main .tab-connect .nav-tabs li:nth-child(4) {
    padding-left: 40px;
    padding-right: 0;
}

.content-main .tab-connect .nav-tabs {
    border-bottom: 1px solid #000;
    padding: 10px 0;
    /* width: 35%; */
}

.content-main .tab-connect .nav-tabs li a {
    color: rgb(0, 0, 0);
    font-size: 16px;
    cursor: default;
    padding: 0px 0px 0px 0px;
    background-color: #fff;
    border: 1px solid #ddd0;
    border-bottom-color: transparent;
    font-weight: 600;
    margin: 0;
}

.content-main .tab-connect .nav-tabs li.active a {
    color: #00CED5;
    font-size: 16px;
    cursor: default;
}

.bids-data img {
    height: 250px;
    object-fit: cover;
    width: 100%;
}

.award-img .btn-secondary {
    padding: 10px 30px;
}

.award-img img.img-responsive {
    height: 65px;
    width: 100%;
    object-fit: contain;
    margin: 15px 0px;
}

.bids-details span {
    line-height: 35px;
    color: #000;
    font-size: 15px;
}

.bids-data {
    border: 1px solid #C9C3C3;
    border-right: 2px solid #00ced5;
    overflow: hidden;
}

.bids-details {
    padding: 10px 0px;
    line-height: 70px;
}

.bids-tit span {
    letter-spacing: 1pt;
}

.pad-left-0 {
    padding-left: 0;
}

.flex-height {
    height: 104px;
}

.flex-hgt {
    height: 150px;
}

.bids-data {
    margin: 30px auto;
}

.site-input {
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    height: 48px;
    font-family: 'Karla', sans-serif !important;
}

.site-textarea {
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000001f;
    color: #9E9E9E;
    resize: none;
    font-family: 'Karla', sans-serif !important;
}

.site-form label {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}

.prc-rang .form-group {
    width: 100%;
    box-shadow: 0px 3px 10px 0px #0000001f;
}

.prc-rang .site-input {
    border-right: 0;
    box-shadow: none;
}

.prc-rang .form-group label {
    padding: 7px 20px;
    font-size: 24px;
    font-weight: 800;
    color: #BCB9B9;
}

.prc-rang .form-group.sha-none {
    box-shadow: none;
}

.prc-rang .input-group-addon:last-child {
    background: white;
    box-shadow: none;
    border: 1px solid #E2E2E2;
    border-radius: 2px;
    font-size: 20px;
    color: #BCB9B9;
    font-weight: 800;
    border-left: 0;
}

/* Prodct View Page */
.slider .owl-carousel .owl-item img {
    height: 350px;
    min-height: 350px;
    object-fit: cover;
}

.slider-two .owl-carousel .owl-item img {
    object-fit: cover;
}

section.carosal-product {
    border-radius: 3px;
    width: calc(100% - 20px);
    display: inline-block;
    height: auto;
    font-family: 'Source Sans Pro', sans-serif;
}

section.carosal-product .all {
    width: 100%;
    height: auto;
    display: inline-block;
}

section.carosal-product .all .slider {
    width: auto;
    height: auto;
    overflow: hidden;
    border-radius: 3px;
    position: relative;
}

section.carosal-product .all .slider .owl-nav,
section.carosal-product .all .slider .owl-dots {
    display: none;
}

section.carosal-product .all .slider:hover .left {
    visibility: visible;
    opacity: 1;
    left: 20px;
}

section.carosal-product .all .slider:hover .right {
    visibility: visible;
    opacity: 1;
    right: 20px;
}

section.carosal-product .all .slider .item-box {
    /* padding: 20px; */
    overflow: hidden;
    width: 100%;
    height: 350px;
    position: relative;
    border-radius: 3px;
    /* background: no-repeat url(http://www.cyw51.com/file/upload/201302/22/18-49-19-74-127.jpg) center center; */
    background-size: cover;
}

section.carosal-product .all .slider .item-box:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    content: "";
    display: block;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000); */
}

/* section.carosal-product .all .slider .left {
    position: absolute;
    z-index: 1;
    top: 167px;
    left: -20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: left ease .25s;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    background: url(http://svgshare.com/i/2V9.svg) top left;
  }
  section.carosal-product .all .slider .left:hover {
    visibility: visible;
    opacity: 1;
    left: 20px;
    background: url(http://svgshare.com/i/2UW.svg) top left;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  } */
section.carosal-product .all .slider .left.nonl {
    left: -20px;
    opacity: 0;
    visibility: hidden;
}

/* section.carosal-product .all .slider .right {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 167px;
    right: -20px;
    width: 40px;
    height: 40px;
    visibility: hidden;
    opacity: 0;
    transition: right ease .25s;
    background: url(http://svgshare.com/i/2V9.svg) top left;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  section.carosal-product .all .slider .right:hover {
    visibility: visible;
    opacity: 1;
    right: 20px;
    background: url(http://svgshare.com/i/2UW.svg) top left;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  } */
section.carosal-product .all .slider .right.nonr {
    right: -20px;
    opacity: 0;
    visibility: hidden;
}

section.carosal-product .all .slider-two {
    width: auto;
    height: auto;
    overflow: hidden;
    position: relative;
}

section.carosal-product .all .slider-two .owl-nav,
section.carosal-product .all .slider-two .owl-dots {
    display: none;
}

section.carosal-product .all .slider-two .left-t {
    visibility: visible;
    opacity: 1;
    left: 3px;
}

section.carosal-product .all .slider-two .right-t {
    visibility: visible;
    opacity: 1;
    right: 3px;
}

section.carosal-product .all .slider-two .item {
    transition: all ease .25s;
    height: 100px;
    border-radius: 3px;
    /* border: solid 3px #e6ecf2; */
    cursor: pointer;
    /* background: no-repeat url(http://www.cyw51.com/file/upload/201302/22/18-49-19-74-127.jpg) center center; */
    background-size: cover;
}

section.carosal-product .all .slider-two .item.active {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    /* border: solid 3px #00ced5 */
}

section.carosal-product .all .slider-two .owl-stage {
    padding: 20px 40px;
    width: 100% !important;
}

section.carosal-product .all .slider-two .left-t {
    /* -webkit-transform: rotate(180deg);
            transform: rotate(180deg); */
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 60px;
    visibility: hidden;
    opacity: 0;
    transition: all ease .25s;
    width: 20px;
    border-radius: 50px;
    height: 20px;
    background: no-repeat #00ced5 url(/public/images/icons/left-arrow.svg) center center;
    left: 8px;
}

section.carosal-product .all .slider-two .left-t.nonl-t {
    left: 8px;
    opacity: 1;
    visibility: unset;
}

section.carosal-product .all .slider-two .left-t:hover {
    visibility: visible;
    opacity: 1;
    left: 3px;
}

section.carosal-product .all .slider-two .right-t {
    background: no-repeat rgba(204, 46, 56, 0.85) url(/public/images/icons/right-arrow.svg) center center;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 60px;
    right: 8px;
    visibility: unset;
    opacity: 1;
    transition: all ease .25s;
    width: 20px;
    border-radius: 50px;
    height: 20px;
    background-color: #00ced5;
}

section.carosal-product .all .slider-two .right-t:hover {
    visibility: visible;
    opacity: 1;
    right: 3px;
}

section.carosal-product .all .slider-two .right-t.nonr-t {
    right: -40px;
    opacity: 0;
    visibility: hidden;
}

/* Prodct View Page */
.pro-pagi h5 {
    font-size: 14px;
    color: #C5C5C5;
    font-weight: 400;
}

.pro-pagi h5 b {
    font-size: 14px;
    color: #878585;
    font-weight: 800;
}

.product-hd h4 {
    font-size: 22px;
}

.product-hd .btn-secondary {
    padding: 10px 20px;
}

.product-hd {
    border-bottom: 1px solid #E8E8E8;
    padding: 0 0 18px 0px;
}

.proper-data span b {
    color: #000;
    font-weight: 500;
}

.proper-data span {
    line-height: 35px;
    font-family: 'Karla', sans-serif !important;
    font-size: 18px;
    font-weight: 500;
    color: #757575;
}

.fancy-btn button {
    position: absolute;

}

.fancy-btn {
    padding: 6px 0px 35px 0;
    border: 1px solid #00CED5;
    width: 80%;
    background: #EFFFFF;
    border-radius: 4px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
}

.fancy-btn button {
    position: absolute;
    left: auto;
    right: auto;
    bottom: -17px;
    text-align: center;
}

.twin-bath span {
    padding: 0 22px 0px 0;
    position: relative;
}

.twin-bath span:nth-child(1):after {
    border-right: 1px solid #DFDFDF;
    content: '';
    position: absolute;
    width: 1px;
    top: 10px;
    right: 7px;
    height: 14px;
    background: #000000;
}

.proper-data.span-block {
    padding: 20px 0;
}

.product-timer img.img-responsive {
    padding: 0 10px;
}

.product-timer.primary.pad-10.text-center {
    margin: 0 0 20px 0px;
}

.range-slider span.range {
    font-size: 15px;
    color: #9E9E9E !important;
    padding: 0px 17px;
    font-weight: 500;
}

.range-slider {
    display: flex;
    justify-content: space-between
}

.buyer-img img {
    height: 205px;
}

.pro-card-hgt.buyer-img {
    height: 205px;
}

/* Seller Dashboard */

.pro-card-hgt {
    height: 231px;
    overflow: hidden;
}

/* Seller Dashboard */
/* Priec range */
.price-range #slider {
    width: 65%;
    background: #00CED5;
    height: 4px;
    border-radius: 0;
    border: none;
    margin: 9px auto;
}

.price-range .value {
    position: absolute;
    top: 20px;
    left: 50%;
    margin: 0 0 0 -20px;
    width: 40px;
    text-align: center;
    display: block;
    font-weight: normal;
    font-family: Verdana, Arial, sans-serif;
    font-size: 14px;
    color: #333;
}

.price-range .price-range-both.value {
    width: 100px;
    margin: 0 0 0 -50px;
    top: 26px;
}

.price-range .price-range-both {
    display: none;
}

.price-range .value i {
    font-style: normal;
}

.price-range body div.ui-slider-range.ui-widget-header {
    background: #00ced5;
}

.price-range .ui-state-hover,
.price-range .ui-widget-content .ui-state-hover,
.price-range .ui-widget-header .ui-state-hover,
.price-range .ui-state-focus,
.price-range .ui-widget-content .ui-state-focus,
.price-range .ui-widget-header .ui-state-focus {
    background: #2ecaf9 !important;
}

.price-range .ui-state-default,
.price-range .ui-widget-content .ui-state-default,
.price-range .ui-widget-header .ui-state-default {
    background: #5892D8 !important;
    border-radius: 100%;
    border: none !important;
    padding: 4px;
    width: 15px;
    height: 15px;
}

.price-range .ui-widget-header {
    background: #00CED5 !important;
}

.price-range .range-slider .ui-slider-horizontal .ui-slider-handle {
    top: -0.4em;
    margin-left: -.6em;
}

.price-range span.ui-slider-handle.ui-corner-all.ui-state-default:focus {
    outline: none;
}

.price-range .range-slider span {
    color: #faad39;
}

/* Priec range  End */

/* 19/6/2019 */
/* .com-ty .list-group{
    background-color: #fff;
} */
.list-group-item{
    background-color: #fff0;
}
.sell-limity img{
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 213px;
    border: none!important;
}
.sell-limity span .fa-arrow-right:before{padding: 0px 15px;}
.sell-limity span{
    color: #00CED5;
}
.sell-limity p{
    font-size: 14px;
    font-weight: normal;
    color: #747474;
    line-height: 28px;
    letter-spacing: 1px;
}
.sell-limity h4{
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    color: #5A5454;
    line-height: 28px;
    padding: 10px 0;
}
.sell-limity .b4-img{
    position: relative;
}
.owl-you{
    margin: 15px;
}
.limity-body{
    padding: 10px 20px;
}
.sell-limity span{
    position: absolute;
    /* background-image: url(../../../public/images/pro-5.png);
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    right: 0; */
}
.sell-limity{
    min-height: 500px;
    height: 500px;
    color: #ccc;
    width: 50%;
    background: white;
    margin: 15px;
    box-shadow: 0px 2px 12px 4px #0000000f;
}
.limity-body p {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 140px;
    min-height: 140px;
    flex-wrap: nowrap;
}
.you-tube img{
    height: 213px;
    width: 100%;
    object-fit: cover;
    border: none!important;
}
.you-tube .b4-img,.sell-limity .b2-img,.sell-limity .b3-img{
    position: relative;
}
.you-tube .b4-img span.iim{
    position: absolute;
    background-image: url(/images/youtube.svg);
    height: 65px;
    width: 85px;
    top: 38%;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
    transform: translate(150%);
}
.sell-limity .b2-img span.iim{
    position: absolute;
    background-image: url(/images/b2-img.svg);
    height: 65px;
    width: 100%;
    bottom: -5px;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
}
.b4-img:after{
    position: absolute;
    content: '';
    background: #000000;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
}
.sell-limity .b3-img span.iim{
    position: absolute;
    background-image: url(/images/b3-img.svg);
    height: 85px;
    width: 100%;
    background-size: cover;
    bottom: -10px;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
}
.bg-light{
    background: #EEFCFE;
}
.you-tube h4{
    font-size: 16px;
    font-weight: 500;
    color: #707070;
    text-align: center;
}
.home-subscribe {
    background: #00ced5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
}
.home-subscribe .News-letter h4 {
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    text-transform: uppercase;
  
}
.home-subscribe  .navbar-form .form-control {
    padding: 18px;
    border: 1px solid #fff;
    margin-right: 5px;
}
.home-subscribe  button:hover {
    background: rgb(255, 255, 255);
    color: #000000;
}
.home-subscribe  button {
background: #077B8E;
}
.home-subscribe .News-letter p {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    margin: 0;
    margin-bottom: 20px;
}
/* 27/6/2019 */
.Home-form .btn-primary{
background: #faad39;
}
.Home-form textarea{
    resize: none;
}
.Home-form h4{
    color: #088397;
    font-size: 30px;
    font-weight: 400;
    margin-bottom:30px;
}
.client-cmt{
    position: relative;
    height: 600px;
}
.client-cmt:after{
    content: '';
    background-image: url(/images/client-circle.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: right;
    height: 100em;
    width: 100%;
    position: absolute;
    left: 33%;
    bottom: -65%;
    z-index: -1;
    opacity: 1;
}
.client-card{
    background: #fff;
    box-shadow: 0px 10px 11px 7px #00000008;
    padding: 45px 25px;
    width: 30%;
    position: relative;
}
.client-cmt h2{
    margin: 40px auto;
}
.client-card:after{
    position: absolute;
    content: '';
    background: #00CED5;
    width: 50%;
    height: 5px;
    bottom: 0;
    left: 0;
}
.star i.fa.fa-star {
    color: #FFAB1A;
    letter-spacing: 8px;
}
.client-card p {
    padding: 15px 0;
    line-height: 24px;
    font-size: 16px;
    color: #B1AAAA;
    font-weight: 500;
}
.client-card b{
    font-size: 16px;
}
.client-card img {
    width: 53px;
    height: 53px;
    border-radius: 60px;
}
.client-card .snd-card p span{
    color: #000;
}
.client-card .snd-card p{
    padding: 0px 0;
}
.snd-card {
    padding-left: 25px;
}
.client-cmt .nav-dots{
    text-align: center;
    margin: 55px auto;
}
.client-cmt .nav-dots .c-1.active{
background: #088397
}
.client-cmt .nav-dots .c-1{
    background: #00ced5;
    border-radius: 60px;
    padding: 0px 8px;
    margin: 8px;
}
.bg-sf-light{
    background: #00ced514;
}
/* .our-client {
    transform: translate(50%,50%)
} */


.search_btn {
    display: flex;
    justify-content: center;
}
.toggle.respond,
[id^=drop] {
	display: none;
}
/* 16/07/2019 */
.sp_banner .banner-bt button{
    font-weight: 600;
}
.sp_banner .banner-bt{
    /* padding: 0 4%; */
}
.sp_banner .banner-bottom button{
    font-weight: 600;
    margin: 3%;
}
.hiw .hiw-1 .hiw-box .hiw-links button,.hiw .hiw-2 .hiw-box .hiw-links button,.hiw .hiw-3 .hiw-box .hiw-links button{
    font-size: 17px;
}
.rt .btn-secondary,.lt .btn-secondary{
    padding: 10px 16px;
}
.rt .btn-secondary:hover,.lt .btn-secondary:hover{
   background: #00ced5;    border: 1px solid #00ced5;
}
.rt .btn-primary,.lt .btn-primary {
   padding: 9px 15px;
}
.Home-form .btn-primary.btn-block{
    padding: 24px;
    box-shadow:0px 4px 12px 6px #61616136;
    font-size: 16px;
}
.services .serv-data span h2{
    font-size: 55px;
    margin: 0;
    color: #faad39;
    font-family: -webkit-body;
}
.serv-data:hover span h2{
    transition: 0.5s all ease-in;
}
section.feture-list {
    margin-top: 50px;
}
/* 26/08/19 */
.client-review{
    font-weight: 600;
}
/* 27/08/19 SELCT SEC */

.select-sec{
    margin-bottom: 16em;
}
.select-sec .sec-divided{
    background: #FFF;
    box-shadow: 0px 0px 13px 0px #56565636;
    border-radius: 6px;
    padding: 10px 35px;
    z-index: 999;
    position: absolute;
    bottom: -85px;
    width: 75%;
    margin: auto;
    left: 0;
    right: 0;
}
.posi-relative{
    position: relative;
}
.sec-divided-flex span p{
    font-size: 20px;
    font-weight: 800;
    color: #00ced5;
}
.sec-divided-flex span{
    text-align: left;
    width: 22%;
}
.sec-divided-flex{
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.sec-divided-flex span h4{
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
}
.divion-button{
    text-align: center;
}
.divion-button button:hover{
    background: #faad39;
    border: 1px solid #faad39;
    box-shadow: none;
}
.divion-button button{
    margin: 10px auto;
    border-bottom: 6px solid #e7a33db8;
    font-weight: 600;
}
.divion-button p{
    font-size: 18px;
    font-weight: 700;
    color: #faad39;
}
/* 3/9/19 */
.close-icon {
    padding: 0px 5px;
    color: #cfcfcf;
    font-size: 18px;
    cursor: pointer;
}
.close-icon{
    display: block;
}
/* 6/9/19 */
.bg-wht{
    background: white;
    border-radius: 4px;
    box-shadow: 8px 8px 10px 3px #8f8f8f0d;
    padding: 5px 15px;
    width: 450px;
}
.w-45{
    width: 45px;
}
/* 16/10/19 */
video {
    max-width: 700px;
    width: 700px;
    border: 5px solid #00ced524;
    border-radius: 8px;
    height: 350px;
  }
  .rt .btn-secondary, .lt .btn-secondary{
    padding: 10px 35px;
  }
  .identify-diff .D-card .card-fot .services-bt h4{    background: transparent;
    color: #3d3d3d;
    font-weight: 600!important;}
  .identify-diff .D-card .card-fot .services-bt{margin: 20px auto;}
  .identify-diff .D-card {width: 270px;}
  .identify-diff .D-card .card-body ul li.D-fot{
    color: #003d3d;
    font-size: 15px;
    margin-top: 0px;
    font-weight: 600;
   }
   .list{list-style-type: none!important;
   }
   /* .identify-diff .D-card .card-body ul li:before {
    content: "\2022";
    color: red;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
   } */
   .identify-diff .D-card .card-body ul li b {
    margin-right: 8px;   
    color: #006845;
    }
   .identify-diff .D-card .card-body ul li {
    line-height: 22px;
    margin: 8px 0;
    list-style-type: none;
    font-size: 14px;
    height: 50px;font-weight: 500;
    display: flex;
    color:#003d3d;
   }
   
   .identify-diff .D-card h4 {
    text-align: center;
    padding: 15px;
    margin: 0;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    background: #00ced5;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
   }
   
   .identify-diff .D-card .card-body ul li:nth-child(1){
       height: 40px;
   }
   .identify-diff .D-card .card-body {
       background: #18e1e87a;
       min-height: 320px;
       height: 320px;
       border-radius: 10px;
       border: 1px solid #18e1e87a;
       border-top: none;
       border-top-left-radius: 0;
       border-top-right-radius: 0;
   }
   
   .identify-diff .D-card .card-body ul {
       list-style-type: none;
       padding: 15px 10px;
       font-family: 'Karla', sans-serif !important;
       color: #ffffff;
       font-weight: 600;
       font-size: 16px;
       padding-right: 10px;
   }
   .color_back{
    background: white !important;
    }
    .feature-cnt{
        padding-bottom: 100px;
        padding-top: 6em;
    }
    .serv-data {
        background:#00ced5;
        padding: 5px;
        border-radius: 10px;
        min-height: 170px;
        height: 180px;
        margin: 15px;
        width: 30%;
    }
/* 31/10 */

.card-fot button,.learn-video button{
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    padding: 9px 33px!important;
}
.text-center.static-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}
























/* Responsive */
@media only screen and (max-width: 1264px) {
    .client-cmt {
        display: block!important;
        overflow: hidden;
    }
}

@media only screen and (max-width: 1024px) {
    .select-sec .sec-divided{
        top: -60px;
        bottom: unset;
    }
    .hiw-links .col-sm-1{
        display: none;
    }
    .client-cmt:after{
        display: none;
    }
    .sp_banner .main-h{
        font-size: 24px!important;
    }
    .hiw .hiw-1 ul li,.hiw .hiw-2 ul li,.hiw .hiw-3 p{
        font-size: 15px!important;
    }
    .googly-img img {
        height: 100%;
        object-fit: cover;
    }

    .width-400 {
        display: none;
    }

    .width-300 {
        width: 300px !important;
    }
    .you-tube .b4-img span.iim{
        transform: translate(130%);
        -webkit-transform: translate(130%);
        -moz-transform: translate(130%);
        -ms-transform: translate(130%);
        -o-transform: translate(130%);
    }
    .hiw .hiw-2 .hiw-box .hiw-links a{
        font-size: 14px;
    }
    .hiw .hiw-1 .hiw-box,.hiw .hiw-3 .hiw-box,.hiw .hiw-2 .hiw-box {
        padding-left: 36px!important;
        margin-top: 35px!important;
    }
    .sell-limity .b3-img span.iim{
        bottom: -31px;
    }
}

@media only screen and (max-width: 991px) {
    .blog-list .sell-limity:nth-child(3),.client-sec .client-card:nth-child(1){
        display: block;
    }
    .client-sec .client-card{
        width: 50%!important;
    }
    .sp_banner .banner-box{
        width: 65%!important;
        right: 127%!important;
    }
    .select-sec .sec-divided{
        padding: 25px 15px;
    }
    .sec-divided-flex span h4 {
        font-size: 14px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 990px) {
    .serv-data {
        text-align: center;
    }
    .serv-data {width: unset;}
    .services .serv-data span::after{
        left: 35%;
    }
    .you-tube {
        float: left;
        width: 50%;
    }
    .sp_banner .ban-lin-inner {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-flow: column;
        align-content: center;
        align-items: center;
    }
    .sp_banner .ban-lin-cnt .divider{
        display: none;
    }
}



@media only screen and (max-width: 768px) {
    .sp_banner .main-h {
        margin-top: 20%!important;}
        .sp_banner .main-h h1{
            font-size: 26px!important;
            text-align: center!important;
        }
        .sp_banner .main-h h3{
            font-size: 22px!important;
            text-align: center!important;
        }
    .sp_banner .banner-box{
        transform: translate(208%, 40%)!important;
    }
    .you-tube {
        float: left;
        width: 48%;
        margin: 0 5px;
    }    
    .you-tube .b4-img span.iim{
        -webkit-transform:translate(150%);
        -moz-transform:translate(150%);
        -ms-transform:translate(150%);
        -o-transform:translate(150%);
    }
    /* .services-cnt .serv-tit h2{
        font-size: 18px!important;
    } */
    .services-cnt .serv-tit h4{
        font-size: 14px!important;
    }
    .serv-data {
        text-align: center;
    }
    .services .serv-data span::after{
        left: 35%!important;
    }
    .sp_banner .ban-lin-cnt{
        margin-top: 0px;
    }
    .sp_banner .ban-lin-inner{
        justify-content: flex-start;
        padding: 9px;
        margin: auto;
    }
    /* toggle */


	/* Stylinf the toggle lable */
	.toggle.respond {
		display: block;
		background-color: transparent;
		padding:14px 20px;	
		color:#FFF;
		font-size:17px;
		text-decoration:none;
        border:none;
        width: 75%;
        margin: auto;
	}

	.toggle.respond:hover {
		background-color: transparent;
	}

	/* Display Dropdown when clicked on Parent Lable */
	[id^=drop]:checked + .ban-lin-cnt {
        display: block!important;
        position: absolute;
        width: 94%;
        z-index: 999;
    }
    .toggle.respond + a,
	.ban-lin-cnt {
		display: none!important;
	}
    /* toggle */
    .navbar-default .navbar-collapse,
    .navbar-default .navbar-form {
        border-color: #e7e7e700;
    }

    .navbar-default .navbar-toggle {
        border-radius: 0px;
        border-color: #fff;
    }

    .navbar-default .navbar-toggle .icon-bar {
        background-color: #fff;
    }

    .navbar-default .navbar-toggle:focus,
    .navbar-default .navbar-toggle:hover {
        background-color: #00ced5;
    }


    .alpha-form {
        padding: 100px 0;
    }

    .News-letter {
        text-align: center;
    }

    .footer-above form.navbar-left {
        float: unset !important;
        text-align: center
    }

    .flex.justify-around {
        justify-content: space-evenly;
        padding: 35px 0;
        padding-bottom: 0;
    }

    .show-fotter .foot-bg {
        background-size: cover;
    }
    .sp_banner .ban-lin-inner{
        width: 75%;   
        text-align: center;
        grid-column-gap: 23px;
        grid-row-gap: 10px;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        background: #00ced5;
    }
    .sp_banner .ban-lin-cnt .divider{
        display: none;
    }
    /* 26/7/19 */
    .hiw .hiw-tit h2,.blog-cnt .blog-tit h2,.client-review{
        font-size: 24px!important;
    }
    .hiw .hiw-tit h4 {
        font-size: 22px!important;}
    .hiw .hiw-1 .hiw-box .hiw-links button, .hiw .hiw-2 .hiw-box .hiw-links button, .hiw .hiw-3 .hiw-box .hiw-links button {
        font-size: 15px;
    }
    .hiw .hiw-1 .hiw-box .hiw-links a, .hiw .hiw-2 .hiw-box .hiw-links a, .hiw .hiw-3 .hiw-box .hiw-links a {
        font-size: 14px!important;    padding-left: 5px!important;}
    .row.hiw-1 {
        padding-bottom: 30px;
    } 
    .hiw-links .col-sm-6:nth-child(1){
        padding: 0;
    }   
}

@media only screen and (max-width: 767px) {
    .sp_banner .main-h{
        margin-top:35%!important;
    }
    .sp_banner .main-h h3{
        font-size: 20px;
        text-align: left!important;
    }
    .sp-banner .banner-box {
        padding: 0;
    }
    .sp-banner .main-p {
        margin: 15px auto;
    }
    .sm-pd{
        padding:0px;
    }
    .w-res-mode{
        width:65%;
    }
    .sp_banner .main-h h1{
        font-size: 24px;
        text-align: left!important;
    }
    .sp_banner .main-h{
        margin: auto;
        line-height: 35px;
    }
    .checklist{
        flex-direction: column;
    }
    .Con-lft{
        height: 300px!important;    padding: 0% 10%;}
        .Home-form:after{
            height: 300px!important;    width: 100%!important;
        }
        .Con-lft h2 {
            padding-right: 0%!important;
            padding-bottom: 10px!important;}
    a.C-a {
        color: #fff;
        font-weight: 600;
        padding: 15px;
        font-size: 16px;
        text-align: center;}
    .C-a{display: block!important;}
    .res-call{display: none!important;}
    .identify-diff{flex-flow: column;}
    .footer-above .flex.justify-around{
        flex-direction: row;
    }
    .identify-diff .D-card{margin: auto;}
    .close-icon{
        display: block;
    }
    .profile.head{
        margin: 0;
    }
    .select-sec .sec-divided{
        /* display: none; */
    }
    .sp_banner .banner-box{
        position: relative;
        transform: translate(220%, 40%)!important;
    }
    .services .serv-data span::after {
        left: 45%!important;
    }
    [id^=drop]:checked + .ban-lin-cnt {
        width: 96%;
    }
}

@media only screen and (max-width: 610px) {
    video{
        width: 100%;
        max-width: 100%;
    }
    .sp_banner .main-h h3,
    .sp_banner .main-h h1{
        text-align: left!important;
        padding-left:5px;
    }
    .sp_banner .banner-box{
        transform: translate(220%, 0%)!important;
        padding:5px 0;
    }
    .client-sec .client-card{
        margin-bottom: 30px;
        width: 100%;
    }
    .client-sec{
        flex-flow: column;
    }
    .client-cmt {
        height: auto;
    }

    .identify-diff .D-card{margin: auto;}
    .blog-list .sell-limity:nth-child(3),.blog-list .sell-limity:nth-child(2),
    .client-sec .client-card:nth-child(1),.client-sec .client-card:nth-child(2)
    {
        display: block;
    }
    .client-sec .client-card{
        width: 100%!important;
    }
    [id^=drop]:checked + .ban-lin-cnt {
        width: 95%;
    }
    .you-tube {
        float: none;
        width: 100%;
    } 
    .you-tube .b4-img span.iim{
        -webkit-transform:translate(200%);
        -moz-transform:translate(200%);
        -ms-transform:translate(200%);
        -o-transform:translate(200%);
    }
    .Home-form{
        background-image: none!important;
    }
    .sell-limity .b3-img span.iim{
        height: 138px;
    }
    .fetu-width .buyer-grid{
        width: 100%;
    }
    .sell-limity{
        width: 95%;
    }
    .blog-cnt .flex{
        display: block!important;
    }
    .sell-limity .b2-img span.iim{
        background-size: cover;
        height: 100px;
        bottom: -17px;
    }
}

@media only screen and (max-width: 500px) {
    .sp_banner .main-h h3{
        font-size: 16px;
        text-align: left!important;
    }
    .sp_banner .main-h h1{
        font-size: 20px;
        text-align: left!important;
    }
    .main-flex button.btn-primary{
        padding: 6px 18px;
        margin: 0 4px 0;
        font-size: 16px;
    }
    .flex.soci{
        justify-content: flex-start!important;
    }
    .foot-link ul{
        text-align: left;
    }
    .identify-diff .D-card{
        width: 100%;
    }

    .sp_banner .banner-box {
        width: 90%!important;
        right: 192%!important;
    }
    
    .select-sec .sec-divided.buy{
        top: -323px;
    }
    .select-sec .sec-divided.sell{
        top: -215px;
    }
    .select-sec .sec-divided{
        position: absolute;
        margin: auto;
        width: 90%;
        left: 0;
        right: 0;
    }
    .sec-divided-flex{
        flex-direction: column;
    }
    .sec-divided-flex span {
        width: 100%;
    }
    .sp_banner .main-h{
        font-size: 18px!important;
        line-height:30px!important;
    }
    .serv-data {
        text-align: center;
    }
    .services .serv-data span::after{
        left: 42%!important;
    }
    /* .services-cnt .serv-tit h2{
        font-size: 18px;
    } */
    .services-cnt .serv-tit h4{
        font-size: 17px!important;
    }
    /* .blog-cnt .blog-tit h2{
        font-size: 18px!important;    
    } */
    .services-bt.flex{
        margin: 20px auto;
    }
    .services-bt .lt{
        /* margin-right: 0!important; */
    }
    .services-bt.ss .lt{
        margin-right: 0!important;
    }
    .services-bt .rt {
        margin-left: 0px!important;
    }
    .elty {
        width: 100%;
    }

    .width-300 {
        width: 100% !important;
    }

    .width-400 {
        display: block;
    }

    .foot-link.ft-after:before {
        display: none;
    }

    .foot-link.ft-before:before {
        display: none;
    }

    .footer-above form.navbar-left {
        box-shadow: none;
        border: none;
    }
    .rt .btn-secondary, .lt .btn-secondary,.rt .btn-primary, .lt .btn-primary {
        padding: 10px 46px;
        font-size: 14px;
        margin: 0px 10px;
    }
}

@media only screen and (max-width: 475px) {
    .w-res-mode{
        width:90%;
    }
    .res-pd{
        padding:0 10px;
    }
    .sp-banner{
        background-position: 57%;
    }
    .sp_banner .main-h h3{
        font-size: 18px;
        text-align: left!important;
    }
    .sp_banner .main-h h1{
        font-size: 22px;
        text-align: left!important;
    }
    .sp_banner .main-p{
        font-size: 15px!important;
    }
    .main-flex button.btn-primary{
        padding: 6px 18px;
        margin: 0 4px 0;
        font-size: 14px;
        width:100px;
    }
    .w-res-mode{
        width:80%;
    }
    .foot-link.ft-after.flex.justify-between {
        flex-flow: column;
        align-items: center;
        margin-bottom: 20px;
    }
    .flex.justify-between.main-fle {
        flex-direction: row;
    }
    .foot-link ul.pr-30 {
        padding-right: 30px;
        width: 200px;
    }
    .d-smn{height: 25px;
        display: none;
    }
    .mb-xs5{
        margin-bottom: 10px!important;
    }
    .sp_banner .main-p{   font-size: 18px!important;}
    .services-bt.flex,.main-flex{
        flex-flow: column;
    }
    .learn{
        display: none;
    }
    .services .serv-data h4,.services .serv-data p{
        text-align: left;
    }
    .L-a{font-size: 14px;
        color: #000;
        font-weight: 700;
        text-align: left;
        margin: 5px 8px;}
    .hidden.L-a{display: block!important;}
    .sp_banner .main-h{
        margin-top: 55%!important;
    }
    .sp_banner .main-h h1{font-size: 28px!important; text-align: left!important;}
    .sp_banner .main-h h3{font-size: 18px!important; text-align: left!important;}

    [id^=drop]:checked + .ban-lin-cnt {
        width: 94%;
    }
    /* .hiw .hiw-tit h2 {
        font-size: 18px!important;
    } */
    .hiw .hiw-tit h4 {
        font-size: 16px!important;
    }
    /* .hiw .hiw-tit{
        padding-bottom: 0!important;
    } */
    /* }  */
    .sp_banner .main-h h1,.sp_banner .main-h h3{
        padding-left: 0;
        padding-right: 0;
    }
    
}

@media only screen and (max-width: 425px) {
    .sp-banner .main-h{
        margin-top:70%!important;
    }
    .sp-banner .main-p,
    .sp-banner .main-h h1,
    .sp-banner .main-h h3{
        margin:5px auto;
    }
    .w-res-mode{
        width:65%;
    }
    .foot-link ul.pr-30{
        padding: 0;}
        .foot-link ul.pr-30 {
            padding-right: 30px;
            width: 160px;
        }
        .d-b{
            display:block!important
        }
    .footer-above .flex.justify-around {
        flex-direction: column;
    }
    .toggle.respond.filter{
        margin: 19% auto!important;
        margin-bottom: 5%!important;
    }
    [id^=drop]:checked + .ban-lin-cnt {
        width: 93%;
    }
    .you-tube .b4-img span.iim{
        -webkit-transform: translate(165%);
        -moz-transform: translate(165%);
        -ms-transform: translate(165%);
        -o-transform: translate(165%);
    }
}

@media only screen and (max-width: 375px) {
    .sp_banner .main-h h3, .sp_banner .main-h h1{
        padding-left: 0;
    }
    .w-res-mode{
        width:68%;
    }
    .main-flex{
        margin-left: auto!important;
    }
    .foot-link ul.pr-30 {
        padding-right: 30px;
        width: 140px;
    }
    .sp_banner .main-p{
        margin: 5px 0px!important;
    }
    .snow-header a.navbar-brand {
        font-size: 15px;
    }
    a.C-a {
        font-size: 14px!important;}
    /* .sp_banner .banner-box{
        transform: translateY(20%)!important;
    } */
    [id^=drop]:checked + .ban-lin-cnt {
        width: 92%;
    }
    .you-tube .b4-img span.iim{
        -webkit-transform: translate(115%);
        -moz-transform: translate(115%);
        -ms-transform: translate(115%);
        -o-transform: translate(115%);
    }
    .services .serv-data span::after {
        left: 38%!important;
    }
    .foot-bg.pad-30.pad-5 {
        padding: 5px;
    }

    .News-letter h4 {
        font-size: 18px;
    }

    .News-letter p {
        font-size: 14px;
    }

    .foot-link li {
        font-size: 12px;
    }

    .foot-link .social ul li {
        font-size: 13px;
        padding: 3px 5px;
    }

}

@media only screen and (max-width: 425px) {

    /* .pad-55.elty{
        padding: 30px;
        padding-top: 20px;
    } */
    .navbar-default .navbar-nav.header-right > li.divider {
        background: white;
        height: 2px;
        padding: 0px;
        width: 93%;
        margin: auto;
        margin-top: 0;
    }
 
    .navbar-default .navbar-nav.header-right > li > a {
        padding: 10px 20px;
    }
}

/* End Responsive */

/* chceklist design */
    .checklist ul li.list-btn button{
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        padding: 9px 33px!important;
    }
    .checklist ul li.list-btn{
        text-align: center;
        margin: 20px auto;
    }
    .checklist ul.complete li.list-btn::before{
        content: '';
    }
    .checklist {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .checklist ul {
        list-style: none;
        padding: 15px;
        box-shadow: 0px 0px 6px 0px #00000024;
        background: hsla(182, 100%, 42%, 0.04);
        border-radius: 8px;
    }
    .checklist ul h5{
        text-align: left;
        font-size: 24px;
        font-weight: 600;
        text-transform: capitalize;
    }
    .checklist ul li {
        font-size: 18px;
        line-height: 38px;
        font-weight: 500;
    }
  
    .checklist  ul.complete li::before {
        content: '✔';
        font-weight: 600;
        color: #faad39;
        padding-right: 10px;
    }
    
    .checklist  ul.incomplete li::before {
        content: '☐ ';
    }
    .pb-3{
        padding-bottom: 3em;
    }
    
/* chceklist design end */