.uni-footer-contact-links {
  display: flex;
  justify-content: flex-end; 
  flex: 1;
}
.footer-contact a {
  color: #212121;
  text-decoration: none;
  padding-left: 40px;
  padding-top: 15px;
}
.uni-footer .container {
  max-width: 1040px;
}
.foot-link.social ul li {
  padding-right: 20px;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  /* padding: 6px 12px; */
}
.foot-link ul {
  padding: 10px 10px;
  justify-content: flex-end;}

.foot-link ul li a{
  color : #C4C4C4;
  text-decoration: none;
}

.foot-link ul li a:hover{
  color : #ffffff;
}
.navbar-brand span {
  font-size: 16px;
  float: right;
}
.News-letter p {
  color: #fff;
  font-weight: 400;
  font-size: 44px;
  margin: 40px 0px 15px 0 !important;
}
.News-letter span{
  color: #fff ;
  font-weight: 400;
  font-size: 20px;
}
.foot-link .fa-mobile-alt:before,.foot-link .fa-address-book:before{font-size: 16px;}
.foot-link ul.pr-30{
  padding-right:30px ;    width: 230px;
}
/* .foot-link.social{    width: 260px;} */
.foot-link.social ul li i{    margin-right: 20px;}

@media only screen and (max-width: 768px){
  .footer-above .flex.justify-around {
    justify-content: space-around!important;
    flex-direction: column;
    align-items: center;
    padding: 0px 0;
  }
  .foot-link ul {
    padding: 10px 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .foot-link .main-li{display: none;}
}
@media only screen and (max-width: 600px){
  .foot-link ul.pr-30 {
    padding-right: 10px!important;
  }
  .main-fle{
    flex-flow: column;
    align-items: stretch;
  }
  .foot-link.ft-after{
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 500px){
  .soci{
    justify-content: center!important;
  }
  .footer-above .flex.justify-around {
    flex-direction: column!important;
  }
}