/* 31/07/19 */
.negosiate-bg{
    width: 100%;
    position: relative;
    padding-top: 15%;
}
.negosiate-bg svg{
    width: 100%;
}
.negosiate-bg .sample-txt h2{
 color: #002B5E;
 font-size: 28px;
}
.negosiate-bg .sample-txt p{
    font-size: 18px;
    font-weight: 500;
    color: #383839;
}
.negosiate-bg .sample-txt{
    position: absolute;
    top:0;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
    line-height: 25px;
}
.negosiate-bg .center-bt{
    position: absolute;
    bottom:0;
    text-align: center;
    width: 100%;
    padding-bottom: 50px;
    line-height: 25px;
}
path#path206:hover {
    fill: #00ced5;
    fill-rule: nonzero;
    filter: brightness(0.5);
    transition: all 0.2s ease-in;
}
path#path204:hover {
    fill: #ff5555;
    fill-rule: nonzero;
    filter: brightness(0.5);
    transition: all 0.2s ease-in;
}
path#path210:hover {
    fill: #6e95ff;
    fill-rule: nonzero;
    filter: brightness(0.5);
    transition: all 0.2s ease-in;
}
/* animated */


