.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  } 
  .upload-btn-wrapper input[type=file] {
    /* font-size: 100px;
    position: absolute; */
    left: 0;
    top: 0;
    opacity: 0;
  }
  .upload-btn-wrapper .btn-primary {
    padding: 8px 12px;
}
  .upload-btn-wrapper .flaticon-upload-button:before {
    font-size: 15px;
}

.upload-btn-wrapper img {
    height: 18px;
    width: 20px;
    float: right;
    padding-left: 4px;
}

.modal .d_block{
  display: block;
}

.deed-textarea{
  width: 100%;
  height: 6em;
  margin: 2em;
  padding: 1em;
}

.reff .d-inline{
  display: inline-block;
}
.fullwidth{
    width : 100%;
}