.popup-body{
    margin: 2em auto;
}
.popup-ft .d-block{
    margin: 15px;
} 

.popup-text-center{
    margin: 0 auto;
    text-align: center;
}

.pop-dialog{
    width: 40%;
    top: 25%;
}

.bid-hiy  .modal-body.video-body{
    margin: 30px;
}

.modal-body.video-body video{
    width: 100%;
    height: auto;
}

.bid-hiy .close{
    margin: 7px;
}
.trans-show{
    transform: translate(0px, 30%);
}
.top-15em{
    top:15em;
}
