.top-head {
  font-weight: 400;
  background: #112e51;
  color: #fff;
  padding: 11px 0;
  font-size: 13px
}

.top-head span {
  font-weight: 300;
  margin-left: 6px
}

.top-head a { 
  color: #d6d7d9;
}
.top-head a:hover {
  color: #fff;
}

.top-head i {
  margin-right: 8px;
  font-size: 18px;
}
.topnav, .mainnav, .uni-search {
  display: flex;
  justify-content: flex-end; 
  flex: 1;
}

.top-logo {
  padding: 30px 0
}

.top-logo img {
  float: left
}

.top-logo span {
  background: #5b616b;
  color: #fff;
  padding: 8px 28px;
  font-size: 16px;
  margin-left: 15px;
  margin-top: 6px;
  letter-spacing: 5px;
  float: left;
  text-transform: uppercase;
  border-radius: 4px 30px 30px 4px
}
.mainnav a {
  color: #212121;
}
.search-head {
  background: #f1f1f1;
    padding-top: 10px;
    padding-bottom: 10px;
}

.search-head select{
width: auto;
height: 3.4rem;
padding: 0.5rem 0.7em;
padding-right: 40px;
}
.profiledrop{
  position: absolute;
  width: 140px;
  left: 0;    top: 65px;
  border-top: 3px solid #000;
  transform: translate(48px, 2px);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.profiledrop ul{
  padding: 0;
  list-style-type: none;
}
.profiledrop ul li a{
  color: #000000;
  text-decoration: none;
}
.profiledrop ul li:hover a{
  color: #00ced5;
}
.profiledrop ul li{
  background: #f0f7ffcc;
  padding: 10px 5px;
  margin: 1px auto;
}
.pad-rt-5{
  padding:0  7px
}
/* animate */
.dropdown-menu1
{
	border:none;
	display: block;
    transform: scale(1)!important;
	height: 0px;
	overflow: hidden;
	padding: 0px;
	top: 180px;
	transition: all .3s;
}
.dropdown-menu1
{
	display: block;
	top: 100%;
	height: inherit;
}

/* snack bar */
/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  background-color: #d4edda;
  padding: 15px;
  color: #155724;
  position: fixed;
  z-index: 1;
  padding-right: 185px;
  right: 0;
  top: 6em;
  float: none;
  margin: 0 2px;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.snackbarClose{
    right: 20px;
    float: right;
    position: absolute;
    top: 15px;
    font-size: 20px;
}

.logo-design{
  text-shadow: 2px 2px #4965878f;
}
.header-name-display{
  padding-left: 10px;
  padding-top: 8px;
  outline: 0;
}
/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
/* 26/7 */
.snow-header a.navbar-brand {
  color: #fff !important;
  font-size: 26px;
}

#bs-example-navbar-collapse-1 ul li.res-call a{
  cursor: pointer;
}
#bs-example-navbar-collapse-1 ul li a {
  display: inline-block;
  position: relative;
}

#bs-example-navbar-collapse-1 ul li {
  color: #fff;
}

.nav-profile div:nth-child(2) {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.noti-dot{
  position: absolute;
  background: #ff7b2e;
  /* width: 20px; */
  /* height: 20px; */
  border-radius: 100px;
  font-size: 12px;
  text-align: center;
  display: flex;
  top: 10px;
  right: 9px;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 3px 4px;
}
.navbar-brand p{
  font-size: 16px;
  float: right;
}
.nav>li.scnd-dash{
  display: none;
}
.header-right li.active:after, .header-right li.res-call:hover:after{
  display: none;
}
.header-right li.res-call a:hover{
  cursor: initial;
  /* cursor: context-menu; */
}
.logo-design img{
  width: 145px;
  display: inline;
  object-fit: contain;
}





























@media (max-width: 991px) {
  .res991{
    width: 100%;
  }
}
@media (max-width: 790px) {
  .snow-header a.navbar-brand {
    color: #fff !important;
    font-size: 15px;
  }
  .navbar-default .navbar-nav.header-right>li>a{
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .snow-header a.navbar-brand {
    font-size: 20px;
  }
  .profiledrop{
    display: none;
  }
  .nav>li.scnd-dash{
    display: block;
  }
  .navbar-default .navbar-nav.header-right > li.divider{
    margin-top: 0!important;
  }
  .profile.head{
    float: left;
    margin: 0!important;
  }
  .navbar-default .navbar-nav.header-right > li.divider{
    height: 1px!important;
  }
  nav .navWide {
    display: block;
  }
  nav .navNarrow {
    display: none;
  }
  .profiledrop{
    margin: auto;
    position: relative;
    top:0;
    left: 0;
    right: 0;
    transform: translate(13px, 2px);
  }
  .nav-profile{
    justify-content: center;
  }
}
@media (max-width: 425px) {
  .logo-design img{
    width: 100px;
  }
}