/* 31/10 */
.pricing .nav-tabs {
    border-bottom: 1px solid #00ced5;
}
.pricing .tab-content p a{
    color: #00ced5;
}
.pricing .tab-content p{
    font-size: 16px;
    line-height: 25px;
    color: #000;
    margin: 15px;
} 
.pricing .tab-content .btn a {
  color: #fff;
}
.pricing .tab-content h5 {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    padding: 25px 0px 10px 0px;
}
.pricing .tab-content h4 {
    line-height: 20px;
    letter-spacing: 0.6px;
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 15px;
    padding-top: 20px;
}
.pricing .nav-tabs>li.active>a,
.pricing .nav-tabs>li.active>a,
.pricing .nav-tabs>li.active>a:focus,
.pricing .nav-tabs>li.active>a:hover{
    color: #fff;
    cursor: default;
    background-color: #00ced5;
    border: 1px solid #00ced5;
    border-bottom-color: #00ced5;
}
.pricing .nav-tabs>li>a{
    width: auto;
    font-size: 15px;
    text-align: center;
    margin-right: 0px;
    line-height: 1.42857143;
    border: 1px solid #00ced5;
    border-radius: 0px 0px 0 0;
    color: #5a5a5a;
}
.pricing .sta-tabs {
    padding: 10px 0;
}


/* accordiant */
.static-accordient input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

/* Accordion styles */
.static-accordient .tabs {
    border-radius: 0px;
    overflow: hidden;
    box-shadow: none;
}

.static-accordient .tab {
  width: 100%;
  color: white;
  overflow: hidden;
  /* margin-bottom: 10px; */
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.static-accordient .tab-label {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-size: 16px;
    background: linear-gradient(45deg, #027e83, #0dd4db);
    margin: 0;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
  /* Icon */
}
.static-accordient .tab-label:hover {
  background: #006f72;
}
.static-accordient .tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all .35s;
  margin-left: 20px;
}
.static-accordient .tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all .35s;
}
.static-accordient .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.static-accordient .tab-close:hover {
  background: #006f72;
}

.static-accordient input:checked + .tab-label {
  background: #006f72;
}
.static-accordient input:checked + .tab-label::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.static-accordient input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 2em;
}
/* accordiant end */
.before-cont h4{
    line-height: 20px;
    letter-spacing: 0.6px;
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 20px;
    text-transform: uppercase;
}
.before-cont{
    padding: 25px 0px;
    font-size: 18px;
}
.static-btn .btn{
    margin: 20px;
    padding: 15px 30px;
    font-weight: 600;
    font-size: 16px;
}
.pricing .tab-content b{
  font-size: 18px;
}
.pricing .tab-content ul{padding: 10px 55px;}
.pricing .tab-content ul li{
  font-size: 16px;
  list-style-type: decimal;
  padding: 5px 0;
}
/* css popup */
/* .new-st .makeStyles-paper-1,.new-st .makeStyles-paper-2
.new-st div[aria-labelledby="simple-modal-title"] div[role="document"]{
  top: 50%!important;
  right: 50%!important;
  position: absolute;
  transform: translate(-50%, -50%)!important;
} */
div[aria-labelledby="simple-modal-title"].new-st{
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-st div[role="document"]{
    width: auto;
    border: 1px solid #757d7e;
    /* padding: 8px 24px 8px 24px; */
    position: absolute;
    /* box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12); */
    box-shadow: none;
    background-color:transparent;
}
@media (max-width:768px) {
  .makeStyles-paper-1{
    width: 95%!important;
    margin: auto;  
  }
}