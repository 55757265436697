.result-padding { 
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    padding-top: 20px;
}
.search-count select {
 max-width: 350px;
}
.search-count {
  padding-bottom: 15px;
  padding-top: 15px;
}
.search-inner {
   margin-top: 20px;
   margin-bottom: 50px;
}

.prolist-wrap .pro-single {
flex-basis: 31%;
}
.pagination-text {
  padding-top: 35px;
}
.pagination-wrap {
  margin-top: 20px;
}
.cat-check {
  font-size: 12px;
  margin-bottom: 35px;
  width: 100%;
  float: left;
}

@media only screen and (max-width : 991px) {
  .toggle.respond.filter{
    margin: 6% auto;
    margin-bottom: 5%;
  }
  .filter-tit{
    display: none;
  }
  .toggle.respond.filter , [id^=dropfilter]{
    display: block!important;
  }
  .toggle.respond.filter + a, .category {
    display: none!important;
}
}

@media only screen and (max-width : 768px){
  .toggle.respond.filter{
    margin: 3% auto 3%!important;
  }
}
@media only screen and (max-width :475px) {
  .toggle.respond.filter {
    margin: 25% auto;
    margin-bottom: 5%;
  }
}

@media only screen and (max-width : 320px){
  .toggle.respond.filter {
    margin: 38% auto;
    margin-bottom: 5%;
  }
}

.searchwachlisthide:before{
  background: none !important;
}