.d-block{
    display: inline-block;
    margin: 0 3px;
}

.btn-primary_c {
    background: none;
    color: #18BBD5;
    font-size: 14px;
    font-weight: 400;
    border-radius: 24px;
    padding: 3px 7px;
    border-color: #18BBD5;
    text-transform: uppercase;
    top: 20%;
    margin-left: 0.5em;
}
.btn-primary_c:hover {
    background: #18BBD5;
    color: white;
    font-size: 14px;
    font-weight: 400;
    border-radius: 24px;
    padding: 3px 7px;
    border-color: #18BBD5;
    text-transform: uppercase;
    top: 20%;
    margin-left: 0.5em;
}
.text-dubdetail-label {
    /* font-size: 18px; */
    font-weight: 500;
    font-family: 'Karla', sans-serif !important;
}
