 .modal-dialog .productPopUp {
    /* position: relative; */
    width: 65em !important;
    /* margin: 10px; */
} 
.text-center{
    text-align: center
}
.btn-secondary-err {
    background: #ffffff;
    color: red !important;
    font-size: 16px;
    font-weight: 400;
    border-radius: 9px;
    padding: 10px 50px;
    border-color: red !important;
    text-transform: capitalize;
}
.btn-secondary {
    background: #ffffff;
    color: #00ced5;
    font-size: 16px;
    font-weight: 400;
    border-radius: 9px;
    padding: 10px 50px;
    border-color: #00ced5;
    text-transform: capitalize;
    margin-top: 10px !important;
}
.property-con{
    width: 185px;
    line-height: 20px;
}
.property-location{
    width: 185px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.bid-hiy.selt-propty .modal-body {
    min-height: auto;
    height: auto;
    max-height: 1000px;
    overflow-y: auto;
    overflow-x: hidden;
}