.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    /* line-height: 1; */
    color: #000;
    text-shadow: 30 8px 53 #fff;
    filter: alpha(opacity=20);
    opacity: .5;
}
.selectedOption{
    background:rgba(0,0,255,.1)
}

.pan-block span.token {
    padding: 8px 8px;
    border: 1px solid #A8A8A8;
    margin: 3px 2px;
    display: inline-flex;
    font-size: 13px;
    color: #000;
    cursor: pointer;
    font-family: 'Karla', sans-serif !important;
}
.pan-block span.token i {
    line-height: 20px;
    padding: 0 0px 0 20px;
}
.fs-16{
    font-size: 16px;
}
.display-flex{
    display:flex;
}
.radio-div-padding{
    flex-direction: column;
    padding: 1.8em 4em 0em 1em;
}
.position-relative{
    position:relative;
}
.position-absolute{
    position:absolute;
}
.top-negative{
    top: 9.2em;
}
.realtor-label{
    display: flex!important;
    flex-direction: column;
    align-items: center;
}
.top-em{
    margin-top : 1em
}